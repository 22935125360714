.my-community-group-template {
  .main-content {
    .heading {
      margin-bottom: 20px;
      position: relative;

      .team-image {
        max-height: 200px;
        min-height: 150px;
        overflow: hidden;
        position: relative;

        .field-name-field-community-hero-image img {
          min-height: 150px;
          object-fit: cover;
        }

        img {
          width: 100%;
          height: auto;
        }

      }

      .absolute-content {
        display: flex;
        align-items: center;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        left: 10px;
        bottom: 10px;
        border-radius: 5px;
      }

      .field-name-field-community-logo {
        height: 80px;

        img {
          border-radius: 0;
          width: auto;
          max-height: 80px;
          margin-right: 20px;
        }

      }

      .team-name {
        display: inline-block;
        font-weight: normal;
        background: $generic-semi-transparent;
        padding: 10px 15px;
        border-radius: 5px;
        font-size: 22px;
        margin: 0;
        color: $white;

        @media (min-width: $tablet) {
          font-size: 30px;
          padding: 10px 20px;
        }
      }

      h1,
      h2,
      p {
        margin: 0;
      }

      .team-rank {
        color: white;
        text-align: center;
        background: $highlight-blue;
        padding: 3px 0;
      }
    }
  }


  .main-content > .content {
    position: relative;
  }

  .action-buttons {
    margin-top: 0;
    padding: 0;
    position: absolute;
    right: 4px;
    bottom: 4px;
    text-align: right;

    a {
      @include cta($highlight-blue);

      & {
        background-color: $white;
        box-shadow: none;
        color: $highlight-blue;
        font-size: 0;
        margin-bottom: 0;
        padding: 6px;
      }

      &:hover,
      &:focus {
        color: $white;
      }

      &::before {
        font-size: 1.5rem;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .progress-block {
    @include global-container;

    @include breakpoint($laptop) {
      margin-bottom: 80px;
    }
  }

  .about-this-team {
    font-size: $global-font-size;
    margin: 0 auto 30px auto;
    padding: 0 15px;
    width: susy-span(11 of 12);

    @media (min-width: $laptop) {
      padding: 0;
    }
  }

  .members-community-block {
    .leads-group {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;

        li {
          flex-basis: 100%;

          &:nth-child(odd) {
            padding-right: 10px;
          }

          &:nth-child(even) {
            padding-left: 10px;
          }

          @include breakpoint($tablet) {
            flex-basis: 50%;
          }
        }
      }

      .leader-card {
        position: relative;
        @include leader-card;

        @include breakpoint($laptop) {
          min-width: 300px;
          width: 100%;
        }

        .leader-action {
          position: absolute;
          top: 5px;
          right: 7px;

          a {
            display: block;
            outline: none;
          }

        }

      }
    }

    .members-group {
      margin-bottom: 20px;

      @include breakpoint($laptop) {
        margin-bottom: 30px;
      }
    }
  }


  .actions-block, .community-block {
    width: 100%;

    .value {
      color: #4A91D6;
      font-size: 28px;
      margin-bottom: 5px;
      font-weight: bold;

      @media (min-width: $laptop) {
        font-size: 35.2px;
      }
    }
  }

  .ranks-block {
    @include global-container;

    @include breakpoint($laptop) {
      margin-bottom: 50px;
    }

    .block-title {
      margin: 0 0 20px;
    }

    .wrapper-block-content {
      margin: 0 auto 30px;

      @include breakpoint($tablet) {
        display: flex;
        justify-content: space-between;
      }

      @include breakpoint($laptop) {
        margin: 0 auto 60px;
      }

      .actions-block {
        margin-bottom: 20px;

        @include breakpoint($tablet) {
          margin-bottom: 0;

          h3 {
            margin-bottom: 50px;
          }
        }

        .view-recent-actions {
          background-color: $background-gray-3;
          padding: 20px 30px;
          height: 100%;

          a {
            font-size: $global-font-size;
            font-weight: bold;
          }
        }
      }

      .last-actions-block {
        background-color: $background-gray-3;
        height: 100%;
        padding: 20px 30px;

        .block-content {
          display: flex;
          flex-wrap: wrap;

          .action-column {
            flex-basis: 100%;

            @include breakpoint($laptop) {
              flex-basis: 50%;
            }

            .action-row {
              display: flex;
              margin: 0 0 20px 0;

              .action-name {
                flex-basis: 90%;
              }

              .action-amount, a {
                color: $light-blue;
                font-size: $global-font-size;
                font-weight: bold;
              }
            }
          }
        }
      }

      .community-block {
        li:nth-child(n+7) {
          display: none;
        }

        @include breakpoint($tablet) {
          border-left: 2px solid $background-gray-3;
          width: 56%;
        }

        > h3 {
          border-bottom: 2px solid $background-gray-3;
          margin-bottom: 0;
          padding-bottom: 20px;
          padding-left: 25px;

          @include breakpoint($laptop) {
            margin-top: 45px;
          }

        }
      }
    }
  }

  .post-block {

    @media (min-width: $laptop) {
      width: susy-span(11 of 12);
      margin: 0 auto;
    }

    .block-title {
      margin: 0 0 20px;
    }

  }

  .progress-block, .standings-block {
    > h2 {
      margin: 30px 0 15px;

      @media (min-width: $laptop) {
        margin: 60px auto 15px;
      }

    }

    .clearfix {
      padding: 0;
    }
  }

  .ranks-block {
    > h2 {
      margin: 0 0 15px;

      @media (min-width: $laptop) {
        margin: 0 auto 15px;
      }

    }

  }

  .actions-stats-block {
    margin-top: 30px !important;

    > .wrapper-content {

      .value {
        font-size: 24px !important;

        @media (min-width: $laptop-l) {
          font-size: 30px !important;
        }
      }

    }

  }

  .actions-block, .community-block {
    margin: 0;

    .view-content {
      padding: 0 !important;
    }
  }

  .leave-community {
    @include global-container;

    .wrapper {
      border-top: 1px solid $background-gray-3;
      margin-top: 20px;
      padding-top: 20px;

      a {
        font-size: $text-font-size;
        font-weight: 700;
      }
    }
  }

  .modal-trigger {
    @include see-all-links;
  }
}

.remodal {
  .last-actions-block {
    background-color: $background-gray-3;
    height: 100%;
    padding: 20px 30px;

    .block-content {
      display: flex;
      flex-wrap: wrap;

      .action-column {
        flex-basis: 100%;

        @include breakpoint($laptop) {
          flex-basis: 50%;
        }

        .action-row {
          display: flex;
          margin: 0 0 20px 0;

          .action-name {
            flex-basis: 90%;
          }

          .action-amount, a {
            color: $light-blue;
            font-size: $global-font-size;
            font-weight: bold;
          }
        }
      }
    }
  }
}
