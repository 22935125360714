.block-dashboard-welcome-message {
  width: susy-span(11 of 12);
  border: 1px solid $highlight-blue;
  border-radius: 4px;
  padding: 10px !important;
  margin: 40px auto 30px;
  position: relative;

  &.hidden {
    display: none;
  }

  h2 {
    padding-right: 40px;
    margin: 0 0 20px;
  }

  div {
    font-size: 18px;
    text-align: center;
  }

  .close {
    display: inline-block;
    width: 28px;
    height: 28px;
    float: none;
    cursor: pointer;
    transition: all .4s;
    overflow: hidden;
    position: absolute;
    top: 10px;
    right: 8px;

    &:hover {

      &:before {
        color: $highlight-blue;
      }

    }

    &:before {
      content: "\f410";
      display: inline-block;
      width: 32px;
      height: 32px;
      color: $light-blue;
      font-size: 2em;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
    }

  }

}
