.homepage-how-it-works {

  h2 {
    max-width: 250px;
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
    margin: 0 auto;

    @media(min-width: $tablet) {
      max-width: 600px;
    }

    &:before {
      content: "";
      width: 95%;
      border-top: 3px solid $green;
      margin: 0 auto;
      transform: translateY(-50%);
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 0;
    }

    span {
      display: inline-block;
      background: $white;
      padding: 0 10px;
      z-index: 2;

      @media(min-width: $tablet) {
        padding: 0 15px;
      }

    }
  }

}
