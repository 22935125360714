#pm-block-user-list {

  > div {

    fieldset {
      margin-bottom: 30px;

      legend {
        padding: 0;
        margin: 0 0 20px;

        span {
          font-size: 18px;
          font-weight: bold;
        }

      }

    }

    table {
      @include tableStyle;
    }

  }

  .form-item {
    margin: 0 0 20px;
  }

  .form-item-name {
    margin: 0 0 15px;

    label {
      margin-bottom: 5px;
    }

    input[type="text"] {
      box-shadow: 0 10px 10px 0 rgba(207, 210, 220, .2);
      border: 1px solid;
      outline: none;
      padding: 10px;
    }

  }

  input[type="submit"] {
    @include cta($highlight-blue);
    & {
      margin: 0;
    }
  }

}
