.node-community-post {

  &.node-teaser {

    h2 {
      font-size: 16px;
      margin: 0 0 2px;

      a {
        @include link;
        color: black;

        &:hover {
          color: $background-blue;
        }

      }

    }

    .field-name-field-com-p-date {
      color: $light-blue;
      font-size: 12px;
      margin-bottom: 15px;
    }

    .field-name-body {
      font-size: 14px;

      p {
        margin: 0;
      }

    }
  }

}
