.modal-popular-actions {

  .item-list ul {
    margin: 0;

    li {
      margin: 0 0 20px;

      a {
        font-size: 16px;
      }

    }

  }

}
