.node-resource {
  font-size: $p;
  @include new-container;

  ul {
    @include unordered-list;
  }

  .field-name-body {
    margin-bottom: 3em;
  }

  .field-name-field-resource-type,
  .field-name-field-city-resource-actions {
    margin-bottom: 3em;

    .field-item {
      margin-bottom: 5px;
    }

  }

  a {
    @include link;
    color: $highlight-blue;

    &:hover, &:focus {
      color: $text-color;
    }

  }

}
