#c02free-community-group-add-group-form {

  > div {

    > span,
    > div:not(.help-text) {
      display: inline-block;
      margin-left: 20px;

      &:first-child {
        margin-right: 0;
      }

    }

  }

  .help-text {
    clear: both;
  }

  input[type="submit"] {
    @include cta($highlight-blue);
  }

}

.modal-content > #c02free-community-group-add-group-form {

  input[type="submit"] {
    float: right;
    margin-right: 0;
  }

}

.content > #c02free-community-group-add-group-form {
  @include new-container;

  input[type="submit"] {
    margin-top: 35px;
  }

}
