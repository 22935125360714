form.confirmation {
  font-size: 18px;
  @include new-container;

  @media (min-width: $laptop) {
    padding: 30px 0;
  }

  .form-actions {

    a,
    input {
      display: inline-block;
      margin-right: 15px;
    }

  }

}

#modal-content > form.confirmation {
  width: 100%;
}
