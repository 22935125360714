$radius: 5px;

.actions-list {
  clear: both;
  padding: 0 15px 5px 0;

  @media (min-width: $tablet) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width: $laptop) {
    width: calc(100% + 30px);
    padding-right: 0;
    padding-bottom: 10px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .actions-list-action {
    margin-bottom: 2em;

    @media (min-width: $tablet) {
      width: 50%;
    }

    @media (min-width: $laptop) {
      width: 25%;
      padding: 0 15px;
      margin-bottom: 3em;
    }

    &:nth-child(2n+2) {
      margin-right: 0;
    }

    &:last-child {
      padding-right: 15px;
    }

    > div {
      @include global-card;
      width: 70vw;
      max-width: 300px;
      height: 100%;
      background: white;
      border-radius: $radius;
      overflow: hidden;
      padding-bottom: 56px;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      @media (min-width: $laptop) {
        width: 100%;
        max-width: 100%;
        height: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      &.unstarted {

        a.add-modal-link,
        a.plain-link {
          background: url("../images/icon-more.svg") no-repeat center;
          background-size: contain;
          display: block;
          height: 24px;
          position: absolute;
          right: 5px;
          text-indent: -99999em;
          top: 5px;
          width: 24px;
          z-index: 10;
        }

      }

      &.in-progress {

        &:before {
          width: 30px;
          height: 30px;
          background: url("../images/icon-throbber.svg") no-repeat center;
          background-size: contain;
        }

      }

      &.completed {

        &:before {
          width: 25px;
          height: 25px;
          background: url("../images/icon-checked.svg") no-repeat center;
          background-size: contain;
        }

      }

      .choose-btn-wrapper {
        margin-top: 10px;
        text-align: center;

        .choose-btn {
          padding: 5px 25px;
          text-transform: initial;
        }
      }

      .already-do-this-wrapper {
        display: flex;
        align-items: center;
        margin-top: 9px;
        margin-bottom: 13px;

        input[type="checkbox"] {
          margin-left: auto;
        }

        label {
          font-weight: normal;
          cursor: pointer;
          margin-right: auto;
        }
      }

      .action-status-title {
        min-height: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $highlight-blue;
        font-size: 22px;
        font-weight: bold;
        padding: 0 20px;
      }

      .answers-modal-link {
        color: white;
        font-size: 24px;
        left: 10px;
        position: absolute;
        top: 10px;
        z-index: 5;

        a {
          height: 100%;
          left: 0;
          position: absolute;
          text-indent: -9999em;
          top: 0;
          width: 100%;
        }
      }

      &:before {
        content: "";
        display: inline-block;
        z-index: 10;
        position: absolute;
        top: 15px;
        right: 20px;
      }

      > a {
        @include link;

        &:hover {
          color: $light-blue;
          text-decoration: none;
        }

        .image-wrapper {
          height: 165px;
          position: relative;
          width: calc(100% + 2px);
          margin-left: -1px;
          overflow: hidden;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          h3 {
            width: 100%;
            min-height: 53px;
            color: white;
            font-size: 22px;
            line-height: 1.2;
            background: rgba(0, 0, 0, .6);
            overflow: hidden;
            padding: 0 20px;
            margin: 0;
            position: absolute;
            left: 0;
            bottom: 0;
          }

        }

        .tag {
          display: inline-block;
          color: white;
          font-size: 14px;
          border-radius: 25px;
          background: rgba(0, 0, 0, .5);
          padding: 3px 15px 4px;
          position: absolute;
          top: 10px;
          left: 20px;
        }

      }

      .tile-content {
        min-height: 56px;
        padding: 12px 20px 10px;

        p {
          font-size: 14px;
          margin: 0;
        }
      }

      .action-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        height: 53px;
        border-top: 1px solid rgba(50, 50, 50, .1);
        padding: 10px 10px 0;
        position: absolute;
        left: 0;
        bottom: 0;

        .action-footer-item {
          color: $text-color;
          font-size: 12px;
          position: relative;

          &.difficulty {
            padding-left: 21px;
          }

          &.points {
            padding-left: 20px;

            &:before {
              background: url("../images/circle-star.svg") no-repeat top center;
              background-size: contain;
            }

          }

          &.savings {
            display: inline-flex;

            &:before {
              content: unset;
            }

            .currency {
              color: #94a4b1;
              font-size: 16px;
              line-height: 12px;
              margin: 0 5px;
              position: relative;
              top: 1px;
            }
          }

          &.customize {
            flex-basis: calc(100% + 20px);
            text-align: center;
            margin-top: 10px;
            margin-left: -10px;
            margin-right: -10px;

            a {
              display: block;
              color: white;
              font-size: 14px;
              line-height: 1.2;
              padding-top: 2px;
              background: $highlight-blue;
            }
          }

          &:before {
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 0;
            left: 0;
          }

          span {
            display: block;

            @media (min-width: $laptop) {
              display: inline-block;
            }

            &.number {
              margin-right: 3px;
            }

          }
        }
      }
    }
  }

}
