form.national-site-form-go-to-domain {
  background: rgba(0, 0, 0, .5);
  border-radius: 3px;
  padding: 20px;

  > div {
    text-align: center;

    fieldset {

      legend {
        width: 100%;
        color: white;
        font-size: 18px;
        margin-bottom: 10px;

        span {
          background: transparent;
        }

      }

      > .fieldset-wrapper {

        @media(min-width: $tablet) {
          display: flex;
        }

        > .form-item {
          margin: 0 0 15px;
        }

        > div {

          @media(min-width: $tablet) {
            width: 50%;
          }

          .form-item {
            margin: 0 !important;
          }

          &:nth-child(odd) {

            @media(min-width: $tablet) {
              margin-right: 5px;
            }

          }

          &:nth-child(even) {

            @media(min-width: $tablet) {
              margin-left: 5px;
            }

          }

          .select2 {
            width: 100% !important;
            background: white;
          }

        }
      }

    }

    input[type="submit"] {
      @include cta($highlight-blue);
      & {
        box-shadow: none;
        padding: 7px 20px;
        margin: 0;
      }
    }

  }

}

.go-to-domain-modal {
  @extend #c02free-user-actions-add-form;

  .fieldset-wrapper {
    display: flex;
    justify-content: center;

    .form-item {
      margin: 10px !important;
    }

    .form-type-select {
      display: flex;
      align-items: center;
      font-size: 16px;
      border: 1px solid $background-gray-1;
      float: none;
      padding: 5px 5px 5px 12px;
    }
  }
}

#modalContent {
  .go-to-domain-modal-title {
    text-align: center;
  }
}
