.page-node-add {

  .block-system > .content {
    padding-top: 2em;
  }

  main {
    @include new-container;
  }

  .date-padding {
    padding: 0;
  }

  input[type="text"] {
    padding: 10px;
  }

  .node-type-list {
    @include new-container;
    & {
      font-size: 16px;
    }

    dd {
      margin-left: 20px;
      margin-bottom: 30px;
    }

    a {
      @include link;
      color: $highlight-blue;
      font-weight: bold;

      &:hover, &:active,
      &:focus {
        color: $text-color;
      }

    }
  }

}
