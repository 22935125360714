.view-calendar-event {

  .view-header {
    background: $light-blue;
    padding: 0 7px;

    .date-nav-wrapper {
      margin: 0;

      .date-nav {
        padding: 20px 0;
        margin: 0;
      }

    }

    .date-heading {
      padding: 0 30px;

      h3 {
        font-size: 20px;
        line-height: 1;
      }

    }

    .pager {
      margin: 0;

      li {
        width: 30px;
        border-radius: 0;
        background: transparent;
        padding: 0;
        margin: 0;
        transform: translateY(-50%);
        top: 50%;

        &.date-prev {
          left: 0;

          a {
            background: url("../images/icon-chevron-left.svg") no-repeat center;
            background-size: 35px;
          }

        }

        &.date-next {
          right: -1px;

          a {
            background: url("../images/icon-chevron-right.svg") no-repeat center;
            background-size: 35px;
          }

        }

        a {
          @include link;
          display: inline-block;
          width: 30px;
          height: 30px;
          text-indent: 1000em;
          opacity: .5;
          overflow: hidden;
          float: none;
          margin: 6px 0 0;

          &:hover {
            opacity: 1;
          }

        }
      }
    }

  }

  > .view-content {

    > .item-list {

      > ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          padding: 0;
          margin: 0 0 25px;
        }

      }

      .views-field-title {

        a {
          @include link;
          color: $highlight-blue;
          font-size: 18px;
          font-weight: 600;

          &:hover {
            color: black;
          }

        }

      }

      .views-field-field-date {
        margin-bottom: 3px;

        span {
          display: inline-block;
          font-size: 14px;
        }

      }

    }

    .calendar-calendar {
      background: $light-blue;
      padding: 0 7px 5px;

      table {

        tbody {
          padding-top: 5px;
        }

        td,
        tr {
          background: transparent;
        }

        th.days {
          color: black;
          font-size: 14px;
          font-weight: normal;
          background: transparent;
          border: none;
          padding: 0 0 10px;
        }

        td {
          color: black;
          text-align: center;
          padding: 3px 0;

          &.has-events {
            background: #f2f2f2;
          }

          &.today {
            background: white;
            font-weight: bold;
          }

        }
      }

    }

  }

  .attachment {

    .view-calendar-event {

      .view-content {
        background: transparent;
        padding: 0;
      }

      .item-list {

        > ul {
          margin: 20px 0 0;

          li {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column-reverse;
            margin: 0 0 15px;

            > * {
              width: 100%;
            }

          }

          a {
            @include link;
            color: black;
            font-size: 16px;
            font-weight: bold;

            &:hover {
              color: $highlight-blue;
            }

          }

          span {
            display: inline-block;
            color: $light-blue;
            font-size: 12px;
          }

        }
      }
    }
  }

}
