@mixin container {
  margin: 0 auto;
  width: susy-span(10 of 12);
}

@mixin icon($background-image) {
  content: '';
  background-image: url($background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 20px;
  width: 20px;
  display: block;
  margin-right: 10px;
}

@mixin button {
  background-color: $supporting-2-transparent-1 !important;
  border-radius: 5px;
  border: 2px solid $supporting-2;
  padding: 20px 0 !important;
  text-align: center;
  color: $text-color;
  font-size: 16px;
  cursor: pointer;

  a {
    &, &:hover, &:focus {
      color: inherit;
      text-decoration: none;
    }
  }
}

@mixin container-small {
  margin: 0 auto;
  width: susy-span(11 of 12);

  @media (min-width: $tablet) {
    width: susy-span(8 of 12);
  }
}

$spacing: 60px;

.heading {
  &__section {
    background-color: $highlight-blue;
    padding: $global-padding-desktop 0;
    color: $white;
    text-align: center;
    position: relative;

    .gcf-lang-switcher {
      position: absolute;
      top: 10px;
      right: 10px;

      .lang-dropdown ul li {
        color: $light-blue;

        &.active:hover {
          color: #FFF;
        }
      }
    }
  }

  &__title {
    font-size: 42px;
    margin-bottom: 15px;
  }

  &__subtitle {
    font-size: 20px;
  }
}

.page-user-register {
  .messages.error {
    @include container;

    @media (min-width: $laptop) {
      @include container-small;
    }
  }
}

.user {
  &__header {
    @include container;
  }

  &__log-section {
    @include container;

    text-align: center;
    padding: $global-padding-desktop 0 10px;

    h3, h4 {
      font-weight: normal;
      margin: 0;
    }

    h3 {
      margin-bottom: 10px;
    }

    h4 {
      margin-bottom: 15px;
      font-size: 18px;
    }

    .custom-site-message {
      margin-top: 2em;
      margin-bottom: 0;
      font-size: 1.1em;
    }
  }
}

.signup {
  &__form {
    margin: 0 auto;

    @media (min-width: $tablet) and (max-width: $laptop -1) {
      @include container;
    }
    @media (min-width: $laptop) {
      @include container-small;
    }

    .confirm-parent,
    .password-parent,
    .form-item .password-suggestions {
      @media (max-width: $laptop) {
        width: 100%;
      }
    }

    .city-container {
      display: flex;
      align-items: center;

      .select-other-city {
        margin-left: 1em;
        margin-top: 3em;
        margin-bottom: 1em;
      }
    }

    .hybridauth-widget {
      margin: 0 auto !important;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 15px;
      width: susy-span(8 of 12);
    }

    .Facebook {
      .provider__icon {
        &::before {
          @include icon('../images/facebook-sign-up.png');
        }
      }
    }

    .Google {
      .provider__icon {
        &::before {
          @include icon('../images/google-sign-up.png');
        }
      }
    }

    .provider__icon {
      @include center-flex;

      font-size: 16px;
    }

    .password-suggestions {
      li {
        margin-top: 5px;
        margin-left: 20px;
        list-style-type: disc;
      }
    }
  }

  &__heading {
    @include container;

    @media (min-width: $laptop) {
      @include container-small;
    }

    & {
      @include button;
      & {
        margin-top: 15px !important;
      }
    }

    span {
      @include center-flex;

      &::before {
        @include icon('../images/plus-sign-up.png');
      }
    }
  }

  &__content {
    @include container;

    @media (min-width: $laptop) {
      @include container-small;
    }
    & {
      margin: 15px auto !important;
      padding: 20px;
      border: 2px solid $background-gray-2;
      border-radius: 5px;
    }
    #edit-state-and-city, #edit-diocese-and-church {
      .fieldset-legend, legend {
        display: initial;
        font-weight: bold;
      }
    }

    .fieldset-legend, legend {
      display: none;
    }

    fieldset.captcha {
      transform: scale(0.85);
      transform-origin: 0 0;

      @media (min-width: $tablet) {
        transform: scale(1);
      }

    }

    input[type="text"], input[type="password"], input[type="tel"] {
      margin: 10px 0;
      padding: 15px;
      border-radius: $welcome-border-radius;
      width: 100%;
      appearance: none;
      font-size: 1em;
      border: 2px solid $background-gray-2;

      &.error {
        border: 2px solid red;
      }
    }

    .select2 {
      border: 2px solid $background-gray-2;
      padding: 10px 15px;
      border-radius: 5px;
      margin-top: 10px;
    }

    select {
      &.error {
        & + .select2 {
          border: 2px solid red;
        }
      }
    }

    input[type="submit"] {
      @include cta($highlight-green);

      & {
        font-size: 16px;
        margin: 0 auto;
        display: block;
      }
    }

    .form-item-field-zip-geolocation-und-0-value,
    .form-type-textfield .form-item-field-last-name-und-0-value,
    .form-item-field-user-household-name-und-0-value,
    .form-item-mail,
    .form-item-field-last-name-und-0-value {
      margin-bottom: 0;
    }

    .form-item-field-user-neighborhood-und {
      align-items: center;
      display: flex;
      flex-wrap: wrap;

      label {
        flex-basis: 100%;
      }

      .description {
        margin-left: 15px;
      }
    }

    label {
      font-size: 16px;
    }

    .form-type-checkbox {
      label {
        line-height: 20px;
      }
    }

    .fieldset-description {
      margin: 0 0 10px;
    }

    .password-strength,
    .password-confirm {
      margin-top: 0;
    }

    .password-strength {
      margin-bottom: 10px;
    }

  }

  &__form__action {
    &.shown {
      .signup__heading {
        span::before {
          @include icon('../images/minus-sign-up.png');
        }
      }

      .signup__content {
        display: flex;
      }
    }
  }
}
