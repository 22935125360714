.pane-action-stats-panel-pane-8 {
  .view-action-stats {
    @include global-container;

    .view-content {

      > div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .kwh, .therms, .gas, .water {
        position: relative;
        background-size: 30px;
        display: flex;
        flex-basis: 50%;
        flex-direction: column;
        justify-content: flex-start;
        margin: 10px 0;
        padding: 0 0 10px 45px;

        @include breakpoint($tablet) {
          padding-left: 90px;
        }

        @include breakpoint($laptop) {
          background-size: 40px;
          flex-basis: 25%;
          height: 100px;
          margin: 0;
          padding: 24px 12px 0 55px;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          transform: translateX(-100%);
          padding-right: 20px;
          width: 40px;
          height: 60px;

          @include breakpoint($laptop) {
            padding-right: 20px;
          }
        }
      }

      .kwh {
        &:before {
          background: url("../images/icon-kwh.svg") 5% 25% no-repeat;
          background-size: 35px;
        }

        @include breakpoint($laptop) {
          background-size: 38px;
        }
      }

      .therms {
        &:before {
          background: url("../images/icon-therms.svg") 5% 27% no-repeat;
          background-size: 38px;
        }

        @include breakpoint($laptop) {
          background-size: 38px;
        }
      }

      .gas {
        &:before {
          background: url("../images/icon-gas.svg") -5% -25% no-repeat;
          background-size: 58px;
        }

        @include breakpoint($laptop) {
          background-size: 58px;
        }
      }

      .water {
        &:before {
          background: url("../images/icon-water.svg") 4% 15% no-repeat;
          background-size: 40px;
        }

        @include breakpoint($laptop) {
          background-size: 40px;
        }
      }

      .value {
        color: $background-blue;
        font-size: $medium-text;
        font-weight: bold;
      }

      .label {
        color: $background-gray-1;
        font-size: $small-text;
        text-transform: uppercase;

        @include breakpoint($tablet) {
          font-size: $text-font-size;
        }
      }
    }
  }
}
