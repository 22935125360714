// Modal styles.
.modal {
  @include center-fixed;
  z-index: 3;
  background: $white;
  width: 100vw;
  height: 100%;
  padding: 50px 10px 10px;
  display: none;

  @media (min-width: $tablet) {
    max-width: 500px;
    width: 50vw;
    height: auto;
    border-radius: 5px;
    padding: 28px 30px;
  }

  h4 {
    margin: 0;
    font-size: 1.5em;
  }

  i {
    color: $background-blue;
    font-size: 2em;
    cursor: pointer;
  }

  header {
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative;

    @media (min-width: $tablet) {
      @include center-flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    }

    .close-wrapper {

      @media (min-width: $tablet) {
        width: 28px;
        height: 28px;
      }

    }

    i {

      @media (min-width: $tablet) {
        width: 28px;
        height: 28px;
        top: 0;
        right: 0;
      }

      &:before {
        display: none;
        font-size: 28px;

        @media (min-width: $tablet) {
          display: inline-block;
        }

      }

      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: url("../images/icon-back.svg") no-repeat left 10px center;
        background-size: 20px;
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        left: 0;

        @media (min-width: $tablet) {
          display: none;
        }

      }
    }

  }

  &-search {
    @include center-flex;

    justify-content: space-between;
    padding: 20px;
    display: none;

    input[type="text"] {
      padding: 10px;
      border: none;
      font-size: 1em;
      color: $text-color;
    }

    input[type="submit"] {
      display: none;
    }

    form, input[type="text"] {
      width: 100%;
    }
  }

  &-picture,
  &-background-img {
    div {
      font-size: 0;
    }

    .messages {
      font-size: 1rem;
    }

    button {
      appearance: none;
      cursor: pointer;
      border: none;
      font-size: 1rem;
      color: $white;
      padding: 10px 20px;
      border-radius: 5px;
      background-color: $background-blue;

      i {
        color: $white;
        font-size: 1rem;
        margin-right: 5px;
      }

      &.save-user-picture-trigger,
      &.save-user-background-trigger {
        background-color: $background-green;
        margin-left: 10px;
      }
    }
  }
}

.user-picture-preview {
  @include center-background;

  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin: 26px auto;

  & + .file {
    display: none;
  }
}

.user-background-preview {
  @include center-background;

  img {
    width: 100%;
    height: auto;
  }

  & {
    margin: 26px auto;
  }

  & + .file {
    display: none;
  }
}

.change-user-picture-form-elements,
.change-user-background-form-elements {
  display: none;
}

.form-item-user-picture,
.form-item-user-background {
  text-align: center;
}

#change-user-picture-form,
#change-user-background-form {
  input[type="submit"] {
    display: none;
  }
}
