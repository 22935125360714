.view-action-stats {
  border-radius: 5px;
  width: 100%;

  .view-content {
    .item-list ul {
      padding: 0;
      margin: 0;

      li {
        margin: 0 0 20px;
      }
    }

    .views-field-title-field {
      a {;
        font-size: 16px;
      }
    }
  }
}

.twocol {
  margin: 0 auto;

  @media (min-width: $tablet) {
    width: susy-span(11 of 12);
  }

  .row {

    @media (min-width: $tablet) {
      margin: 0 0 60px !important;
    }

  }

  &__container {
    padding: 0 15px;
    margin-bottom: 30px;

    @media (min-width: $tablet) {
      display: flex;
      padding: 0;
      margin-bottom: 60px;
    }

    > div {

      @media (min-width: $tablet) {
        flex-basis: 50%;
      }

      &:first-child {
        margin-bottom: 20px;

        @media (min-width: $tablet) {
          margin-right: 15px;
          margin-bottom: 0;
        }

      }

      &:last-child {

        @media (min-width: $tablet) {
          margin-left: 15px;
        }

      }
    }
  }
}

.pane-action-stats-panel-pane-8 {
  .view-action-stats {
    @media (min-width: $laptop) {
      margin-top: 0;
    }
  }
}
