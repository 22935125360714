.graph-baseline {
  .bar-graph {
    align-items: center;
    display: flex;
    margin-bottom: 15px;

    @include breakpoint($tablet) {
      margin-bottom: 10px;
    };

    .name {
      color: $gray-text;
      font-size: $small-text;
      font-weight: bold;
      width: 120px;

      @include breakpoint($tablet) {
        font-size: $text-font-size;
      };
    }

    .bar-graph-track {
      border-radius: 5px;
      padding-right: 90px;
      width: calc(100% - 120px);

      .graph {
        background-color: $supporting-2;
        border-radius: 30px;
        height: 22px;
        min-width: 5px;
        position: relative;

        @include breakpoint($tablet) {
          height: 30px;
        };

        &.highlight {
          background-color: $light-blue;
        }
      }
    }

    .bar-label {
      color: $background-gray-1;
      left: calc(100% + 8px);
      font-size: $small-text;
      position: absolute;
      text-transform: uppercase;
      top: 50%;
      transform: translateY(-50%);
      width: 80px;

      @include breakpoint($tablet) {
        font-size: $text-font-size;
      };
    }

    .my-house,
    .goal {
      color: $black;
    }
  }
}
