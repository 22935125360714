// Styles for typography selectors, i.e. "h1".
$font-family-sans-serif: 'proxima-nova', sans-serif;
$font-family-serif: 'Lora', Georgia, 'Times New Roman', Times, serif;
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;
$h1: 26px;
$h1-tablet: 30px;
$h2: 24px;
$h2-tablet: 26px;
$h3: 20px;
$h3-tablet: 24px;
$h4: 18px;
$h4-tablet: 20px;
$h5: 18px;
$h5-tablet: 18px;
$h6: 18px;
$p: 18px;
$small: 16px;

// Mixin utilities.
@mixin h1 {
  font-size: $h1;

  @media (min-width: $tablet) {
    font-size: $h1-tablet;
  }
}

@mixin h2 {
  font-size: $h2;

  @media (min-width: $tablet) {
    font-size: $h2-tablet;
  }
}

@mixin h3 {
  font-size: $h3;

  @media (min-width: $tablet) {
    font-size: $h3-tablet;
  }
}

@mixin h4 {
  font-size: $h4;

  @media (min-width: $tablet) {
    font-size: $h4-tablet;
  }
}

@mixin h5 {
  font-size: $h5;

  @media (min-width: $tablet) {
    font-size: $h5-tablet;
  }
}

body {
  font-family: $font-family-sans-serif;
  font-size: 14px;
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

h5 {
  @include h5;
}

h6 {
  font-size: $h6;
}

p {
  font-size: $p;
}
