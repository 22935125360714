.header-anonymous {
  background-color: $white;
  box-shadow: 0 0 10px 0 $background-gray-1;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000000;

  .logo {
    width: 100%;

    a {
      padding: 0;
    }

    img {
      height: 40px;
      vertical-align: top;
      width: auto;
    }
  }
}
