.view-my-action-list {
  margin-left: -$global-padding-mobile;
  margin-right: -$global-padding-mobile;
  text-align: left;

  @include breakpoint($tablet) {
    margin-left: 0;
    margin-right: 0;
  };

  .plan-status {
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 20px;

    > * {
      margin-right: 35px;
    }

    h2 {
      font-weight: 600;
      line-height: 1;
      margin-top: 0;
      margin-bottom: 0;
    }

    .stat-number {
      display: inline-block;
      width: 35px;
      height: 35px;
      font-size: 18px;
      border: 2px solid;
      border-radius: 100%;
      background: white;
      margin-right: 5px;
      position: relative;
      top: 4px;

      > span {
        line-height: 1;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
      }

    }

    .stat-text {
      display: inline-block;
      position: relative;
      bottom: 7px;
    }

  }

  .plan-stats {
    margin-bottom: 20px;

    @media (min-width: $tablet) {
      padding: 0 40px;
      margin-bottom: 30px;
    }

    .wrapper {
      background: white;
      padding: 10px 10px 6px;

      @media (min-width: $tablet) {
        display: flex;
        align-items: baseline;
      }

    }

    .plan-stats-section {
      text-align: center;

      @media (min-width: $tablet) {
        width: 33.33333%;
        text-align: left;
      }

      + .plan-stats-section {
        text-align: center;
      }

    }

    .stat-number {
      color: $green;
      font-size: 30px;
      font-weight: bold;
      margin-right: 7px;

      span {
        display: inline-block;
        margin-right: 25px;
      }

    }

    .stat-text {
      color: #626365;
      font-size: 14px;
      font-weight: bold;
      position: relative;
      bottom: 1px;
    }

  }

  > .view-content {
    @include breakpoint($laptop) {
      padding: 30px 0;
      width: 100%;
    }

    .views-row {
      padding: 20px 30px;
      margin: 0;

      @include breakpoint($tablet) {
        padding: 20px;
      };

      .title {
        font-size: $small;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .wrapper {
        @include breakpoint($tablet) {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
        };

        @include breakpoint($laptop) {
          align-items: center;
          justify-content: space-between;
        };
      }

      .left {
        margin-bottom: 15px;

        @include breakpoint($tablet) {
          width: 62%;
        };

        @include breakpoint($laptop) {
          margin-bottom: 0;
          padding-right: 20px;
          width: 48%;
        };
      }

      .middle {
        margin-bottom: 15px;

        @include breakpoint($tablet) {
          padding-right: 20px;
          width: 45%;
        };

        @include breakpoint($laptop) {
          margin-bottom: 0;
          width: 25%;
        };
      }

      .right {
        white-space: nowrap;
        display: flex;
        justify-content: space-between;

        .next {
          @media(max-width: $tablet - 1) {
            flex: 1;
          }
          @include breakpoint($laptop) {
            width: 65%;
          }
        }
        .complete-action-cta {
          @include breakpoint($tablet) {
            padding-left: 10px;
            flex: 1;
            align-items: center;
            text-align: right;
          }

          @include breakpoint($laptop) {
            flex: unset;
          }
          @include breakpoint($laptop-l) {

          }
        }

        @include breakpoint($tablet) {
          padding-left: 20px;
          width: 50%;
          align-items: center;
        };

        @include breakpoint($laptop) {
          padding-left: 0;
          width: 27%;
        };
      }

      a {
        &:hover {
          text-decoration: none;
        }
      }

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;

        > div {
          color: $supporting-1;
          font-size: 1em;
          position: relative;
          margin: 0;
          padding: 0;
          display: flex;
          align-content: center;
          align-items: center;
          width: 33%;

          i {
            font-size: 12px;
            margin-right: 2px;
            font-style: normal;
          }

          .star-circle {
            font-size: 14px;
          }

          &:first-child {
            padding-left: 0;
          }

          &.difficulty:before {
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 3px;
            left: 0;
          }

          div, span {
            display: block;
          }

        }

      }

      .steps {
        color: $supporting-1;
        cursor: pointer;
        white-space: nowrap;

        @include breakpoint($laptop) {
          margin-left: 10px;
        };

        .status {
          font-weight: bold;

          em {
            font-style: normal;
          }

        }

        .action-progress-bar {
          height: 12px;
          background: $supporting-2;
          border-radius: 5px;
          margin-top: 5px;
          position: relative;

          .progress {
            background: $highlight-yellow-1;
            height: 12px;
            border-radius: 5px;
            position: absolute;
            top: 0;
            left: 0;
          }

        }

      }

      .completed,
      .next {

        .label {
          font-size: 1em;
          white-space: normal;
        }

        span {
          display: inline-block;
          color: $supporting-1;
          font-size: 1em;
          white-space: normal;
          padding-right: 3px;

          @include breakpoint($laptop) {
            display: block;
          } ;
        }
      }
    }

    .views-row-odd {
      background-color: $background-gray-3;
    }

    .views-row-even {
      background-color: $white;
    }
  }

  .modal-trigger {
    @include see-all-links;
    & {
      padding-left: $global-padding-desktop;
      padding-right: $global-padding-desktop;
    }
  }

  .remodal & > .view-content {
    padding: 0;
  }
}
