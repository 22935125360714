.block-badge-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  height: 195px;
  margin: 15px auto 0;

  .badge-wrapper {
    text-align: center;
    padding: 0 15px;
    margin-bottom: 15px;
    flex-basis: calc(100% / 3);
    position: relative;

    &:nth-child(n+7) {
      margin: 0;
    }

    &.level-0 {
      opacity: 0.2;
    }

    .badge-level-star-wrapper {
      display: flex;
      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
    }

    .badge-level-star {
      background: url("../images/star-icon-yellow.png") no-repeat center center transparent;
      background-size: 25px;
      width: 25px;
      height: 25px;
    }

    a {
      display: inline-block;
      max-width: 65px;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
