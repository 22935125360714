#privatemsg-list {

  > div > div {

    > .container-inline {

      * {
        margin: inherit;
      }

      > div {
        margin: 0 20px;
      }

      input[type="submit"] {
        @include cta($highlight-blue);
        & {
          margin: 0;
        }
      }

      .form-item {
        display: inline-block;

        select {
          margin: 0;
        }

        .selection {
          margin: 0;
        }

      }
    }

  }

  table {
    @include tableStyle;
  }

}
