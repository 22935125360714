.page-my-community-group {

  main.container .container {
    padding-left: 0;
    padding-right: 0;
  }

  .my-community-group-edit {
    padding: 30px 15px;
    margin: 0 auto;

    @media (min-width: $laptop) {
      width: susy-span(11 of 12);
      padding: 30px 0;
    }

    @media (min-width: $laptop-l) {
      width: susy-span(10 of 12);
    }

    .back,
    .create-post {

      a {
        @include blue-button;
        & {
          font-weight: bold;
          padding: 10px 15px;
        }
      }

    }

    .back {
      float: left;
    }

    .create-post {
      float: right;
    }

    .view-my-community-posts {
      clear: both;
      padding-top: 30px;
    }

  }

  .preview {
    padding: 0 15px;
    margin: 0 auto;

    @media (min-width: $laptop) {
      width: susy-span(11 of 12);
      padding: 0;
    }

    @media (min-width: $laptop-l) {
      width: susy-span(10 of 12);
    }

    .node {
      padding: 10px;
    }

  }

}
