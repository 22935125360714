.modal-badge-wrapper {
  text-align: center;
  max-width: 450px;
  margin: 0 auto;


  .name {
    font-size: 22px;
    font-weight: bold;
  }
}
