.action-footer {
  .difficulty {

    &.easy {

      &:before {
        background: url("../images/icon-star-black.svg") no-repeat top center;
        background-size: contain;
      }

    }

    &.medium {

      &:before {
        background: url("../images/icon-star-grey.svg") no-repeat top center;
        background-size: contain;
      }

    }

    &.challenging {

      &:before {
        background: url("../images/icon-star.svg") no-repeat top center;
        background-size: contain;
      }

    }
  }

}
