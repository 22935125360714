.tabs-wrapper {

  .nav-tabs {
    display: flex;
    width: 100%;
    padding: 0 4px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    justify-content: center;

    > li {

      a {
        background-color: $white;
        border: 2px solid $light-blue;
        border-radius: 4px;
        color: $light-blue;
        cursor: pointer;
        display: inline-block;
        font-size: $small-text;
        font-weight: bold;
        margin-right: 5px;
        padding: 7px 5px;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        transition: all .4s;
        z-index: 2;

        @include breakpoint($tablet) {
          padding: 11px 15px;
        };

        &:hover,
        &.active {
          color: $white;
          background-color: $light-blue;
        }
      }
    }
  }

  .tab-content {
    padding-top: 10px;

    .tab-pane {
      display: none;

      &.active {
        display: block;
      }

    }
  }

}

.ranks-block {
  .tabs-wrapper {
    .nav-tabs {
      padding-top: 20px;
    }
  }
}

.tab-pane {
  h2.strikethrough-background {
    text-align: left;
    margin: 30px 0 20px;

    @media (min-width: $laptop) {
      margin: 30px 0 30px;
    }

  }

  .description {
    margin: 0;
  }
}
