.node-action {

  #wrapper-question {
    .form-item {
      margin: 0;
    }
  }

  #action-impact {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 15px;
    margin-top: 10px;

    @include breakpoint($tablet) {
      flex-direction: row;
    }

    h2 {
      padding-bottom: 0;
      margin-bottom: 20px;
    }

    .field-action-sample-points {
      color: $green;
      font-size: 40px;
      font-weight: bold;

      @include breakpoint($tablet) {
        margin-left: 0.3em;
      }

      @include breakpoint($laptop) {
        text-align: center;
        align-self: flex-end;
        margin-bottom: 17px;
      }

      .points + span {
        font-size: 28px;
      }

    }

    .share-button.share-block {
      @include breakpoint($tablet) {
        margin-left: auto;
      }

      &:hover {
        color: $glacial-blue;
      }

      a {
        display: flex;
        align-items: center;
        font-weight: bold;
        padding: 15px 0;
        text-transform: uppercase;

        &:hover {
          color: $glacial-blue;
          text-decoration: none;
        }
      }

      i {
        font-size: 25px;
        vertical-align: middle;
        padding: 5px 0;
        position: relative;
        z-index: 10;
      }

      span {
        font-size: $small-text;
        line-height: 17px;
        vertical-align: middle;
        padding-left: 10px;
        // We need reset some properties.
        display: block !important;
        opacity: 1 !important;
      }
    }

    .share__social {
      bottom: 10px;
      left: 0;
      overflow: hidden;
      right: unset;
      transition: width .4s, right .4s;
      width: 0;

      &.expanded {
        left: unset;
        right: 0;
        width: 170px;
      }

      .at-share-btn-elements {
        align-items: baseline;
        display: flex;
        justify-content: flex-end;

        .at-share-btn {
          display: block;
          margin: 0 auto 8px auto;
          max-width: 35px;

          &:hover {
            transform: none;
          }
        }
      }
    }
  }

  #action-impact-values {
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0px 0px 10px $black-5;
    margin-top: 10px;
    padding: $global-padding-desktop;

    @include breakpoint($tablet) {
      display: flex;
      flex-wrap: wrap;
    }

    @include breakpoint($laptop) {
      padding: 25px 20px 0;
    }

    > div {
      border-bottom: 2px solid $background-gray-3;
      padding: $global-padding-desktop 16px;

      @include breakpoint($tablet) {
        border-bottom: medium none;
        border-right: 2px solid $background-gray-3;
        margin-bottom: 10px;
        padding-bottom: 20px;
        flex-basis: 50%;

        &:nth-child(2n) {
          border-right: medium none;
        }
      }

      @include breakpoint($laptop) {
        border-right: 2px solid $background-gray-3;
        font-weight: bold;
        padding: 5px 10px;
        flex-basis: 20%;

        &.time {
          flex-basis: 25%;
        }

        &.impact {
          flex-basis: 30%;
        }

        &:first-child {
          padding-left: 0;
        }

        &:nth-child(2n) {
          border-right: 2px solid $background-gray-3;
        }

        &:last-child {
          border-right: medium none;
          padding-right: 0;
        }
      }

      @include breakpoint($tablet, "down") {
        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: medium none;
          padding-bottom: 0;
        }
      }
    }

    @include breakpoint($tablet, true) {
      padding: 0 5px 30px;
    }

    @include breakpoint($laptop, true) {
      & {
        padding: 20px 5px 30px 5px;
      }
      .upfront, .time, .annual {
        display: flex;
        flex-wrap: wrap;

        & > div {
          flex-basis: 50%;

          &:first-child {
            flex-basis: 100%;
          }

          &:last-child {
            text-align: right;
          }
        }
      }

      .impact {
        display: flex;
        flex-wrap: wrap;

        & > div {
          flex-basis: 100%;

          &.impact__content {
            display: flex;

            & > div {
              flex-basis: 50%;

              &:last-child {
                text-align: right;
              }
            }
          }
        }
      }
    }

    .upfront-cost,
    .time-commitment,
    .impact-name,
    .annual-savings {
      color: $supporting-1;
      font-size: $global-font-size;
      text-transform: uppercase;
    }

    .cost-range,
    .approximate-time,
    .impact__content .stat,
    .field-action-sample-savings {
      color: $light-blue;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 6px;

      @include breakpoint($laptop) {
        display: inline-block;
        font-size: 18px;
        margin-top: 6px;
      }
    }

    .stats-icons,
    .impact__icons {
      font-size: $lisa-medium-text;

      @include breakpoint($laptop) {
        font-size: $text-font-size;
        float: right;
      }
    }

    .stats-icons {
      @include breakpoint($laptop) {
        margin-top: 6px;
      }
    }

    .stat-num,
    .stat-label {
      display: inline-block;
    }

    .stat-label {
      font-size: $text-font-size;
    }

    .field-name-field-action-cost-low,
    .field-name-field-action-cost-high {
      display: inline-block;
    }

    .icon-upfront {
      &:after {
        color: $supporting-2;
        content: '\f155';
        font-family: FontAwesome;
      }

      &.active {
        &:after {
          color: $supporting-1;
        }
      }
    }

    .time {
      .field-name-field-action-time {
        display: inline-block;

        > div {
          display: inline-block;
        }
      }

      .icon-time {
        &:after {
          color: $supporting-2;
          content: '\f017';
          font-family: FontAwesome;
        }

        &.active {
          &:after {
            color: $supporting-1;
          }
        }
      }
    }

    .icon-fo {
      text-align: center;

      &:after {
        content: '\f613';
        width: 17px;
        height: 17px;
        position: relative;
        display: inline-block;
        color: $supporting-2;
        font-size: 18px;
      }

      &.active:after {
        color: black;
      }

      + .icon-fo {
        margin-left: 6px;
      }
    }

    .icon-savings {
      &:after {
        background: url('../images/icon-savings.svg') no-repeat center;
        background-size: contain;
        content: '';
        display: inline-block;
        height: 22px;
        position: relative;
        width: 22px;
      }

      &.active {
        &:after {
          background-image: url('../images/icon-savings-active.svg');
        }
      }
    }

    .icon-drop {
      &:after {
        color: $supporting-2;
        content: '\f043';
        display: inline-block;
        font-family: FontAwesome;
        position: relative;
      }

      &.active {
        &:after {
          color: $supporting-1;
        }
      }
    }

    .icon-gas {
      &:after {
        background: url('../images/gas.svg') no-repeat center;
        background-size: contain;
        content: '';
        display: inline-block;
        height: 22px;
        position: relative;
        width: 22px;
      }

      &.active {
        &:after {
          background-image: url('../images/gas-active.svg');
        }
      }
    }

    .icon-therms {
      &:after {
        background: url('../images/therms.svg') no-repeat center;
        content: '';
        display: inline-block;
        height: 22px;
        position: relative;
        width: 22px;
      }

      &.active {
        &:after {
          background-image: url('../images/therms-active.svg');
        }
      }
    }

    .icon-bolt {
      &:after {
        color: $supporting-2;
        content: '\f0e7';
        display: inline-block;
        font-family: FontAwesome;
        position: relative;
      }

      &.active {
        &:after {
          color: $supporting-1;
        }
      }
    }
  }

  .action__question {
    background-color: $background-gray-7;
    border-radius: 0px 0px 3px 3px;
    font-size: $global-font-size;
    line-height: 26px;
    margin-bottom: $global-padding-desktop;
    padding: 13px 45px;

    .select2-container,
    .form-text {
      background: #FFFFFF;
      border: 1px solid #CBCDD1;
      border-radius: 3px;
      display: block;
      font-size: $global-font-size;
      margin: 8px 0;
      padding: 8px 15px;

      @include breakpoint($laptop) {
        display: inline-block;
      }
    }

    .select2-container--default {
      .select2-selection__arrow {
        right: 10px;
        top: 50%;
        transform: translateY(-50%);

        b {
          border-left-width: 6px;
          border-right-width: 6px;
          border-top-color: $light-blue;
          border-top-width: 8px;
        }
      }

      &.select2-container--open {
        .select2-selection--single {
          .select2-selection__arrow {
            b {
              border-bottom-color: $light-blue;
              border-bottom-width: 8px;
              border-left-width: 6px;
              border-right-width: 6px;
              border-top-color: transparent;
              border-top-width: 0;
            }
          }
        }
      }
    }

    .submit-wrapper {
      display: none;
    }
  }

  #action-plan {
    margin-bottom: 20px;

    h2 {
      margin: 0;
    }
  }

  .action-steps-number {
    color: $green;
    font-size: $lisa-medium-text;
    font-weight: bold;
    margin-bottom: 36px;

    @include breakpoint($tablet) {
      margin-left: 1em;
    }

    @include breakpoint($laptop) {
      text-align: center;
    }
  }

  .action-intro {
    margin-bottom: 30px;

    h3 {
      @include accordion-title;

      & {
        font-size: 20px;
      }

      &:after {
        transform: rotate(45deg);
      }

      @include breakpoint($laptop) {
        font-size: 24px;
        text-align: left;

        &:after {
          display: block;
        }

        span {
          display: block;

          &:after {
            width: 100%;
          }
        }
      }
    }

    .intro-content {
      color: $black;
      font-size: 20px;
      line-height: 30px;

      p {
        margin: 0;
      }
    }

    &.expanded {
      h3 {
        &:after {
          transform: rotate(225deg);
        }
      }
    }
  }

  .already-completed-action {
    margin-left: auto;
  }

  .action-completed-wrap {
    margin-bottom: 10px;
    text-align: right;

    .tooltip-intro {
      display: inline-block;
    }
  }

  .action-plan-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    justify-content: flex-start;

    @include breakpoint($tablet) {
      align-items: baseline;
      flex-direction: unset;
    }

    h2 {
      padding: 0;
    }

  }

  .remove-action {
    display: block;
    font-size: $text-font-size;
    font-style: italic;
    margin-bottom: 20px;
    text-align: right;

    @include breakpoint($tablet) {
      margin-left: auto;
    }
  }

  .action__started-links {
    background: $background-gray-1;
    color: $white;
    margin-left: -$global-padding-mobile;
    margin-right: -$global-padding-mobile;
    padding: 25px 20px;

    @include breakpoint($tablet) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    @include breakpoint($laptop) {
      margin: 0;
    }

    .trigger-date {
      text-align: center;
      margin-bottom: 12px;

      @include breakpoint($tablet) {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 0;
      }

      span {
        display: inline-block;

        a {
          font-size: 20px;
          margin-left: 0.5em;
        }
      }
    }

    > div {
      width: 100%;
      text-align: center;

      @include breakpoint($tablet) {
        text-align: right;
      }

    }

    // In progress action
    span {
      display: block;
      font-size: 20px;
      text-align: center;

      @include breakpoint($tablet) {
        display: inline-block;

        &:last-child {
          text-align: right;
        }
      }

      + span {
        margin-bottom: 0;

        a {
          font-size: $global-font-size;
        }
      }

      a {
        font-size: $small-text;
        color: $white;
      }

      + .get-started {
        &.btn {
          @include breakpoint($tablet) {
            margin: 0;
          }
        }
      }
    }

    // Started or completed actions
    .get-started {
      &.btn {
        background: $background-blue;
        border: solid 2px $highlight-blue;
        border-radius: 4px;
        color: $white;
        display: block;
        margin: 0 auto;
        max-width: 220px;
        padding: 12px 10px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;

        @include breakpoint($tablet) {
          width: 180px;
        }
      }
    }

    .step-completed-date {
      &.wrapper {
        padding-top: 7px;
      }
    }
  }

  // Accordion
  #action-accordeon {
    margin-left: -$global-padding-mobile;
    margin-right: -$global-padding-mobile;

    @include breakpoint($laptop) {
      margin: 0;
    }

    .action__heading-step {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      border-bottom: 2px solid $white;
      cursor: pointer;
      margin: 5px 0 0;
      padding: 30px 45px 30px 15px;
      position: relative;

      @include breakpoint($laptop) {
        flex-wrap: nowrap;
        padding: 15px;
      }

      &.introduction {
        @include breakpoint($tablet) {
          height: 59px;
        }

        h3 {
          height: 40px;
          margin: 0;
          padding-top: 8px;
        }
      }

      &:after {
        color: $text-color;
        content: "\f078";
        font-family: FontAwesome;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);

        @include breakpoint($laptop) {
          top: 30px;
        }

      }

      &.open:after {
        content: "\f077";
      }
    }

    .action-action-step {
      background-color: $white;
      box-shadow: 0 4px 2px 0 #DEDEE5;
      margin-bottom: 10px;
    }

    .action__step-title {
      width: 100%;
      align-items: center;
      color: $text-color;
      display: flex;
      font-size: 20px;
      line-height: 20px;
      margin: 0 0 20px;

      @include breakpoint($tablet) {
        width: auto;
        font-size: 24px;
        line-height: 24px;
        margin: 0;
      }

      .step-id {
        border-radius: 50%;
        border: 1px solid $text-color;
        color: $text-color;
        height: 35px;
        line-height: 35px;
        margin-right: 10px;
        min-width: 35px;
        text-align: center;
        width: 35px;

        @include breakpoint($laptop) {
          height: 40px;
          line-height: 40px;
          min-width: 40px;
          width: 40px;
        }

      }

      .step-id.completed {
        font-size: 0;

        &:after {
          content: '\f00c';
          font-family: FontAwesome;
          font-size: 20px;
        }
      }
    }

    .step-status {
      width: 100%;
      text-align: left;

      @include breakpoint($tablet) {
        width: auto;
        padding-right: 10px;
      }

      .step-in-progress {

        @include breakpoint($tablet) {
          width: 285px;
          display: flex;
          justify-content: space-between;
        }

        > a {
          display: inline-block;
          margin: 1px;
          white-space: nowrap;
        }

        @include breakpoint($laptop) {
          display: flex;
          margin-right: 60px;
          margin-top: 0;

          > a:first-child {
            margin-right: 10px;
          }
        }
      }

      .create-post {
        border: 1px solid $supporting-1;
        border-radius: 3px;
        color: $supporting-1;
        margin-right: 10px;
        padding: 7px 15px;
        text-decoration: none;
        transition: all .4s;

        &:hover {
          border: 1px solid $text-color;
          color: $text-color;
        }
      }

      .mark-completed,
      .mark-complete {
        background: $supporting-1;
        border-radius: 3px;
        color: $white;
        margin: 0;
        padding: 8px $global-padding-mobile;
        text-decoration: none;
        transition: all .4s;

        &:hover {
          background: $text-color;
        }
      }

      .step-completed-date {

        .wrapper {
          background: $text-color;
          border-radius: 3px;
          color: $white;
          display: inline-block;
          font-weight: bold;
          padding: 10px $global-padding-mobile;

          @include breakpoint($laptop) {
            margin-right: 60px;
            margin-top: 0;
          }
        }
      }
    }

    .in-progress {
      .action__step-content {
        display: block;
      }
    }

    .action__step-content {
      display: none;

      .wrapper {
        @include global-container;
        @include clearfix;

        & {
          line-height: 24px;
          padding: $global-padding-desktop;
          background: $white;
          border-top: 2px solid $background-gray-2;
        }

        @include breakpoint($tablet) {
          padding-bottom: $global-padding-desktop;
          padding-top: $global-padding-desktop;
        }
      }

      @include breakpoint($tablet, "down") {
        .media-float-right {
          margin: 0;
        }
      }

      p {
        margin: 0;
      }

      .element-invisible {
        display: none;
      }

      img {
        max-width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;

        @include breakpoint($tablet) {
          margin-bottom: 0;
          margin-top: 0;
        }
      }

      ul {
        @include unordered-list;
      }
    }
  }

  #action-resources {
    h2 {
      margin-bottom: 30px;
    }

    @include breakpoint($tablet) {
      .items {
        columns: 2;

        .views-row {
          break-inside: avoid;
        }
      }
    }
  }

  .field-item {
    &:last-child {
      .action__step-content {
        .wrapper {
          border-bottom: medium none;
        }
      }
    }
  }

  .buy-offset {
    background: $background-blue;
    border: solid 2px $highlight-blue;
    border-radius: 4px;
    color: $white;
    display: block;
    font-weight: bold;
    margin: 0 auto;
    max-width: 220px;
    padding: 12px 10px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
    }

    @include breakpoint($tablet) {
      width: 180px;
    }
  }
}
