.page-403 {

  .block-system > .content {
    @include new-container;

    & {
      font-size: 20px;
      text-align: center;
      padding: 20px;
    }

    @media (min-width: $laptop) {
      padding: 40px 20px;
    }

    &:before {
      content: '403';
      display: block;
      color: $highlight-blue;
      font-size: 80px;
      font-weight: 900;
    }

  }

}
