.community_group_form {
  font-size: $text-font-size;
  padding: 10px $modalFlank !important;

  .form-item,
  #edit-state-and-city {
    margin: 25px 0 0 0 !important;

    &.form-item-name {
      margin-top: 0 !important;
    }

  }

  #edit-state-and-city {

    .fieldset-wrapper {

      @media (min-width: $tablet) {
        display: flex;
      }

      .form-item {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

      }
    }

  }

  .description {
    font-size: $small-text;
  }

  fieldset {
    legend {
      padding: 0;

      span {
        font-size: $text-font-size !important;
      }
    }
  }

  textarea {
    font-family: $font-family-sans-serif;
  }
}
