// Global layout properties.
html.js input.form-autocomplete {
  background-image: none;
}

html {
  body {
    margin-left: 0;
    margin-right: 0;
    position: relative;
  }

  body.admin-menu {
    // !important declaration as toolbar module already uses !important.
    margin-top: 0 !important;

    @media (min-width: $laptop) {
      padding-top: $toolbar-height-l !important;
    }
  }
}

#admin-menu {
  display: none;

  @media (min-width: $laptop) {
    display: block;
  }
}

.ajax-progress-throbber {
  display: none
}

.messages {
  margin: 0;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: $text-font-size;

  .item-list {
    margin-top: 8px;
  }

  ul {
    list-style-type: disc;
  }

  &.error, &.warning {
    background: $highlight-orange;
    font-weight: bold;
  }

  &.error, &.status, &.warning {
    background-image: none;
    color: $white;
    border: none;
    padding: 20px;
  }

  &.status {
    background: $background-green;
    color: $white;
  }

  a {
    color: $white;
    text-decoration: underline;
  }
}

.content {
  //max-height: 100vh;
  width: 100%;
  z-index: 1;

  @include breakpoint($laptop) {
    max-height: none;
  }
}

.page {
  display: flex;
  overflow: hidden;

  @include breakpoint($laptop) {
    overflow: visible;
  }

  &__overlay {
    display: none;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    background-color: $background-gray-1-transparent-2;
    top: 0;
    left: 0;
    position: fixed;
  }
}

header, .logo {
  display: flex;
  align-items: center;
  align-content: center;
}

.logo {
  height: $logo-height-m;

  @include breakpoint($laptop) {
    padding-left: 40px;
  }

  &__subtitle {
    display: none;

    @include breakpoint($tablet) {
      display: block;
      padding-left: 20px;
    }
  }

  @include breakpoint($tablet) {
    height: $logo-height-l;
  }

  img {
    height: auto;
    max-height: $logo-height-m;
    max-width: $logo-width-m;
    object-fit: contain;

    @include breakpoint($tablet) {
      max-height: $logo-height-l;
      max-width: none;
    }

    & + img {
      margin-left: $global-padding-mobile;

      @include breakpoint($tablet) {
        margin-left: $global-padding-desktop;
      }
    }
  }

  a + img {
    margin-left: 10px;

    @include breakpoint($tablet) {
      margin-left: $global-padding-desktop;
    }
  }
}

.main-content {
  // TODO: Choose better selector
  // .container-fluid is for user dashboard
  // .content is for all the other pages
  > .container-fluid,
  > .content {
    h2:not(#dashboard-name) {
      @include accordion-title;
    }
  }
}

.has-sidebar {

  @include breakpoint($laptop) {
    display: flex;
    width: 100%;
  }

  .main-content {
    flex-basis: 100%;

    @include breakpoint($laptop) {
      flex-basis: 70%;
      flex-shrink: 0;
    }

    .container {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .layout-sidebar {
    background: $background-gray-4;
    display: none;
    padding: $global-padding-mobile;

    @include breakpoint($laptop) {
      display: block;
      width: 30%;
      flex-grow: 0;
      padding: $global-padding-desktop;
    }

    .create-post {
      @include global-box-shadow;

      display: inline-block;
      font-size: 1em;
      color: $white;
      background-color: $background-green;
      padding: 10px 20px;
      line-height: 1;
      border-radius: 5px;
      border: none;
      transition: all .4s;

      &:hover, &:focus {
        background-color: lighten($background-green, 5%);
        text-decoration: none;
      }

    }

    .view-filters {
      float: right;

      .views-exposed-widgets {
        margin: 0;
      }

      .views-exposed-widget {
        padding: 0;
      }

      .form-type-select {
        display: flex;
        align-items: center;

        label {
          margin-right: 7px;
        }

      }

    }

    .view-content {
      clear: both;
      padding-top: 15px;
    }

  }

}

footer {
  background: $background-gray-2;

  ul {
    margin: 0 auto;
    padding: 30px 0;
    width: susy-span(10 of 12);

    @media (min-width: $laptop) {
      display: flex;
    }

    li {
      padding: 5px 0;

      @media (min-width: $laptop) {
        padding: 10px;
      }
    }
  }

  nav {
    margin: 0 auto;
  }
}

main {
  margin: 0 auto;
}

.container {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $laptop) {
    max-width: $laptop-l;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: 1200px) {
    max-width: $laptop-l;
    width: 100%;
  }

  .row {
    margin-left: 0;
    margin-right: 0;

    @media (min-width: $laptop-l) {
      margin-left: -15px;
      margin-right: -15px;
    }

  }

}

// Fixes for Nested Containers.
main.container .container .container {
  width: auto;

  @media (min-width: $tablet) {
    margin-left: -15px;
    margin-right: -15px;
  }

}

// Fix bottom white space problem.
.panel-display.section-3col.clearfix.panels-sections-3col {
  font-size: 0;

  > .main-content {
    font-size: 14px;
  }
}

.signup__content .select2 {
  z-index: 0;
  max-width: 100%;
}

.global-container {
  @include global-container;
}

.church-letter-container {
  @include church-letter-container;
}

// Regions page custom layout per client request.
.regions-container {
  @include global-container;
  @media (min-width: $tablet) {
    padding: 0 40px;
  }
}
