@mixin trigger {
  width: calc(100% - 10px);
  border-bottom: 1px solid $supporting-2;
  padding: 10px 40px 10px 15px !important;
  margin: 0 5px !important;
  position: relative;

  &.active {
    background: linear-gradient(to bottom, rgba(219, 219, 219, 1) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dbdbdb', endColorstr='#ffffff', GradientType=0);
    border-bottom: none;
    padding-bottom: 15px;
    margin: 0 5px 10px !important;

    &:after {
      background: url("../images/icon-caret-up.svg") no-repeat center;
      background-size: contain;
    }

  }

  &:after {
    content: "";
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url("../images/icon-caret-down.svg") no-repeat center;
    background-size: contain;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: 10px;
  }

}

@mixin accordion-content {
  display: none;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: height 500ms, opacity 300ms;

  &:not(.open) {
    padding: 0 !important;
    margin: 0 !important;

    * {
      padding: 0 !important;
      margin: 0 !important;
    }

  }

  &.open {
    display: block;
    height: auto;
    opacity: 1;
    visibility: visible;
  }

}

.my-community-group-template, .page-user, .page-welcome {
  .main-content {
    h2.section-title, h2.strikethrough-background {
      text-align: left !important;
    }
  }
}

// User
body.page-user {

  .pane-c02free-user-user-impact-co2 {
    @media (max-width: $laptop - 1px) {
      width: 100%;
      margin: 0;
    }
  }

  .pane-c02free-user-user-impact-co2 .pane-content > .graph-baseline.open,
  .pane-c02free-user-user-impact-co2 .pane-content > .congratulations-text.open,
  .pane-action-stats-panel-pane-1 > .pane-content.open,
  .pane-action-stats-panel-pane-1 + .twocol.open .twocol__container,
  .pane-user-dashboard-my-actions .view-my-action-list.open {

    @media (max-width: $laptop - 1px) {
      margin-bottom: 30px;
    }

  }

  .pane-action-stats-panel-pane-1 + .twocol,
  .pane-c02free-user-user-impact-co2 .pane-content > .graph-baseline,
  .pane-c02free-user-user-impact-co2 .pane-content > .congratulations-text,
  .pane-c02free-user-user-average-impact-person {

    @media (max-width: $laptop - 1px) {
      @include accordion-content;
    }

  }

  .section-three {

    .panel-pane:last-child {
      margin-bottom: 20px;

      @media (min-width: $laptop) {
        margin-bottom: 0;
      }

    }

  }

  .strikethrough-background {
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: $tablet) {
      padding-left: 0;
      padding-right: 0;
    }

  }

  // TEMP: User dashboard - My progress accordion
  .pane-action-stats-panel-pane-1 {
    > h2 {
      ~ .pane-content,
      ~ .panel-pane {
        @include accordion-hidden;

        .pane-content {
          @include accordion-hidden;

          @media (min-width: $laptop) {
            flex-wrap: wrap;
          }
        }
      }

      &.active {
        ~ .pane-content,
        ~ .panel-pane {
          @include accordion-shown;

          .pane-content {
            @include accordion-shown;
          }
        }
      }
    }
  }

  // TEMP: User dashboard - My actions accordion
  .pane-user-dashboard-my-actions {
    h2 {
      + .actions-subtitle,
      ~ .stat2__container,
      ~ .view-my-action-list {
        @include accordion-hidden;

        .view-content {
          width: 100%;
        }
      }

      &.active {
        + .actions-subtitle,
        ~ .stat2__container,
        ~ .view-my-action-list {
          @include accordion-shown;

          .view-content {
            width: 100%;
          }
        }
      }

      @include breakpoint($laptop, true) {
        &:not(.active) {
          ~ .stat2__container {
            margin-bottom: 0;
          }
        }
      };
    }
  }

  // TEMP: User dashboard - My impact accordion
  .section-four {
    @include accordion-hidden;

    &.open {
      @include accordion-shown;
    }
  }
}

// Team page
.node-team {
  .progress-block {
    > h2 {
      ~ .stat2__container {
        @include accordion-hidden;
      }

      &.active {
        ~ .stat2__container {
          @include accordion-shown;
        }
      }
    }
  }
  .members-block {
    .actions-group {
      flex-basis: 100%;

      @media (min-width: $laptop) {
        flex-basis: unset;
      }
    }

    > h2 {
      ~ .content-wrapper {
        @include accordion-hidden;

        @media (min-width: $mobile-s) {
          flex-wrap: wrap;
        }

        & {
          width: 100%;
        }
      }

      &.active {
        ~ .content-wrapper {
          @include accordion-shown;

          @media (min-width: $mobile-s) {
            flex-wrap: wrap;
          }

          & {
            width: 100%;
          }
        }
      }
    }
  }
  .standings-block,
  .actions-block {
    > h2 {
      ~ .wrapper {
        @include accordion-hidden;
      }

      &.active {
        ~ .wrapper {
          @include accordion-shown;
        }
      }
    }
  }
}

// My community group page
.page-my-community-group {
  .progress-block {
    > h2 {
      ~ .stat2__container,
      ~ .actions-stats-block {
        @include accordion-hidden;
      }

      &.active {
        ~ .stat2__container,
        ~ .actions-stats-block {
          @include accordion-shown;
        }
      }
    }
  }

  .members-community-block {
    > h2 {
      ~ .content-wrapper {
        @include accordion-hidden;

        .leads-group {}
      }

      &.active {
        ~ .content-wrapper {
          @include accordion-shown;
        }
      }
    }

    > .content-wrapper {
      flex-direction: column;
      justify-content: unset;
    }
  }

  .ranks-block {
    > h2 {
      ~ .wrapper-block-content {
        @include accordion-hidden;
      }

      &.active {
        ~ .wrapper-block-content {
          @include accordion-shown;
        }
      }
    }
  }
}

// Region
.my-community-region-template {
  .our-impact {
    > h2 {
      ~ .region-impact,
      ~ .stat2__container,
      ~ .actions-stats-block {
        @include accordion-hidden;
      }

      &.active {
        ~ .region-impact,
        ~ .stat2__container,
        ~ .actions-stats-block {
          @include accordion-shown;
        }
      }

      @include breakpoint($laptop, down) {
        &:not(.active) {
          ~ .region-impact,
          ~ .actions-stats-block {
            padding: 0;
          }
        }
      };
    }
  }

  .our-actions {
    > h2 {
      ~ .ranks-block {
        @include accordion-hidden;
      }

      &.active {
        ~ .ranks-block {
          @include accordion-shown;
        }
      }
    }
  }

  .news-resources {
    > h2 {
      ~ .view-community-news {
        @include accordion-hidden;
      }

      &.active {
        ~ .view-community-news {
          @include accordion-shown;
        }
      }
    }
  }
}
