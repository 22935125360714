.remodal[data-remodal-id="modal-add-badge"] {

  @media (min-width: $laptop-l) {
    max-width: 1200px;
  }

  .badge-modal.row {
    height: calc(100% - 60px);
  }

}

.remodal-wrapper .remodal .badge-modal {
  border-radius: 5px;
  z-index: 1000;
  text-align: center;
  background: white;

  > div {
    margin-bottom: 40px;

    @media (min-width: $tablet) {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

  }

  h3 {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;

    @media (min-width: $tablet) {
      margin-top: 1em;
      margin-bottom: 1em;
    }

  }

  img {
    width: 100%;
    max-width: 150px;

    @media (min-width: $tablet) {
      max-width: initial;
    }

  }

  legend {
    display: block;
    width: 100%;
    color: #535761;
    font-size: 15px;
    line-height: inherit;
    border: 0;
    padding: 0;
  }

}
