#c02free-user-picture-form {
  $grey-text: #8c909c;
  $blue: #5697ea;

  .form-item {
    margin: 0;

    > label {
      display: inline-block;
      max-width: 100%;
      color: $grey-text;
      font-weight: normal;
      margin-bottom: 5px;
    }

    input[type="file"] {
      display: block;
      width: auto;
      font-size: 18px;
      border: 2px solid rgba(140, 144, 155, 0.12156863);
      border-radius: 5px;
      padding: 4px;
      margin: 10px auto;
    }

    #edit-identity-image-upload-button {
      display: none;
    }

    .description {
      display: block;
      color: $grey-text;
      font-size: 14px;
      margin-top: 5px;
      margin-bottom: 10px;
    }

  }

  input[type="submit"] {
    @include link;
    color: $blue;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.42857143;
    text-transform: uppercase;
    background: white;
    border-radius: 4px;
    border: 2px solid $blue;
    padding: 8px 18px 7px;
    margin-top: 15px;

    &:hover {
      color: white;
      background: $blue;
    }

  }

}
