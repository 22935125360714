.pane-c02free-user-user-average-impact-person {
  @include global-container;

  h3 {
    font-size: $global-font-size;
    text-align: center;

    @include breakpoint($tablet) {
      font-size: 24px;
      text-align: left;
    }
  }

  .pane-content {
    position: relative;

    @include breakpoint($laptop) {
      padding: 20px;
    }
  }

  .my-impact-main-text {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .global-card__inner {

    &.hide-block {
      display: none;
    }

    h3 {
      margin-top: 0;
    }

  }

  .left {
    margin-bottom: 30px;

    @include breakpoint($tablet) {
      border-right: 1px solid $supporting-2-transparent-2;
      margin-bottom: 0;
      margin-right: 30px;
      padding-right: 30px;
      width: 35%;
    }

    .wrapper {
      margin: 0 auto;
      width: 80%;

      @include breakpoint($tablet) {
        display: flex;
        flex-direction: column;
        height: calc(100% - 77px);
        padding-top: 30px;
        width: 100%;
      }

      > div:first-child {
        margin-bottom: 15px;

        @include breakpoint($tablet) {
          margin-bottom: 55px;
        }
      }
    }
  }

  .right {
    @include breakpoint($tablet) {
      width: 65%;
    }
  }

  .field-tons-per-year {
    display: flex;
    align-items: flex-start;

    i {
      color: $text-color;
      display: block;
      float: left;
      font-size: 31px;
      margin-right: 10px;
      padding-top: 4px;
      text-align: center;
      width: 35px;
    }

    .tons-number {
      color: $light-blue;
      font-size: $big-text;
      font-weight: 600;
      line-height: 1;
      justify-content: space-between;
      text-align: right;
      width: 130px;

      @include breakpoint($tablet) {
        font-size: $medium-text;
        width: 110px;
      }

      @include breakpoint($laptop) {
        display: flex;
        align-items: flex-end;
      }

      i {
        @include breakpoint($laptop) {
          padding-bottom: 4px;
        }
      }

    }

    .tons-label {
      color: $supporting-1;
      font-size: $text-font-size;
      padding-left: 10px;
      padding-top: 17px;

      span {
        text-transform: uppercase;
      }
    }
  }
}
