.ajax-progress,
.ajax-progress-throbber,
.ajax-progress-fullscreen {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  opacity: 1;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  overflow: hidden;

  .throbber {
    display: none !important;
  }

}

.ajax-progress:before,
.ajax-progress-throbber:before,
.ajax-progress-fullscreen:before {
  content: " ";
  display: block;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 0.8s infinite linear;
  animation: spin 0.8s infinite linear;
  border-radius: 120px;
  border-width: 10px;
  border-style: solid;
  border-color: rgba(210, 211, 218, 1) transparent rgba(218, 211, 210, 1) transparent;
  overflow: hidden;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
