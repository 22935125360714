.pane-user-dashboard-my-actions {
  @include global-container;
  & {
    background-color: $white;
    border: none;
  }

  @include breakpoint($laptop) {
    margin-bottom: 20px;
  }

  .pane-content {
    position: relative;
  }

  .actions-title {
    margin-bottom: 0;
  }

  .actions-subtitle {
    display: inline-block;
    color: $light-blue;
    font-size: 20px;
    margin: 20px 0;

    @include breakpoint($laptop) {
      margin: 0;
      position: absolute;
      top: 23px;
      right: 0;
    }

    a {
      display: inline-block;
      text-decoration: none;
    }
  }

  .pane-title {
    display: none;
  }

  .stat__container {
    padding-top: 48px;
    margin-bottom: 30px;

    @media (min-width: $laptop) {
      padding-top: 0;
    }
  }

  .wrapper-link {
    position: absolute;
    top: 50px;
    right: 10px;

    @media (min-width: $laptop) {
      top: -15px;
      right: 0;
    }

  }

  .actions-link {
    @include link;
    display: inline-block;
    width: 55px;
    height: 50px;
    background: url("../images/icon-star-blue.svg") left top no-repeat;
    background-size: 45px;
    padding-top: 20px;
    padding-bottom: 15px;
    position: relative;

    &:hover {
      opacity: .8;
    }

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      background: url("../images/icon-more-blue.svg") no-repeat center;
      background-size: 20px;
      display: inline-block;
      position: absolute;
      right: 0;
      bottom: 0;
    }

  }

  .strikethrough-background {
    margin: 0 0 15px;
  }

}

.view-id-my_action_list.view-display-id-panel_pane_2 {
  > h3 {
    display: none;
  }
}
