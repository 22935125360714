.node-community-group-status.node-teaser,
.modal-default .ctools-modal-content #modal-content .node-community-group-status.node-teaser {
  padding: 0 20px;
  position: relative;

  @media (min-width: $tablet) {
    padding: 20px 20px 0;
  }

  .contextual-item-actions {
    position: absolute;
    top: 6px;
    right: 6px;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    margin-bottom: 20px;

    .info {
      display: flex;
      align-items: center;

      .user-picture {
        display: inline-block;
        padding-right: 10px;

        a {
          display: inline-block;
        }

        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 100%;
        }

      }

      > a {
        margin: 0;
        font-weight: bold;
        font-size: 16px;
        padding-left: 0;
        text-decoration: none;
      }

    }
  }

  .submitted {
    font-size: 12px;
  }

  .type {
    margin-bottom: 20px;
    padding: 10px 20px;
    border-top: 1px solid lighten($supporting-1, 40%);
    border-bottom: 1px solid lighten($supporting-1, 40%);
    background-color: lighten($supporting-1, 40%);
    color: $white;
    font-size: 14px;
    margin-left: -20px;
    margin-right: -20px;

    div {
      display: inline;
      padding-left: 0;
      position: relative;

      i {
        display: none;
        margin-right: 10px;
      }

      &.action-step {
        &:not(.completed) i.fa-star {
          display: initial;
        }

        &.completed i.fa-check {
          display: initial;
        }

        // background: url("../images/icon-star-grey.svg") no-repeat left center;
        // background-size: 15px;
      }

      &.action {
        i.fa-check {
          display: initial;
        }

        a {
          color: #FFF;
        }
        // background: url("../images/icon-checked-light-blue.svg") no-repeat left center;
        // background-size: 13px;
      }

      &.team,
      &.community-group {
        i.fa-users {
          display: initial;
        }

        // background: url("../images/icon-team-light-blue.svg") no-repeat left center;
        // background-size: 15px;
      }

      &:after {
        content: ">";
        display: inline-block;
        font-size: 10px;
        margin-left: 5px;
        position: relative;
        bottom: 2px;
      }

    }

  }

  .content {
    margin-bottom: 20px;

    h1, h2, h3, h4, h5, h6 {
      font-size: 18px;
      margin: .5em 0;
    }

    p {
      font-size: 14px;

      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }

  }

  .icons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $light-blue;

    > div {
      display: flex;
    }

    .left {
      align-items: flex-end;
    }

    .right {
      align-items: center;
    }

    .comment-trigger, .comments-count {
      cursor: pointer;
      color: $supporting-1;

      i {
        font-size: 1em;
        margin-right: .5em;
        color: inherit;
      }

      &:hover,
      &.active {
        color: $highlight-blue;

        i {
          color: $highlight-blue;
        }
      }

      .counter div {
        display: inline;
      }
    }

    .comments-count {
      margin-right: .5em;
    }

    .likes-wrapper {
      display: flex;
      align-items: center;

      .likes-count {
        color: $text-color;
        margin-left: .5em;
        order: 2;
      }

      .like {
        line-height: 1;

        a span {
          @include link;
          display: inline-block;
          width: 16px;
          color: $light-blue;
          font-size: 14px;
          text-indent: -1000em;
          overflow: hidden;

          &.unflagged {
            background: url("../images/icon-like-stroke.svg") no-repeat right top;
            background-size: 16px;
          }

          &.flagged,
          &:hover,
          &.active {
            background: url("../images/icon-like.svg") no-repeat right top;
            background-size: 16px;

            i {
              color: $text-color;
            }
          }

        }
      }
    }

  }

  .field-name-field-status-image {
    margin-bottom: 20px;

    img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }

  }

  .show-all-comments {
    display: block;
    text-align: left;
    margin: 0 0 10px 0;
  }

  // Comments visibility

  .comment-wrapper {
    .comment.has-comments:not(.open) > .hide-replies {
      display: none;
    }

    .comment.has-comments.open > .view-replies {
      display: none;
    }

    .comment {
      display: none;
    }

    &.open > .comment,
    > .comment.visible {
      display: block !important;
    }

    .open > .comment,
    .open > div:not([class]) > .comment {
      display: block;
    }

    //.indented {
    //  display: none;
    //}

    //.comment.open + .indented,
    //.comment.open + .indented > .comment,
    //.comment.open + .indented div:not(.indented) .comment {
    //  display: block;
    //}

    .comment {
      display: none;
    }

    .comment.open {
      display: block;
    }

    // Positioning
    .comment > .replies {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
    }
  }

  // End Comments visibility

  #comments {
    margin-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px;
    background-color: $supporting-2-transparent-1;

    .comment {
      border: none;
      padding: 0 0 10px;
      margin: 0;

      .user-picture {
        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
        }
      }

      &-title {
        font-size: 14px;
        font-weight: normal;
      }
    }

    .form-type-textarea {
      margin-bottom: 20px;
    }

    form {
      padding: 0;

      textarea {
        @include global-box-shadow;
        max-width: 100%;
        min-width: 100%;

        padding: 10px 20px;
        border: none;
        border-radius: 50px;
        font-size: 1em;
        color: $text-color;
      }

      .grippie {
        display: none;
      }

      input[type="submit"] {
        display: none;
      }
    }

    // Since a few up lines there is a very aggressive form
    // styles we need to reset some styles to the original ones.
    form.confirmation {
      input[type="submit"] {
        display: inline-block;
      }
    }
  }

}

// On sidebar
.view .node-community-group-status.node-teaser,
.panel-pane .node-community-group-status.node-teaser {

  .heading {
    .info .user-picture {

      > a {
        vertical-align: middle;
      }

      > img {
        vertical-align: middle;
      }

      img {
        width: 40px;
        object-fit: cover;
        height: 40px;
      }

    }

  }

  > .content * {
    padding: 0;
  }

  .icons-wrapper {
    margin-bottom: 20px;

    .comments-count,
    .likes-count,
    .like.link a span,
    .comment-trigger {
      font-size: 1em;
    }

  }

}

.modal-default .ctools-modal-content #modal-content .node-community-group-status.node-teaser .heading .info h3 {
  padding-left: 50px;
}
