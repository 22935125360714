.pane-c02free-user-user-impact-co2 {
  $grey01: #90a0ad;
  $grey02: #8896a2;
  $grey03: #7e8994;
  $grey04: #6b727a;
  $grey05: #4f4f53;

  @include global-container;

  > .pane-content {

    .strikethrough-background {
      margin: 23px 0 15px;
    }

    .graph-baseline {
      padding: 20px 0;
      position: relative;

      @include breakpoint($laptop) {
        padding: 20px;
      };
    }

    .graph-header {
      margin: 0;

      h4 {
        font-size: $global-font-size;
        text-align: center;
        margin: 0 0 10px;
        font-weight: bold;

        @include breakpoint($tablet) {
          font-size: $lisa-medium-text;
        };

        @include breakpoint($laptop-l) {
          font-size: 24px;
        };
      }
    }

    .description {
      text-align: center;
      margin-top: 10px;
      font-style: italic;
    }

    .stat-icons {
      display: flex;
      justify-content: center;
      margin: 20px auto 0 auto;
      max-width: none;

      @include breakpoint($laptop) {
        max-width: 80%;
      };

      > div {
        min-width: 40px;
        text-align: center;
        cursor: pointer;

        &:first-child {
          border-radius: 25px 0 0 25px;
          padding-left: 5px;
        }

        &:last-child {
          border-radius: 0 25px 25px 0;
          padding-right: 5px;
        }

        &:before {
          content: '';
          display: inline-block;
          height: 12px;
          margin: 0 auto;
          padding: 12px 0;
          vertical-align: top;
          width: 15px;

          @include breakpoint($tablet) {
            height: 20px;
            padding: 15px 0;
            width: 20px;
          };
        }

      }

      .electric {
        background: $highlight-yellow-2;

        &:before {
          background: url("../images/icon-socket.svg") no-repeat center;
          background-size: contain;
        }

      }

      .vehicles {
        background: $highlight-yellow-1;

        &:before {
          background: url("../images/icon-car.svg") no-repeat center;
          background-size: contain;
        }

      }

      .food {
        background: $highlight-green;

        &:before {
          background: url("../images/icon-food.svg") no-repeat center;
          background-size: contain;
        }

      }

      .airline {
        background: $highlight-blue;

        &:before {
          background: url("../images/icon-plane.svg") no-repeat center;
          background-size: contain;
        }

      }

      .gas {
        background: $supporting-2;

        &:before {
          background: url("../images/icon-cloud.svg") no-repeat center;
          background-size: contain;
        }

      }
    }
  }

}
