.node-team {
  .main-content {
    .heading {
      margin-bottom: 70px;
      height: 250px;

      @include breakpoint($laptop) {
        margin-bottom: 40px;
        height: 220px;
      }

      .change-teamcover-bg-launcher {
        top: 30px;

        @include breakpoint($laptop) {
          top: 0;
        }
      }

      .team-image {
        height: 100%;
        overflow: hidden;
        position: relative;

        // Default blue background
        > img {
          height: 100%;
          width: 100%;
        }

        .field-name-field-team-cover-photo {
          height: 100%;

          div,
          img {
            height: 100%;
          }

          img {
            margin-left: 50%;
            object-fit: cover;
            transform: translateX(-50%);

            @include breakpoint($laptop) {
              margin-left: auto;
              height: 100%;
              object-fit: cover;
              object-position: center;
              transform: none;
              width: 100%;
            }
          }
        }
      }

      .absolute-content {
        bottom: 50px;
        position: absolute;
        text-align: center;
        width: 100%;

        @include breakpoint($tablet) {
          bottom: 10px;
          left: 50%;
          padding: 0 20px;
          transform: translateX(-50%);
        }
      }

      .field-name-field-team-picture {
        position: absolute;
        left: 10px;
        bottom: 0;

        img {
          max-width: 75px;
          max-height: 75px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          vertical-align: bottom;
        }
      }

      .about-this-team {
        color: white;
      }

      .team-name {
        @include page-heading-title;
        & {
          font-size: 1.5 * $global-font-size;
        }

        @include breakpoint($tablet) {
          margin: 0;
        }

      }

      .about-this-team {
        p {
          display: inline-block;
          background: $black-50;
          padding: 10px 20px;
        }
      }

      h1,
      h2,
      p {
        margin: 0;
      }

      .team-rank {
        text-align: center;
        background: $supporting-2-transparent-1;
        padding: 10px 0;

        h2 {
          padding: 0;
          color: $text-color;
          font-weight: bold;
          font-size: 20px;
        }
      }
    }

    .onboard-actions {
      padding: 35px 30px 35px;
    }

  }

  .level-ribbon {
    position: relative;

    .edit-settings {
      position: absolute;
      right: 10px;
      top: 5px;

      a {
        box-shadow: none;
        font-size: 0;
        padding: 4px;

        &::before {
          font-size: 1.2rem;
        }
      }
    }
  }

  .team-info {
    text-align: center;
    padding: 5px;

    @include breakpoint($tablet) {
      text-align: left;
    }
  }

  .progress-block {
    @include global-container;
    & {
      margin-bottom: $global-padding-desktop;
    }

    @include breakpoint($laptop) {
      margin-bottom: 80px;
    }
  }

  .members-block {
    .modal-trigger {
      @include see-all-links;

      & {
        width: 100%;
      }
    }

    @include global-container;
    & {
      margin-bottom: $global-padding-desktop;
      position: relative;
    }

    @include breakpoint($laptop) {
      margin-bottom: 80px;
    }

    .team-leader {
      margin-bottom: 20px;

      @include breakpoint($laptop) {
        max-width: 60%;
      }

      .wrapper {
        @include box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px;

        .left {
          display: flex;
          align-items: center;

          .user-picture {
            width: 90%;
          }

          .image {
            width: 80px;

            a {
              @include link;
              display: inline-block;
              border-radius: 50%;

              &:hover {
                opacity: .7;
              }

            }

            img {
              width: 100%;
              height: auto;
              vertical-align: middle;
            }

          }

          .position {
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
          }

          .name {
            font-size: 18px;
            font-weight: bold;
          }

        }

        .right {
          color: $light-blue;
          font-size: 14px;

          .phone {
            background: url("../images/icon-phone.svg") no-repeat left center;
            background-size: 14px;
            padding-left: 25px;
            margin-bottom: 5px;
          }

          .mail {
            background: url("../images/icon-mail.svg") no-repeat left center;
            background-size: 16px;
            padding-left: 25px;
          }

        }

      }

      img {
        border-radius: 50%;
      }

    }

    .team-members {
      @include box;
      padding: 30px 30px 0;
      position: relative;

      > ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        li {
          width: 33.33333%;
          list-style: none;
          padding: 10px;
        }

      }

      .member {
        display: flex;
        align-items: center;

        .image {
          width: 50px;
          margin-right: 7px;

          a {
            @include link;
            display: inline-block;
            border-radius: 50%;

            &:hover {
              opacity: .7;
            }

          }

          img {
            width: 100%;
            height: auto;
            vertical-align: middle;
          }

        }

        .name {
          max-width: calc(100% - 56px);
          font-weight: bold;
        }

      }

      .modal-trigger {
        width: calc(100% + 60px);
        border-top: 1px solid $light-grey;
        text-align: right;
        padding: 10px 30px;
        margin-left: -30px;

        a {
          @include link;
          color: $light-blue;
          font-size: 14px;
          font-weight: bold;

          &:hover {
            color: $highlight-blue;
          }

        }
      }

    }

    .actions-group {
      @media (min-width: $laptop) {
        position: absolute;
        right: 30px;
        top: 0;
      }

      a {
        color: $light-blue;
        font-size: 0;
        padding: 8px;
        position: relative;
        text-decoration: none;

        &:before {
          font-size: 22px;
        }
      }
    }
  }

  .standings-block {
    @include global-container;
    & {
      margin-bottom: $global-padding-desktop;
    }

    @include breakpoint($laptop) {
      margin-bottom: 80px;
    }

    .wrapper {
      flex-direction: column;
      margin-top: 15px;

      @include breakpoint($tablet) {
        align-items: stretch;
        flex-direction: unset;
        display: flex;
      }

      .left {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 20px;
        padding: 20px 20px 0;

        @include breakpoint($tablet) {
          border-right: 2px solid $background-gray-3;
          flex-basis: 47%;
          margin: 0;
          padding: 20px;
        }

        > div {
          width: 100%;
        }
      }

      .right {
        @include breakpoint($tablet) {
          flex-basis: 53%;
        }

        .view {
          font-size: $global-font-size;

          .view-content ul {
            margin: 0;

            li {
              border-bottom: 2px solid $background-gray-3;
              padding-left: 25px;
              margin: 0;

              &:first-child {
                padding-top: 0;
              }

              &:last-child {
                border-bottom: medium none;
              }
            }

            .views-field-title {
              font-weight: bold;
            }
          }

          #team-standing-total {
            @include see-all-links;

            // Overriding view style
            & {
              padding-right: 0;
            }
          }
        }
      }
    }

    .position {
      margin-bottom: 5px;

      @include breakpoint($tablet) {
        margin-bottom: 30px;
      }
    }

    .reduced-number {
      display: flex;
      align-items: center;
    }

    .number {
      color: $highlight-green;
      font-size: $big-text;
      font-weight: 700;
      padding: 0 10px 0 0;
      text-align: right;
      width: 87px;
    }

    .text {
      font-size: $text-font-size;
      text-transform: uppercase;
      width: calc(100% - 87px);
    }

  }

  .actions-block {
    @include global-container;

    .view-teams {
      width: 100%;

      #team-standing-total {
        @include see-all-links;
        & {
          background: $background-gray-3;
          padding-left: $global-padding-desktop;
          padding-right: $global-padding-desktop;
        }
      }
    }

    .item-list > ul {
      margin: 0;

      li {
        list-style: none;
        padding: 15px 10px;
        margin: 0;

        &:nth-child(odd) {
          background: $background-gray-3;
        }
      }
    }

    > h2 {
      margin-bottom: 15px;
    }
  }

  .actions {
    width: 100%;

    .title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
      padding: 0 10px;

      a {
        color: $light-blue;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .info {
      color: $supporting-1;
      font-size: $text-font-size;

      @include breakpoint($tablet) {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
      }

      > div {
        padding: 0 10px;
        margin-bottom: 10px;

        @include breakpoint($laptop) {
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        color: $highlight-blue;
      }

      .title-wrapper {
        @include breakpoint($tablet) {
          flex-basis: 100%;
        }

        @include breakpoint($laptop) {
          flex-basis: 30%;
        }

        .stats {
          display: flex;
          font-size: 14px;
        }

        .points,
        .difficulty {
          flex-basis: 50%;
          position: relative;

          i {
            font-size: 14px;
            font-style: normal;
            margin-right: 3px;
          }

          div {
            display: block;

            @include breakpoint($laptop-l) {
              display: inline-block;
            }
          }
        }

        .points {
          padding-left: 14px;

          @include breakpoint($laptop-l) {
            min-width: 100px;
          }

          div {
            display: inline;
          }
        }

        .difficulty {
          display: flex;
          align-items: center;

          &:before {
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 3px;
            left: 0;
          }
        }
      }

      .team {
        font-weight: bold;

        @include breakpoint($tablet) {
          flex-basis: 50%;
        }

        @include breakpoint($laptop) {
          flex-basis: 30%;
        }
      }

      .completion-status {
        display: flex;
        flex-wrap: nowrap;

        @include breakpoint($tablet) {
          flex-basis: 50%;
        }

        @include breakpoint($laptop) {
          flex-basis: 40%;
        }
      }

      .progress {
        font-weight: normal;
        flex-basis: 50%;
      }

      .goal {
        flex-basis: 50%;

        span {
          padding-right: 3px;
        }

        div {
          display: inline;
        }
      }
    }
  }

  .leave-team-action {
    @include global-container;

    .wrapper {
      border-top: 1px solid $background-gray-3;
      margin-top: 30px;
      padding-top: 20px;

      a {
        font-size: $text-font-size;
        font-weight: 700;
      }
    }
  }

  .block-title {
    margin: 20px 0;
  }

  .standings-block,
  .ranks-block {
    .clearfix {
      padding: 0;
    }
  }
}

.team-container, .community-container {
  width: 100%;
}

$logo-size: 70px;
$title-size: 25%;
$number-size: 20%;
$points-size: 10%;
$link-size: 30%;

.page-teams,
.page-profile-wizard {

  .view-teams {

    .item-list > ul {

      .labels-wrapper {
        display: flex;
        width: 100%;
        padding: 0 10px;
        margin: 0;
        background-color: $supporting-2;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        > li {
          padding: 10px 5px;
          font-weight: bold;

          @include breakpoint($tablet) {
            padding: 10px;
          }

        }

        li {
          border: none;
          margin: 0;
        }

      }

      li.views-row {
        margin: 0;
        padding: 0 10px;
        border: none;
      }

    }

  }

  .views-row-odd {
    background-color: $supporting-2-transparent-3;
  }

  .views-row-even {
    background-color: $supporting-2-transparent-1;
  }

  .team-list-container {

    .icon {
      &, &-label {
        width: 40px;

        @include breakpoint($tablet) {
          width: $logo-size;
        }

        img {
          width: 30px;
          object-fit: contain;

          @include breakpoint($tablet) {
            width: 70px;
          }

        }
      }

    }

    .title-wrapper {
      &, &-label {
        width: 35%;

        @include breakpoint($tablet) {
          width: $title-size;
        }

      }
    }

    .title-wrapper, .progress div {
      &, & strong {
        font-weight: normal;
      }
    }

    .team {
      &, &-label {
        width: 26%;

        @include breakpoint($tablet) {
          width: $number-size;
        }

      }
    }

    .progress {
      &, &-label {
        width: 14%;

        @include breakpoint($tablet) {
          width: $points-size;
        }

      }
    }

    .goal {
      &, &-label {
        width: 16%;

        @include breakpoint($tablet) {
          width: $link-size;
        }

      }
    }

  }

  .actions {
    display: flex;
    width: 100%;

    > div {
      display: flex;
      align-items: center;
      padding: 10px 5px;

      @include breakpoint($tablet) {
        padding: 10px;
      }

    }
  }

  .views-row {
    padding: 10px;
  }

  .list-heading {
    padding: 0;
  }
}

.remodal {
  .view-id-teams.view-display-id-panel_pane_10 {
    li:nth-child(odd) {
      background-color: $supporting-2-transparent-3;
    }

    li:nth-child(even) {
      background-color: $supporting-2-transparent-1;
    }

    .item-list {
      > ul {
        li {
          margin: 0;
          padding: 10px 20px;

          .field-content {
            font-weight: normal;
          }
        }
      }
    }

    .views-field.views-field-id {
      .field-content {
        font-weight: bold;
      }
    }
  }
}

.c02free-pseudo-view,
.view-id-teams.view-display-id-panel_pane_10 {
  .views-field.views-field-id {
    .field-content {
      font-size: 35.2px;
      color: $highlight-green;
      font-weight: bold;
    }
  }

  .my-team-rank {
    .views-field-title, .views-field-name, .views-field-field-earned-points {
      > span, > div {
        color: lighten($supporting-1, 20%);
      }
    }
  }
}

.position-number {
  display: flex;
  align-items: center;
}

.node-type-team {
  &.page-node-edit {
    .field-name-field-team-about {
      .filter-wrapper {
        display: none;
      }
    }
  }
}
