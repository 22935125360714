.modal-default .ctools-modal-content #modal-content .node-community_group_status-form {
  text-align: left;

  .congratulations-action {
    text-align: center;
    padding-top: 10px;
    margin-top: 0;
    margin-bottom: 42px;

    h2 {
      font-size: 36px;
      text-align: center;
      padding: 8px 0 13px;
      margin: 0;
    }

    p {
      margin: 0;

      .points {
        color: $green;
        font-size: 36px;
        font-weight: bold;
        padding: 0 8px;
      }

    }

  }

  .post-message {
    text-align: center;
    margin-bottom: 30px;

    h3 {
      font-size: 30px;
      text-align: center;
      margin: 0 0 5px;
    }

    p {
      margin: 0 0 25px;
    }

  }

  #edit-share {
    min-width: 0;
    border: 0;
    padding: 0.01em 0 0 0;
    margin: 0;

    legend {
      width: 100%;
      display: table;
      font-size: 14px;
      font-weight: 700;
      float: left;
      margin: 0;
      padding: 0;

      + * {
        clear: both;
      }

    }

    .fieldset-wrapper {
      display: flex;

      > div {
        padding-right: 20px;
      }

    }

  }

  .title {
    margin-bottom: 30px;

    @media (min-width: $tablet) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .form-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      @media (min-width: $tablet) {
        width: calc(100% - 100px);
        margin-bottom: 0;
      }

      label {
        width: auto;
        padding-right: 15px;
      }

    }

  }

  .trigger-image {
    display: inline-block;
    @include link;
    color: white;
    font-size: 16px;
    background: $highlight-blue;
    padding: 5px 15px;

    &.active,
    &:hover {
      background: $light-blue;
    }

    &.active {
      &:before {
        content: '-';
      }
    }

    &:before {
      content: '+';
      display: inline-block;
      font-size: 16px;
    }

  }

  .image-wrapper {
    display: none;
    text-align: center;

    &.open {
      display: block;
    }

    > div {
      display: inline-block;
    }

    label {
      display: none !important;
    }

    .image-widget {

      input[type="file"] {
        padding-right: 10px;
      }

      input[type="submit"] {
        margin: 0;
      }

    }

    .description {
      font-size: 12px;
    }

  }

  .form-item {
    margin-bottom: 0;
  }

  .description {
    margin-bottom: 30px;
  }

  .field-name-field-status-community-body {

    .filter-wrapper {
      padding: 0;
    }

  }

  > div > .form-actions {
    padding-top: 20px;
    margin-top: 20px;

    &.with-checkbox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: initial;
      border-top: 1px solid $light-blue;
      margin-bottom: 0;

      * {
        margin: 0;
      }
    }

    & > .form-actions {
      display: flex;
    }

    .form-submit + .form-submit {
      margin-left: 10px;
    }

  }

  .field-name-field-status-community-type,
  .share-with {
    text-align: left;
  }


  .field-name-field-status-community-type {
    margin-bottom: 30px;

    .form-type-radios {

      > label {
        margin-bottom: 10px;
      }

      .form-type-radio {
        @include radioButton;
        & {
          display: inline-block;
          margin-right: 15px;
          margin-bottom: 15px;
        }

        input {
          display: inline;
        }

        label {
          display: inline;
        }

      }
    }

  }


  .field-name-field-group-type {
    margin-top: 20px;
  }

  .share-with {
    width: 100%;
    padding-right: 20px;

    .label {
      font-weight: 700;
      margin-bottom: 5px !important;
    }

    .form-wrapper {
      display: flex;
      align-items: center;

      > div {
        padding-right: 20px;

        input[type=checkbox]:not(old) + label:before {
          margin-top: 1px !important;
        }

      }
    }
  }

}
