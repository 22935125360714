.view-city-content {

  &.view-display-id-panel_pane_1 {

    .views-row {
      min-height: 25em;
      text-align: center;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding: 3em;
      margin-bottom: 2em;

      @media (min-width: $tablet) {
        min-height: 35em;
      }

      > div:not(.views-field) {
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 auto;

        @media (min-width: $laptop) {
          width: $laptop;
        }

        h1 {
          color: #FFF;
          font-size: 28px;
          font-weight: 400;
          margin-top: 22px;
          margin-bottom: 11px;

          @media (min-width: $tablet) {
            font-size: 42px;
          }

          @media (min-width: $laptop) {
            max-width: 900px;
          }

        }

        &.gray-shade {
          display: inline-block;
          width: auto;
          padding-top: 20px;

          h1 {
            @include gray-background-box;
            display: inline-block;
          }
        }

      }

      .views-field-field-city-why-join-hero-image {
        display: none;
      }

    }

  }

  &.view-display-id-panel_pane_2 {
    @include global-container;

    @media (min-width: $laptop) {
      width: $why-join-width;
    }

    p {
      font-size: 18px;
      margin: 0 0 11px;
    }

  }

}
