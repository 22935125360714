body.page-node-edit main {
  @include global-container;
  & {
    font-size: 16px;
  }

  > div {

    > div {
      margin-top: 2em;
      margin-bottom: 0;

      &.field-name-field-team-about .description {
        display: none;
      }

      .form-item {
        margin: 0;
      }

    }

  }

  legend span {
    font-weight: bold;
  }

  .text-summary-wrapper {
    margin-bottom: 2em;
  }

  input[type="text"] {
    max-width: 100%;
    padding: 10px;
  }

  .form-actions {
    margin-top: 3em;

    input {
      @include cta($highlight-blue);
      & {
        margin: 0 20px 20px 0;
      }

      &:last-child {
        margin-right: 0;
      }

    }
  }

}
