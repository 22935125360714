.remodal[data-remodal-id="modal-team-actions"] {

  @media (min-width: 641px) {
    max-width: 90%;
  }

  @media (min-width: $laptop) {
    max-width: 1000px;
  }

}

.modal-team-actions {

  .icon {
    display: none;
  }

  .item-list > ul {
    margin: 0;

    li {
      list-style: none;
      padding: 10px 20px;
      margin: 0 !important;

      &:nth-child(odd) {
        background: $light-grey;
      }

    }

  }

  .actions {
    width: 100%;

    .title {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      padding: 0 10px;
      margin-bottom: 8px;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .info {

      @media (min-width: $tablet) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      > div {
        padding: 0 10px;
        margin-bottom: 10px;

        @media (min-width: $tablet) {
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

      }

      a {
        color: $highlight-blue;
      }

      .title-wrapper {

        @media (min-width: $laptop) {
          flex-basis: 40%;
        }

        .stats {
          display: flex;
          font-size: 14px;
        }

        .points,
        .difficulty {
          flex-basis: 50%;
          position: relative;

          i {
            font-size: 14px;
            font-style: normal;
            margin-right: 3px;
          }

          div {
            display: block;

            @media (min-width: $laptop-l) {
              display: inline-block;
            }

          }

        }

        .points {
          padding-left: 14px;
          text-align: left;

          @media (min-width: $laptop-l) {
            min-width: 100px;
          }

          div {
            display: inline;
          }

        }

        .difficulty {
          display: flex;
          align-items: center;

          &:before {
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 3px;
            left: 0;
          }

          i {
            font-size: 14px;
            font-style: normal;
            margin-right: 3px;
          }

        }

      }

      .team {
        font-size: 18px;
        text-align: left;
        font-weight: bold;

        @media (min-width: $laptop) {
          width: 30%;
        }

      }

      .completion-status {
        display: flex;
        flex-wrap: nowrap;
        text-align: left;

        @media (min-width: $laptop) {
          flex-basis: 30%;
        }
      }

      .progress {
        font-weight: normal;
        flex-basis: 50%;

        @media (min-width: $laptop) {
          padding-top: 8px;
        }
      }

      .goal {
        flex-basis: 50%;

        span {
          padding-right: 3px;
        }
      }
    }
  }

}
