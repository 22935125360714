#c02free-community-group-add-group-form {
  #edit-filter {
    .form-item.form-type-select,
    .drop-down {
      display: inline-block;
      float: left;
    }
  }

  .help-text {
    clear: both;
  }
}

.modal-default .ctools-modal-content #modal-content > form[id^="c02free-community-group-add-group-form"] {
  padding-left: 20px;
  padding-right: 20px;

  .help-text {
    margin-bottom: 20px;
  }

}
