.stat2 {
  &__container {
    @include stat-box;
    & {
      margin-bottom: $global-padding-desktop;
    }

    .stats {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-around;

      @include breakpoint($tablet) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }

  &__item {
    @include stat-item;

    // My region only block
    &--text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px;

      p {
        font-size: $small-text;
        margin: 0;
      }

      h5 {
        color: $text-color;
        font-size: $global-font-size;
        margin-top: 0;
        text-transform: none;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    text-align: right;

    img {
      height: auto;
      max-width: 80px;
      width: 100%;
    }
  }

  &__content {
    color: $supporting-1;
    font-size: 12px;
    justify-content: center;
    padding: 0 0 0 20px;
    text-transform: uppercase;
    width: 55%;

    @include breakpoint($tablet) {
      padding: 20px 0 0 10px;
      text-align: left;
    }

    @include breakpoint($laptop-l) {
      padding-left: 20px;
    }

    h4 {
      color: $green;
      font-size: $big-text;
      line-height: 1;
      margin: 0 0 5px;

      @include breakpoint($laptop) {
        font-size: $lisa-big-text;
      }

      @include breakpoint($laptop-l) {
        font-size: $big-text;
      }
    }

    &--text {
      min-height: 40px;
      font-size: 16px;
      z-index: 1;
    }

    // My region stat box
    &.blue {
      font-size: $small-text;
      line-height: 17px;

      h4 {
        color: $highlight-blue;
        font-size: $big-text;

        @include breakpoint($laptop) {
          font-size: $my-region-stat;
        }
      }
    }
  }

  &__footer {
    color: $light-blue;
    padding: 5px 5px;
    position: absolute;
    right: 0;

    @include breakpoint($laptop-l) {
      padding: 5px $global-padding-mobile;
    }

    &.align-left {
      width: 100%;
      padding: 15px 0;
      position: relative;

      @include breakpoint($tablet) {
        position: absolute;
        padding: 15px 15px;
      }

      + .stats {

        @include breakpoint($tablet) {
          padding-top: 10px;
        }

      }

      p {
        font-size: 16px;
        font-weight: bold;
      }

    }

    p {
      font-size: $small-text;
      margin-top: 0;
    }
  }
}

.dashboard-stat {
  h4 {
    color: $background-green;
    margin-right: 10px;

    span {
      font-size: 30px;
    }
  }

  .star-circle, .fa-arrow-down, .fa-star {
    text-align: center;
    margin-right: 0;
    position: absolute;
    font-size: 4.5em;
    bottom: initial;
    top: 50%;
    transform: translateY(-50%);
    color: $supporting-2-transparent-0;
    z-index: 0;
    font-style: normal;
    display: none;
  }

  .star-circle {
    font-size: 6.5em;
    margin-top: 5px;
  }

  &-info {
    font-size: 16px;
    margin-left: 5px;
    color: $supporting-2;
    cursor: pointer;
  }
}
