$tooltip-color: #676a73;

.tooltip-icon {
  display: inline-block;
  width: 21px;
  height: 21px;
  text-decoration: none !important;
  background: $background-blue;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
  transition: all .4s;
  margin-left: 3px;
  vertical-align: middle;
  margin-top: -2px;
  position: relative;

  &:hover {
    background: $tooltip-color;
  }

  span {
    content: '?';
    display: inline-block;
    color: white !important;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }

}

.tooltip {
  font-size: 14px;
  line-height: 1.42857143;
  line-break: auto;
  text-align: center;
  text-decoration: none;
  white-space: normal;
  z-index: 999;

  &.bs-tooltip-top {
    margin-top: -3px;
    padding: 0 0 8px;

    .arrow {
      bottom: 3px;
      left: 50%;
      margin-left: -3px;
      border-width: 5px 5px 0;
      border-top-color: $tooltip-color;
    }

  }

  &.bs-tooltip-bottom {
    margin-top: 3px;
    padding: 8px 0 0;

    .arrow {
      top: 3px;
      left: 50%;
      margin-left: -3px;
      border-width: 0 5px 5px;
      border-bottom-color: $tooltip-color;
      border-top-color: $tooltip-color;
    }

  }

  &.bs-tooltip-left {
    margin-left: -3px;
    padding: 0 8px 0 0;

    .arrow {
      top: 50%;
      right: 3px;
      margin-top: -3px;
      border-width: 5px 0 5px 5px;
      border-left-color: $tooltip-color;
    }

  }

  &.bs-tooltip-right {
    margin-left: 3px;
    padding: 0 0 0 8px;

    .arrow {
      top: 50%;
      left: 3px;
      margin-top: -3px;
      border-width: 5px 5px 5px 0;
      border-right-color: $tooltip-color;
    }

  }

  .arrow {

    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  .tooltip-inner {
    max-width: 200px;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    background: $tooltip-color;
    border: solid 1px $tooltip-color;
    padding: 3px 8px;
  }

}
