.page-group-node-admin-people-edit-membership {
  form {
    @include global-container;
    & {
      font-size: $text-font-size;
      margin-top: 30px;
    }
    .form-checkboxes {
      text-transform: capitalize;
    }

    .fieldset-legend {
      display: none;
    }

    textarea {
      font-family: $font-family-sans-serif;
    }
  }
}
