.gcf-lang-switcher {
  position: relative;
  transition: all .4s;
  padding: 0 0 9px;
  top: 9px;

  .current-lang {
    border: 1px solid transparent;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    font-size: $small-text;
    padding: 0;

    .icon {
      display: block;
      text-align: center;

      img {
        height: 14px;
        width: 14px;
      }
    }

    &:hover {
      border: 1px solid transparent;
    }
  }

  &.expanded,
  &:hover {
    .lang-dropdown {
      display: block;
    }
  }

  .lang-dropdown {
    display: none;
    min-width: 77px;
    background-color: #FFF;
    border: 1px solid $supporting-1;
    border-radius: 3px;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: 46px;
    width: 100%;
    z-index: 5;
    font-size: $small-text;

    &:before {
      content: '';
      border-style: solid;
      border-width: 9px;
      border-color: transparent;
      border-top-width: 0;
      border-bottom: 9px solid $supporting-1;
      width: 0;
      height: 0;
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: -9px;
      left: 50%;
      transform: translate3d(-50%,0,0);
    }

    ul {
      display: block;
      margin-bottom: 0;

      li {
        display: block;
        margin: 0;
        padding: 8px 4px;
        text-align: center;


        &:hover {
          background-color: $light-blue;

          span {
            cursor: default;
          }

          a {
            color: $white;
          }
        }
      }

      a {
        color: $light-blue;
        display: block;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}
