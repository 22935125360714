.modal-team-rank {

  li > div {
    text-align: left;

    &:last-child {
      text-align: right;
      padding-right: 10px;
    }

  }

}
