.pane-user-dashboard-header,
.pane-user-pub-dashboard-header {
  background-color: lighten($background-gray-1-transparent-1, 60%);
  padding: 40px 0 30px;
  position: relative;

  @media (min-width: $laptop) {
    padding: 20px 0;
  }

  .background-img {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;

    @media (min-width: $laptop) {
      background-size: 100%;
    }

    a.change-background.top-right {
      position: absolute;
      top: unset;
      right: 0;
      bottom: 0;

      @media (min-width: $laptop) {
        top: 0;
        bottom: unset;
      }
    }
  }

  .user-dashboard-header--user {
    text-align: center;

    .user-dashboard-header--user-photo {
      display: inline-block;
      position: relative;

      .image-add {
        display: flex;
        align-items: center;
        width: 100px;
        height: 100px;
        background: white;
        border-radius: 100%;
        overflow: hidden;
        position: relative;

        &:hover {

          > div {
            opacity: 1;
          }
        }

        > div {
          @include link;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: inset 0 0 10px 0 rgba(255, 255, 255, 0.5);
          background: rgba(0, 0, 0, .5);
          border-radius: 100%;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;

          span {
            display: inline-block;
            color: white;
            font-size: 14px;
            text-decoration: none;
          }
        }

        img {
          max-width: 100%;
          vertical-align: middle;
          object-fit: contain;
        }
      }

      .dashboard-modal-badge {
        display: inline-block;
        width: 40px;
        height: 40px;
        transition: all .4s;
        position: absolute;
        right: -10px;
        bottom: -10px;

        &:hover {
          opacity: .8;
        }

        img {
          max-width: 100%;
        }
      }
    }

    .user-dashboard-header--user-name {
      h2 {
        @include h3;

        & {
          color: white;
          font-weight: 100;
          background: $generic-semi-transparent;
          border-radius: 5px;
          padding: 10px 20px;
          margin-bottom: 0;
          margin-top: .5em;
        }
      }

      .message-alert {
        background: $generic-semi-transparent;
        border-radius: 5px;
        padding: 10px;
        font-size: 14px;
        margin-top: 3px;

        a {
          color: #FFF;
        }
      }
    }
  }
}
