#invite-form {

  #edit-token-help {
    display: none;
  }

}

.modal-default .ctools-modal-content #modal-content > form#invite-form {
  padding-left: 20px;
  padding-right: 20px;
}
