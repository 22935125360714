.page-teams {

  main {
    @include new-container;
  }

  .main-content > .container-fluid .view-teams h2:not(#dashboard-name) {
    border-bottom: none;
    padding-bottom: 0;

    &:after {
      content: none;
    }

  }

}
