.shortcontent .ellip {
  display: none !important;
}

.morelink {
  @include link;
  color: $light-blue;
  background: white;
  padding-left: 10px;
  position: absolute;
  bottom: 0;
  right: 0;

  &:hover {
    color: $highlight-blue;
  }

  &:before {
    content: '...';
    display: inline-block;
    letter-spacing: 1px;
    padding-left: 3px;
  }

}

a.morelink {
  text-decoration: none;
  outline: none;
}

.morecontent span {
  display: none;
}
