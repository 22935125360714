.energy-profile-utility-api.message {

  &.element-hidden {
    display: none!important;
  }

  .ajax-progress {
    position: relative;
    left: -10px;
    width: 15px;
    height: 15px;
    overflow: visible;

    &:before {
      width: 15px;
      height: 15px;
      border-width: 2px;
    }
  }

  .close {
    display: none;
    background: #636363;
    cursor: pointer;
    border-radius: 100%;
    color: #FFF;
    margin-left: 5px;
    text-align: center;
    width: 20px;
    height: 20px;
    vertical-align: middle;

    &:hover {
      background: #CCC;
    }
  }

  &.has-ended {
    position: fixed;
    z-index: 10;
    background: rgba(255, 165, 0, 0.95);
    border-radius: $welcome-border-radius;
    border: 2px solid $supporting-2;
    padding: 10px;
    display: block;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);

    .message-placeholder {
      float: left;
      width: calc(100% - 30px);
      font-size: 15px;
    }

    .ajax-progress {
      display: none;
    }

    .close {
      display: table;
      float: left;
    }
  }

  .message-placeholder {

  }
}