body.node-type-action {

  .modal-default .ctools-modal-content {
    text-align: center;

    #modal-content form > div > input[type=submit] {
      float: none;
    }

  }

  h2 {
    @include section-title;

    & {
      padding-top: 20px;
      border: none;

      // Hides bottom line.
      span:after {
        content: none;
      }
    }
  }

  #action-hero {
    > .hero__banner {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 250px;
      margin-bottom: 0;
      position: relative;

      @include breakpoint($laptop) {
        background-image: initial;
        background-position: center;
        background-repeat: no-repeat;
        height: 388px;
        padding: 0 3em 2em 1.5em;
      }
    }

    > .hero-title.only-mobile {
      color: #000;
      padding: 20px 30px 0;
      margin: 0 auto;

      .node-title {
        font-size: 24px;
        font-weight: 400;
        margin: 0;
      }

    }

  }

  #action-main-description {
    max-width: 100%;
    font-size: 20px;
    line-height: 30px;
    padding: 0;
    position: relative;

    .wrapper-content {
      @include global-container;

      & {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
      }

      @include breakpoint($tablet) {
        padding-bottom: 20px;
      }

      @include breakpoint($laptop) {
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 0;
      }

    }

    @include breakpoint($laptop) {
      font-size: 24px;
      line-height: 36px;

      .field-name-field-action-description {
        color: #555E67;
        max-width: 80%;
        padding: 30px 15px;

        p {
          font-size: 22px;
          line-height: 27px;
        }
      }
    }

    .share__options {
      max-width: 100%;

      @include breakpoint($laptop) {
        max-width: unset;
        padding: 15px;
      }
    }
  }

  .hero__section {
    .hero__banner .hero-title {
      @include global-container;

      & {
        display: none;
        color: $white;
        transform: translateX(-50%);
        position: absolute;
        left: 50%;
        bottom: 15px;
      }

      @include breakpoint($laptop) {
        display: block;
      }
    }

    .node-title {
      margin: 0 0 6px 0;
      font-size: $medium-text;

      @include breakpoint($laptop) {
        font-size: $action-title-text;
        font-weight: 400;
        line-height: 55px;
        padding: 0 15px;
        margin-bottom: 10px;
      }
    }

    .action-difficulty {
      display: none;
      font-size: $text-font-size;
      font-style: italic;
      line-height: 19px;

      &:before {
        content: '\f005';
        display: inline-block;
        font-family: FontAwesome;
        font-style: normal;
        margin-right: 4px;
      }
    }
  }

  .action__wrapper {
    background-color: $background-gray-4;
    padding-bottom: 20px;

    .content-wrapper {
      @include global-container;
    }

  }

  .social-options {
    bottom: -40px;
    display: block;
    width: 100%;

    @include breakpoint($mobile-m) {
      display: flex;
    }

    @include breakpoint($laptop) {
      background-color: transparent;
      bottom: 25px;
      display: block;
      right: 25px;
      width: auto;
    }

    .share-title,
    .share-button {
      border-radius: 4px;
      color: $white;
      flex-basis: 50%;
      font-size: $small-text;
      font-family: $font-family-sans-serif;
      line-height: 17px;
      margin: 0;
      text-align: center;
      text-transform: uppercase;
      width: 197px;

      &:before {
        font-size: $small-text;
        font-family: "Font Awesome 5 Free";
        margin-right: 5px;
      }
    }

    .share-title {
      background-color: $green;
      padding: 12px 5px 11px;
      margin-bottom: 10px;

      @include breakpoint($mobile-m) {
        margin-right: 10px;
        margin-bottom: 0;
      }

      @include breakpoint($laptop) {
        background-color: $green;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    .share-button {
      border: 2px solid $green;
      color: $green;
      padding: 10px 5px 9px;
    }
  }

  .modal-default {

    + .select2-container.select2-container--open .select2-dropdown {
      transform: translateX(-30%);
    }

    .ctools-modal-content {

      #modal-content {

        form {

          &#c02free-user-actions-add-form > div > input[type=submit] {
            float: none;
            margin-left: 0;
          }

          &.node-form {
            padding-left: $modalFlank;
            padding-right: $modalFlank;
          }

          > div > input[type="submit"] {
            margin-left: $modalFlank;
          }

          .views-exposed-form {
            text-align: right;

            .views-exposed-widgets {
              margin-bottom: 15px;
            }

            .views-exposed-widget {
              display: inline-block;
              float: none;
              padding: 0;
            }

            .form-item {
              display: flex;
              padding: 0;
              margin-bottom: 0;

              label {
                width: auto;
                vertical-align: middle;
                padding-top: 6px;
                padding-right: 8px;
                margin: 0;
              }

            }
          }
        }

      }

      #modal-content {
        position: initial;

        > .messages {
          margin-left: $modalFlank;
          margin-right: $modalFlank;

          @media (max-width: $laptop) {
            word-break: break-all;
          }

        }

        img {
          max-width: 100%;
        }

      }
    }

  }

  .view-posts {
    padding-top: 10px;
  }

}

#modal-content {
  .view-posts {
    background: rgba(232, 234, 236, .25);
    border: none;

    .views-exposed-form {
      width: 80%;
      margin: 0 auto;
    }

    .views-row {
      &::before {
        display: none !important;
      }
    }

    .node-community-group-status {
      border-radius: 5px;
      background-color: white;
      width: 80%;
      margin: 30px auto 0;

      @media (min-width: $tablet) {
        width: 70%;
      }
    }
  }
}

.btn-blue {
  @include cta($highlight-blue);
}
