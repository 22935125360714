.view-community-news {

  .views-row {
    border-bottom: 2px solid $background-gray-3;
    margin-bottom: 20px;
    padding: 20px 0;

    > div > h3 {
      margin: 0;

      a {
        @include link;
        color: black;

        &:hover {
          color: $highlight-blue;
        }
      }
    }

    .shortcontent {
    }

    .allcontent {
      padding-bottom: 30px;
    }
  }

  img {
    max-width: 100%;
    margin: 0 !important;

    @media (max-width: 1199px) {
      width: 100% !important;
      height: auto !important;
    }
  }
}
