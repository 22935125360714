.modal-members {
  position: relative;

  > ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    li {
      width: 100%;
      list-style: none;
      padding: 10px;

      @media(min-width: $tablet) {
        width: 50%;
      }

    }

  }

  .member {
    display: flex;
    align-items: center;

    .image {
      position: relative;
      width: 50px;
      margin-right: 7px;

      .leader-action {
        position: absolute;
        right: -5px;
        bottom: -5px;

        a {
          font-size: 18px;
          outline: none;
        }

      }

      a {
        @include link;
        display: inline-block;
        border-radius: 50%;

        &:hover {
          opacity: .7;
        }

      }

      img {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }

    }

    .name {
      display: flex;
      flex-wrap: wrap;
      font-weight: bold;
      max-width: calc(100% - 56px);

      span {
        flex-basis: 100%;
        text-align: left;
      }
    }

  }

  > a {
    @include link;
    color: $light-blue;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    right: 30px;
    bottom: 7px;

    &:hover {
      color: $highlight-blue;
    }

  }

}

.remodal {
  &[data-remodal-id="modal-members"] {
    @media (min-width: $tablet) {
      max-width: 90%;
    }

    @media (min-width: $laptop) {
      max-width: 1000px;
    }
  }
}
