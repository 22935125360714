// User login page styles.
.user-login {
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;

  h1 {
    color: $white;
    font-size: 3em;
    margin: 30px 0;

    @media (min-width: $tablet) {
      margin: 0;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: susy-span(10 of 12);
    flex-direction: column;

    @media (min-width: $tablet) {
      flex-direction: row;
    }
  }

  .form-actions {
    margin-top: 20px;
  }

  .content-region {
    max-width: 500px;
  }

  label {
    display: block;
    font-weight: bold;
    font-size: 1em;
  }

  form {
    background: white;
    padding: 30px 30px 10px;
    border-radius: 5px;

    .form-item, .form-actions {
      margin: 0;
    }

    .form-type-password {
      margin-top: 20px;
    }

    input {
      margin: 10px 0;
      padding: 15px;
      border-radius: 5px;
      width: 100%;
      appearance: none;
      font-size: 1em;
      border: 2px solid $background-gray-2;
    }

    input[type="submit"] {
      background: $background-green;
      color: $white;
      cursor: pointer;

      @media (min-width: $tablet) {
        border: none;
      }

      &:hover {
        background: $highlight-green;
      }

    }
  }

  .pass-recovery {
    padding: 0 30px;

    a {
      @include link;
      font-size: 14px;

      &:hover, &:focus {
        color: $text-color;
      }

    }

  }

  .forgot-password,
  .register-link {
    margin-top: 5px;

    @media (min-width: $tablet) {
      display: inline-block;
    }
  }

  .register-link {
    @media (min-width: $tablet) {
      float: right;
    }
  }

}

.page-user-login {
  margin: 0;
}
