.media-element.file-default {
  @media (max-width: $mobile-l) {
    margin: 1em 0 !important;
    width: 100% !important;
    float: none !important;
    height: auto !important;
  }
}

.media.media-element-container {
  .file-video {
    video {
      max-width: 100%;
    }
  }
}
