.action-stats-overlay-message {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(221, 221, 221, 0.92);
  border-radius: 3px;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;

  a {
    width: 90%;
    color: $highlight-blue;
    font-size: 22px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    transition: all .4s;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;

    @media (min-width: $tablet) {
      font-size: 30px;
    }

    &:hover {
      color: $green;
    }

  }

}
