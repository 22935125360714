.stat {
  &__container {
    padding: 30px 0;

    @media (min-width: $tablet) {
      @include global-card;

      display: flex;
      padding: 0;
      position: absolute;
      bottom: -30%;
      background: white;
      border-radius: 5px;
      left: 50%;
      transform: translateX(-50%);
      width: susy-span(11 of 12);
      max-width: $laptop-l;
    }

    @media (min-width: $laptop) {
      bottom: -20%;
    }
  }

  &__item {
    @include center-flex;

    flex-direction: column;
    position: relative;
    padding: 10px 0;

    @media (min-width: $tablet) {
      flex-basis: calc(100% / 3);
      margin: 0;
      flex-direction: row;
      padding: 30px 0;
    }

    &.middle {
      padding: 40px 0;
      margin: 6px 0;

      &::before {
        @include stat-separator('left');
      }

      &::after {
        @include stat-separator('right');
      }

      .stat__content {
        padding: 20px 20px 0;

        @media (min-width: $tablet) {
          padding: 0 20px;
        }

        @media (min-width: $tablet) {
          padding: 0 20px 0 0;
        }
      }

      @media (min-width: $tablet) {
        margin: 0 6px;
        padding: 0;
      }
    }

    &--text {
      text-align: center;
      text-overflow: ellipsis;
      max-height: 80px;
      word-break: break-word;
      overflow-wrap: break-word;
      hyphens: auto;

      h5 {
        text-transform: normal;
        text-align: center;
        margin-bottom: 0;
        margin-top: 20px;
        font-family: sans-serif;
        font-size: 15px;

        @media (min-width: $tablet) {
          text-align: left;
        }
      }

      &__container {

        @media (min-width: $tablet) {
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 30px;
        }

        .stat__item--text {
          @media (min-width: $tablet) {
            flex-basis: 0;
          }

          &:first-child {
            p {
              margin-top: 0;
            }
          }
        }

        h5 {
          font-size: 18px;
          margin-top: 0;
          color: rgb(83, 87, 97);
          text-transform: none;
        }

        p {
          text-transform: uppercase;
          font-size: 12px;
          margin-bottom: .35em;
          color: rgb(103, 106, 115);
        }
      }

      @media (min-width: $tablet) {
        text-align: left;
      }
    }

    > div {
      @media (min-width: $tablet) {
        flex-basis: 50%;
      }
    }
  }

  &__content {
    text-align: center;
    padding: 20px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: sans-serif;

    h4 {
      font-size: 42px;
      margin: 0 0 5px;
      color: $background-blue;
      line-height: 1;
      font-family: sans-serif;

      @media (min-width: $laptop) {
        font-size: 65px;
      }

    }

    @media (min-width: $tablet) {
      padding: 0;
      text-align: left;
    }

    @media (min-width: $laptop-l) {
      padding: 0 20px 0 0;
    }
  }

}

.dashboard-stat {
  position: static;
  transform: none;
  width: 100%;

  @media (min-width: $tablet) {
    padding: 0;
  }

  h4 {
    font-size: 42px;
    color: $background-green;
    margin-right: 10px;

    @media (min-width: $laptop) {
      font-size: 42px;
    }

    span {
      font-size: 30px;
    }
  }

  .stat__content {
    @include center-flex;

    padding: 0 20px;
    flex-basis: 100%;
    text-transform: none;
    white-space: nowrap;
    flex-direction: column;

    &--text {
      @include center-flex;

      z-index: 1;
      font-size: 16px;
    }
  }

  .star-circle, .fa-dollar-sign, .fa-arrow-down, .fa-star {
    text-align: center;
    margin-right: 0;
    position: absolute;
    font-size: 4.5em;
    bottom: initial;
    top: 50%;
    transform: translateY(-50%);
    color: $supporting-2-transparent-0;
    z-index: 0;
    font-style: normal;
    display: none;
  }

  .star-circle {
    font-size: 6.5em;
    margin-top: 5px;
  }

  .stat__item {
    &:nth-child(2) {
      &::before {
        @include stat-separator('left');
      }

      &::after {
        @include stat-separator('right');
      }
    }
  }

  &-info {
    font-size: 16px;
    margin-left: 5px;
    color: $supporting-2;
    border-radius: 50%;
    cursor: pointer;

    &:focus {
      outline: none;
    }

  }
}

.global-card {
  &__inner {

    @media (min-width: $tablet) {
      display: flex;
    }

  }
}
