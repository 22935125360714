.view-control-users-panes {

  ul {
    margin: 0;

    li {
      margin: 0 0 10px;
    }

  }

}
