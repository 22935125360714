.modal-default {
  max-width: 100vw;
  width: 100%;
  height: 100%;
  animation: opacity .3s linear;
  z-index: 1005 !important;
  overflow-x: hidden;
  position: fixed !important;
  top: 0 !important;
  left: 0;

  .ctools-modal-content {
    width: 100% !important;
    height: 100%;
    color: $text-color;
    background: $white;
    vertical-align: middle;
    border: none;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;

    @media (min-width: $tablet) {
      max-width: 700px;
      height: auto;
      border-radius: 5px;
      padding: 0 20px 20px;
    }

    .modal-header {
      color: black;
      background: transparent;
      padding: 50px 20px 0;
      position: relative;

      @media (min-width: $tablet) {
        padding: 45px 0 0;
      }

      &:before {
        content: "";
        width: 100%;
        height: 40px;
        background: black;
        box-shadow: 0 10px 15px 0 rgba(207, 210, 220, .55);
        position: fixed;
        top: 0;
        left: 0;

        @media (min-width: $tablet) {
          content: none;
        }

      }

      .close {
        display: inline-block;
        width: 28px;
        height: 28px;
        float: none;
        cursor: pointer;
        transition: all .4s;
        overflow: hidden;
        text-indent: -1000em;
        position: absolute;
        top: 5px;

        @media (min-width: $tablet) {
          text-indent: 0;
          top: 10px;
          right: -11px;
        }

        &:before {
          content: "\f410";
          display: inline-block;
          width: 32px;
          height: 32px;
          font-size: 2em;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          color: $background-blue;
          background: white;
        }

        &:after {
          content: "";
          width: 100%;
          height: 100%;
          background: url("../images/icon-back.svg") no-repeat center left;
          background-size: 20px;
          transform: translateY(-50%);
          text-indent: -1000em;
          overflow: hidden;
          position: absolute;
          top: 50%;
          left: 0;

          @media (min-width: $tablet) {
            content: none;
          }

        }

        img {
          display: none;
        }

      }

    }

    #modal-title {
      display: block;
      color: black;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.3;
      white-space: normal;
      margin: 0 0 10px;
      padding: 0 20px;

      @media (min-width: $tablet) {
        font-size: 26px;
        line-height: 1.42857143;
        margin: 0 0 10px;
      }

      .center {
        text-align: center;
      }

    }

    #modal-content {
      width: 100% !important;
      padding: 0;
      margin: 0;
      overflow-x: hidden;

      @media (min-width: $tablet) {
        max-width: 100%;
        max-height: 80vh;
      }

      h1, h2, h3, h4, h5, h6 {
        margin: 0 0 .5em;
      }

      > form {
        overflow: hidden;
        padding: 10px 0;
      }

      > .messages {
        margin: 20px;
      }

      form fieldset legend span {
        display: inline-block;
        font-size: 18px;
        margin-bottom: 8px;
      }

      .info-message {
        font-size: $global-font-size;
        margin: 20px 0;
        padding: 0 20px;
      }

      .invite-members-with-link {
        position: relative;

        > span {
          display: block;
          padding: 20px;
        }

        .form-item.form-disabled {
          padding: 0 20px;

          input[type=text] {
            padding-right: 29px;
          }

          + .fa-copy {
            background: white;
            padding: 10px 9px;
            position: absolute;
            right: 20px;
            bottom: -1px;

            &:hover {
              opacity: .8;
            }

          }
        }

      }

      .messages li {
        word-break: break-all;
      }

      .field-name-field-action-question-answers {

        .field-prefix,
        .field-suffix {
          font-size: 14px;
          display: inline-block;
        }

        .question {
          margin-bottom: 15px;

          > div {
            display: block;
          }

          * {
            font-size: 14px;
          }

          > p, div {
            display: inline-block;
            margin: 0;
          }

          select {
            margin: 0 5px;
          }

        }

        .select2-container--default {
          display: inline-block;
          background: $highlight-blue;
          padding-left: 10px;
          margin: 0 5px;

          &.select2-container--open .select2-selection--single .select2-selection__arrow b {
            border-color: transparent transparent #fff;
          }

          .select2-selection--single .select2-selection__arrow b {
            border-color: #fff transparent transparent;
          }

          .select2-selection__rendered {
            color: $white;
            font-size: 15px;
            padding-right: 30px;
          }

        }

        .answer {
          max-width: 65px;
          color: $white;
          font-size: 15px;
          font-family: 'Poppins', sans-serif;
          text-align: center;
          background: $highlight-blue;
          border: none;
          padding: 3px 0;
          margin: 0 2px;
        }

      }

      .field-type-datetime {
        text-align: center;

        label {
          width: auto;
        }

        .form-item {
          margin-bottom: 0;
        }

      }

      #wrapper-question {
        text-align: center;
        padding-top: 15px;

        .question-container {

          .question-text {
            display: inline;
            margin: 0;

            + .form-item {
              display: inline-block;
              margin-top: 5px;
              margin-left: 10px;
            }

          }

        }

        input.answer,
        .select2 {
          border-radius: 2px;
          border: 1px solid $supporting-5;
          outline: none;
          margin: 0 5px;

          &:focus {
            border-color: $text-color;
          }

        }

      }

      .question-container .form-item input[type=text] {
        width: auto;
      }

      .form-item {
        margin: 0 0 25px;

        &:not(.form-type-checkbox) {

          label {
            display: block;
          }

        }

        &.form-type-select {

          label {
            margin-bottom: 5px;
          }

        }

        &.form-type-checkbox {
          @include checkbox;

          input[type=checkbox]:not(old) {
            top: -1px;

            + label:before {
              margin-top: -3px !important;
            }

          }


        }

        label {
          line-height: 1;
          text-align: left;
          float: none;
          padding: 0;
          margin-bottom: 3px;
        }

        input {
          font-size: 8px;

          @media (min-width: $mobile-m) {
            font-size: 10px;
          }

          @media (min-width: $mobile-l) {
            font-size: 12px;
          }

          @media (min-width: $tablet) {
            font-size: 16px;
          }

          &[type="url"],
          &[type="text"] {
            @include text-input;
            & {
              width: 100%;
            }
          }

          &[type="file"] {
            border: 1px solid #e7e6e6;
          }

        }

        .form-textarea-wrapper {
          width: 100%;
          margin: 0;

          textarea {
            @include text-input;
          }

        }

        .description {
          text-align: left;
          margin-top: 10px;
          position: relative;
          left: 7px;
        }

      }

      .form-type-date-select {

        .form-item {
          margin-left: 10px;
          margin-right: 10px;
        }

      }

      .form-actions {
        margin-bottom: 0;
        text-align: center;

        &:not(.with-checkbox) {

          > * {
            margin-left: 20px;
            margin-top: 0;

            &:first-child {
              margin-left: 0;
            }

          }

        }

        a:not(.btn) {
          @include link;
          color: black;

          &:hover {
            color: $light-blue;
          }

        }
      }

    }

    fieldset {
      margin-top: .5em;
    }

    a.link-as-button,
    input[type="submit"] {
      @include blue-button;
      @include wider-button;

      &.js-hide {
        display: none;
      }

    }

    input[type="submit"].btn-cancel,
    a.btn-cancel,
    button.btn-cancel {
      @include cancel-blue-button;
    }

    a.link-as-button {
      display: inline-block;
    }
  }

}

// Fix modal dialog.
.ui-dialog {
  z-index: 1002;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
