.our-impact {

  .stats-wrapper {

    @media(min-width: $tablet) {
      width: calc(100% + 2%);
      display: flex;
      justify-content: space-between;
      margin-left: -1%;
    }

    @media (min-width: $laptop) {
      margin-bottom: 30px;
    }

  }

  .stat {

    @media(min-width: $tablet) {
      width: 34%;
      padding: 0 1%;
    }

    .wrapper {
      @include box;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0 25px;
      margin-bottom: 20px;

      @media(min-width: $tablet) {
        margin-bottom: 0;
      }

    }

    .stat-number {
      color: $green;
      font-size: 40px;
      font-weight: bold;
      margin-right: 10px;
    }

    .stat-text {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.2;
    }

  }

}
