body.node-type-event {

  .block-system > .content {
    @include new-container;
  }

  .page-title-wrapper .container {
    padding: 0;
  }

}
