.view-resources {
  .views-row {
    background: $white;
    border: 1px solid $background-gray-7;
    border-radius: 3px;
    box-shadow: 0px 0px 15px $black-3;
    margin-bottom: 30px;
    padding: 60px 30px 30px 30px;
    position: relative;
  }

  .views-field-field-resource-type {
    left: 0;
    position: absolute;
    top: 0;

    .resource {
      color: $white;
      font-size: $small-text;
      padding: 10px 30px;
      text-transform: uppercase;

      &[data-type="Other"] {
        background-color: $light-blue;
      }

      &[data-type="Financing"] {
        background-color: $background-orange;
      }

      &[data-type="Rebate"] {
        background-color: $green;
      }

      &[data-type="Information"] {
        background-color: $highlight-yellow-2;
      }
    }
  }

  .views-field-title-field {
    a {
      font-size: 20px;
    }
  }

  .views-field-body {
    p {
      font-size: $global-font-size;
      line-height: 27px;
    }
  }


}
