.modal-default .ctools-modal-content #modal-content > form[id^="c02free-landing-page-get-started-form"] {
  padding: 20px;

  @media (min-width: $tablet) {
    padding: 0;
  }

  .form-item-wrapper {

    @media (min-width: $tablet) {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .label-item {
      color: #535761;
      font-size: 14px;
      line-height: 38px;
      text-align: center;

      @media (min-width: $tablet) {
        width: 250px;
      }

    }

    .fieldset-wrapper {

      @media (min-width: $tablet) {
        display: flex;
        width: calc(100% - 220px);
      }

      > div {

        @media (min-width: $tablet) {
          width: 50%;
        }

        &:nth-child(odd) {
          @media (min-width: $tablet) {
            padding-right: 5px;
          }
        }

        &:nth-child(even) {
          @media (min-width: $tablet) {
            padding-left: 5px;
          }

        }
      }

    }

    .form-item {

      @media (min-width: $tablet) {
        margin-bottom: 0;
      }

      label {
        display: none;
      }

      > span {
        width: 100% !important;
        font-size: 16px;
      }

      .select2-container--default .select2-selection--single {
        height: 38px;
        border: 1px solid #e7e7e7;
        border-radius: 4px;

        .select2-selection__arrow {
          width: 20px;
          height: 38px;
          transform: translateY(0);
          position: absolute;
          top: 1px;
          right: 1px;
        }

      }

      .select2-container {
        position: relative;

        .select2-selection--single .select2-selection__rendered {
          display: block;
          padding-left: 8px;
          padding-right: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 38px;
        }

      }
    }

  }

  input[type="submit"] {
    display: block;
    margin: 0 auto;
  }

}
