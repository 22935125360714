form {

  fieldset {

    legend {

      span {
        background: white;
      }

    }

    &.fieldset--remarked {
      > legend {
        width: 100%;
        background: #CCC;
        padding: 5px;

        a {
          color: #000;
        }
      }
      > .fieldset-wrapper {
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 0 0 10px 10px;
      }
    }
  }

  .form-actions {

    input[type="submit"] {
      @include cta($highlight-blue);
      & {
        margin-right: 20px;
      }

      &:last-child{
        margin-right: 0;
      }

    }

  }

  // @todo this should be done by code, not styling
  // We remove the text format selection from the form of taxonomy terms
  &.community_group_form {
    .text-format-wrapper > .filter-wrapper {
      display: none;
    }
  }

  &.find-my-neighborhood {
    .modal-default .ctools-modal-content #modal-content>& {
      overflow: initial;

      .form-type-select {
        margin: 0;
      }
    }

    .fieldset-wrapper {
      align-items: baseline;
      background-color: white;
      display: flex;
      justify-content: center;
      left: 0;
      margin: 0 auto 20px;
      max-width: 50%;
      padding: 5px 0;
      right: 0;
      z-index: 10;
    }

    .form-type-item {
    }

    .neighborhood-map .ndd-uberzoom-container{
      margin: 0 auto;
    }
  }

}
