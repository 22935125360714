.page-admin-dashboard {

  .block-system > .content {
    padding-top: 2em;
  }

  .block-system > .content > .view,
  .page-title-wrapper > .container,
  .twocol {
    @include new-container;

    .container {
      padding: 0;
    }

  }

  .view-control-content-panes .view-header > div {
    margin-bottom: 3em;
  }

  .views-exposed-widgets {
    margin-bottom: 3em;

    @media (min-width: $tablet) {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
    }

    > div {
      float: none;
      padding: 0;
      margin-right: 20px;

      @media (min-width: $tablet) {

      }

      &:last-child {
        margin-right: 0;
      }

    }
  }

  form[id^="views-form-dashboard-control-content-"],
  form[id^="views-form-user-dashboard-"] {

    .fieldset-wrapper {

      .form-item {
        margin-right: 15px;
      }

    }

  }

  legend {
    width: 100%;
    margin-bottom: 10px;

    span {
      font-size: 16px;
    }

  }

  li {

    span, a {
      font-size: 14px;
    }

  }

  a {
    @include link;
    color: $highlight-blue;

    &:hover, &:active,
    &:focus {
      color: $text-color;
    }

  }

  input[type="text"] {
    border: 1px solid;
    padding: 10px;
  }

  input[type="submit"] {
    @include cta($highlight-blue);

    & {
      padding: 11px 20px 12px;
      margin: 0;
    }
  }

}

// Special fix for a /admin/dashboard/users page because of weird sticky headers.
.page-admin-dashboard-users {
  th {
    padding: 10px 5px;
  }

  .block-system > .content > .view {
    max-width: unset;
    width: susy-span(11 of 12);
  }
}
