.actions-stats-block {
  @include action-stats;

  .wrapper-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}
