.my-community-region-template {

  .global-container h2:not(.strikethrough-title) {
    @include accordion-title;
  }

  .hero {

    @media (min-width: $tablet) {
      margin-bottom: 100px;
    }

    @media (min-width: $laptop) {
      margin-bottom: 0;
    }

    .hero__banner {

      @media (min-width: $tablet) {
        min-height: 375px;
      }

      @media (min-width: $laptop) {
        height: 560px;
      }

    }

  }

  .heading {
    position: relative;

    @include breakpoint($laptop) {
      margin-bottom: 150px;
      padding-bottom: 50px;
    }

    .city-image {
      margin-bottom: 0;

      img {
        width: 100%;
        height: auto;
        vertical-align: top;
      }
    }

    .absolute-content {

      @media (min-width: $laptop) {
        position: absolute;
      }

      &.top {

        @media (min-width: $tablet) {
          position: absolute;
          top: 20px;
          left: 40px;
        }

        h2 {
          color: black;
          font-weight: 100;
          padding: 20px 15px 0;
          margin: 0;

          @media (min-width: $tablet) {
            color: white;
            background: $background-gray-1-transparent-5;
            border-radius: 5px;
            padding: 10px 20px;
          }
        }
      }

      &.bottom {
        color: #2f3741;
        margin-top: $global-padding-desktop;
        width: 100%;

        @include breakpoint($laptop) {
          transform: translateX(-50%);
          left: 50%;
          bottom: -80px;
          margin-top: 0;
        }

        .stat2__container .stats {

          @include breakpoint($tablet) {
            flex-direction: column;
            max-width: 100%;
          }

          @include breakpoint($laptop) {
            flex-direction: row;
          }

        }

        .stat2__item {
          @include stat-item;

          .stat2__icon {
            width: 100%;

            @include breakpoint($laptop) {
              width: 140px;
              flex-direction: row;
            }

          }

          .stat2__content {
            width: 100%;
            text-align: center;
            padding: 20px 0 0;

            @include breakpoint($laptop) {
              width: calc(100% - 140px);
              text-align: left;
              padding: 0 20px;
            }
          }
        }

        .stat2__item--text {
          text-align: center;

          @include breakpoint($laptop) {
            text-align: left;
          }
        }
      }
    }
  }

  .region-impact {
    padding: 20px 0;
    text-align: right;

    a {
      font-size: $global-font-size;
    }
  }

  .our-impact {

    .stat2__container {

      .stat2__item:after {
        @include breakpoint($laptop) {
          right: 0;
        }
      }

      .stat2__icon {
        width: 80px;

        @include breakpoint($laptop) {
          width: 100px;
          flex-direction: row;
        }

        @include breakpoint($laptop-l) {
          width: 140px;
        }
      }

      .stat2__content {
        width: calc(100% - 80px);

        @include breakpoint($laptop) {
          width: calc(100% - 140px);
        }

      }
    }

  }

  .stat2__container {
    margin-bottom: 0;

    .stat2__icon {
      width: 110px;

      @include breakpoint($laptop) {
        width: 140px;
        flex-direction: row;
      }

    }

    .stat2__content {
      width: calc(100% - 110px);

      @include breakpoint($laptop) {
        width: calc(100% - 140px);
      }
    }

  }

  .actions-stats-block {
    background-color: $background-gray-6;
    padding-top: 20px;

    @include breakpoint($laptop) {
      margin: 0 auto 70px;
      padding-top: 40px;
    }
  }

  .description {
    margin: 30px 0;
  }

  .ranks-block {
    @include breakpoint($laptop) {
      margin-bottom: 50px;
    }

    .block-title {
      margin: 0 0 20px;
    }

    .wrapper-block-content {
      width: 100%;
      @include breakpoint($laptop) {
        display: flex;
        justify-content: space-between;
      }

      .tabs-wrapper {
        margin-bottom: 20px;

        @include breakpoint($laptop) {
          margin-bottom: 0;
        }

        .nav-tabs > li a {
          font-size: 11px;

          @include breakpoint($mobile-l) {
            font-size: 14px;
          }

        }

        .item-list {
          li {
            padding-right: 20px;
          }
        }

        #community-total {
          padding-right: 20px;
          @include see-all-links;
        }
      }

      .actions-block {
        &:not(.full-width) {
          @include breakpoint($laptop) {
            flex-basis: 36%;
            padding-left: 55px;
          }
        }

        .actions-wrapper {
          height: 100%;
          background-color: $background-gray-3;
          padding: 30px;

          .item-list ul li {
            margin: 0 0 20px;
          }

        }

        h3 {
          margin-top: 0;
        }

        a {
          font-size: $global-font-size;
          font-weight: bold;
        }

        #popular-actions {
          a {
            font-size: $text-font-size;
          }
        }
      }

      ul {
        margin: 0;
      }

      li {
        margin: 0;
      }
    }
  }

  .news-resources {
    .view-community-news {
      > h2 {
        display: none;
      }
    }
  }

  .bottom-content {

    > h2 {
      margin-bottom: 10px;
    }

    .Description {

      h3 {
        margin: 0 0 20px;
      }

    }

    .wrapper-content {

      @media (min-width: $laptop) {
        display: flex;
        justify-content: space-between;
      }

    }

    .news-resources {

      @media (min-width: $laptop) {
        width: 75%;
        flex-grow: 2;
        margin-bottom: 0;
      }

      h3 {
        margin: 0 0 20px;
      }

      + .calendar {

        @media (min-width: $laptop) {
          padding-left: 20px;
        }

      }

    }

    .calendar {
      width: 100%;
      max-width: 252px;
      margin-bottom: 30px;

      @media (min-width: $laptop) {
        max-width: 100%;
        width: 25%;
        flex-grow: 2;
        margin-bottom: 0;
      }

    }
  }

}
