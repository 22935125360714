.view-posts {
  padding-top: 15px;

  > h2 {
    span {
      background: #EDEFF1;
    }

    &::before {
      width: 100%;
    }

    & + .clearfix {
      padding-top: 15px;
    }
  }

  .view-content {

    .views-row {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

    }
  }

}

// On modal.
.modal-default .ctools-modal-content #modal-content .view-posts {

  .view-filters {

  }

  .view-content {

    .views-row {
      position: relative;

      &:last-child:before {
        content: none;
      }

      &:before {
        content: '';
        display: inline-block;
        width: calc(100% + 30px);
        height: 1px;
        background: $light-blue;
        position: absolute;
        bottom: 0;
        left: -15px;
      }

    }
  }

}

// On sidebar.
.layout-sidebar {
  .view-posts .view-filters {
    font-size: 14px;
    line-height: 21px;

    .select2-container .select2-selection--single .select2-selection__rendered {
      padding-right: 20px;
    }
  }

  .node-community-group-status {
    background: $white;
    padding: 20px 20px 0;
  }
}

.rounded-box {
  @include box;
  padding: 5px 30px;
  &.white-box {
    background-color: $white;
  }
}
