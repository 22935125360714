.page-teams {
  .view-teams {
    > h3 {
      display: none;
    }
  }
}

#og-ui-confirm-subscribe {
  width: susy-span(8 of 12);
  margin: 60px;

  .form-textarea-wrapper {
    margin: 30px 0 15px;
  }

  .description {
    margin-bottom: 30px;
    font-size: 14px;
  }

  textarea {
    margin: 0;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid $supporting-2;
    font-size: 1em;
  }

  .grippie {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  label {
    @include h3;

    & {
      margin-top: 0;
    }
  }

  input[type="submit"] {
    @include cta($background-blue);
  }

  #edit-cancel {
    @include cta($supporting-2);
  }
}

.ctools-modal-content {
  #og-ui-confirm-subscribe {
    margin: 0;
  }
}
