.page-admin-dashboard {

  #block-system-main {
    .view-content {
      overflow-x: auto;
    }

    table.views-table {
      @include tableStyle;
    }
  }

}
