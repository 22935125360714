.remodal-overlay {
  z-index: 999;
}

.remodal-wrapper {
  background-color: darken($supporting-2-transparent-3, 40%);
  overflow: hidden;
  padding: 0;
  z-index: 1000;

  @include breakpoint($tablet) {
    padding: 20px 40px;
  }

  .remodal {
    background: #fff;
    box-sizing: border-box;
    color: $text-color;
    height: 100%;
    margin: 0 auto;
    max-height: 100%;
    overflow: auto;
    padding: 50px $global-padding-mobile 0 $global-padding-mobile;
    text-align: center;
    transform: translate3d(0, 0, 0);
    width: 100%;

    &.welcome-modal {

      .align-center {
        img {
          max-height: 100px;
        }
      }

      @include breakpoint($tablet) {
        padding: 20px 20px 5px;
      }

    }

    @include breakpoint($tablet) {
      height: auto;
      padding: 45px 47px 35px;
    }

    > h2 {
      margin-top: 0;
      text-align: center;
    }

    .row {
      height: 100%;
      overflow-y: auto;
      margin-left: 0;
      margin-right: 0;

      @media (min-width: $tablet) {
        max-height: 70vh;
        height: auto;
        margin-right: -15px;
        margin-left: -15px;
      }

    }

    img {
      max-width: 100%;
    }

    p {
      line-height: 24px;
      text-align: left;
    }

    ol {
      font-size: $global-font-size;
      text-align: left;

      li {
        margin-bottom: 4px;
      }
    }

    .button {
      @include blue-button;
      & {
        font-size: 1rem;
        margin: 0 20px 10px 20px;
      }
    }

  }

  .comments-feed-container {

    > a {
      margin: 5px 0 30px 10px;
    }

  }

  .modal-title {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.42857143;
    margin: 0;
  }

  .form-item {
    margin: 0 0 25px;

    label {
      line-height: 1;
      text-align: left;
      margin-bottom: 3px;
    }

    input {
      width: 100%;
      font-size: 16px;
      border: 1px solid #e7e6e6;
      padding: 6px 7px 5px;

      &:focus {
        outline: 1px solid black;
      }

    }

    .description {
      text-align: left;
      margin-top: 10px;
      position: relative;
      left: 7px;
    }

  }

  .form-actions {
    text-align: right;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  input[type="submit"],
  button.btn {
    @include blue-button;
    @include wider-button;
  }

  input[type="submit"].btn-cancel,
  a.btn-cancel,
  button.btn-cancel {
    @include cancel-blue-button;
  }

  .welcome-modal {
    p {
      text-align: center;
    }

    ol {
      counter-reset: steps-counter;
      list-style-type: none;
      padding-left: 60px;

      @include breakpoint($laptop) {
        padding-left: 190px;
      }
    ;

      li {
        counter-increment: steps-counter;

        &::before {
          content: counter(steps-counter) ")";
          padding-right: 8px;
        }
      }
    }
  }
}

#modalBackdrop,
.page__overlay {
  background: darken($supporting-2-transparent-3, 40%) !important;
  opacity: 1 !important;
}
