form.node-city_administrator_contact-form {
  padding-bottom: 30px;

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .contact-intro {
      flex-basis: 100%;
      margin-top: 0;
    }

    .contact-title {
      flex-basis: 100%;
      margin-bottom: 20px;
      margin-top: 0;

      @media (min-width: $tablet) {
        flex-basis: 30%;
      }
    }

    .contact-faq-link {
      color: #5697EA;
      display: none;
      font-size: $h1;
      font-weight: bold;

      @media (min-width: $tablet) {
        display: initial;
      }
    }

    > div {
      width: 100%;
      margin-bottom: 15px;

      &:nth-of-type(-n+4) {

        @media (min-width: $tablet) {
          width: calc(50% - 15px);
        }

      }
    }

    .vertical-tabs-panes {
      display: none;
    }

    fieldset.captcha {
      flex-basis: 80%;
    }

    .form-actions {
      align-self: flex-end;
      flex-basis: 0;
      margin-bottom: 0;

      input {
        margin-bottom: 0;
      }
    }
  }

  .form-actions {
    margin-top: 0;
    text-align: right;

    input {
      @include cta($highlight-blue);
      & {
        border: none;
      }
    }

  }

  label {
    margin-bottom: 8px;
  }

  textarea {
    width: 100%;
    color: $text-color;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    box-shadow: 0 10px 10px 0 rgba(207, 210, 220, .2);
    border: 1px solid $background-gray-1-transparent-4;
    outline: none;
    padding: 20px;
    &:focus {
      outline: 1px solid $black;
    }
  }

  input[type="tel"],
  input[type="text"] {
    border: 1px solid $background-gray-1-transparent-4;
    box-shadow: 0 10px 10px 0 rgba(207, 210, 220, .2);
    font-size: 16px;
    outline: none;
    padding: 10px;
    width: 100%;
    &:focus {
      outline: 1px solid $black;
    }
  }

  .grippie {
    display: none;
  }

}
