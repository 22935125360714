.energy-profile {

  .scroll-down {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 100%);
    text-align: right;
    padding-right: 50px;
    bottom: 98px;

    @media (max-width: $laptop - 1px) {
      display: none;
    }

    @media (min-width: $laptop) {
      width: calc(100vw - 60px);
      margin-left: -50vw;
      margin-right: -50vw;
      position: fixed;
      left: calc(50% + 60px);
      right: 50%;
    }

    span {
      display: inline-block;
      width: 50px;
      height: 50px;
      background: url("../images/icon-down.svg") no-repeat center;
      animation: upAndDown .7s infinite linear alternate;
      position: relative;
    }

  }

  @keyframes upAndDown {
    from {
      top: 0;
    }

    to {
      top: 10px;
    }
  }

  .fuel-oil-use,
  .propane-use,
  .natural-gas-use,
  .electricity-use {

    .fieldset-legend {
      i {
        display: none;
        font-size: 20px;
        margin-left: 5px;
        color: $highlight-blue;
      }
    }

    &.collapsed {
      .fieldset-legend i.collapsed {
        display: inline;
      }
    }

    &:not(.collapsed) {
      .fieldset-legend i.expanded {
        display: inline;
      }
    }


    .fieldset-wrapper {

      @media (min-width: $tablet) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      > div:not(.container-inline-date) {
        margin-bottom: 30px;

        @media (min-width: $laptop) {
          margin-bottom: 15px;
        }

      }

      > .field-type-datetime {
        width: 100%;
      }

      > .field-type-number-float {

        @media (min-width: $tablet) {
          width: calc(100% / 3);
        }
      }

      input[type="number"] {
        width: auto;
        max-width: 120px;
      }
    }

  }

  table[id^="field-vehicles-values"] {
    width: 100%;
    table-layout: fixed;
    position: relative;

    * {
      box-sizing: border-box;
    }

    tbody tr:not(:last-child) {
      padding-top: 50px;
    }

    .field-name-field-vehicle-mpg > input[type="submit"] {
      position: absolute;
      top: 10px;
      right: 9px;
    }

    .remove-button {
      display: none;
    }

    .remove-car {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }

    .slider-wrapper {
      font-size: 10px;

      @media (min-width: $mobile-l) {
        font-size: 12px;
      }

      @media (min-width: $tablet) {
        font-size: 16px;
      }

      @media (min-width: $laptop) {
        font-size: 18px;
      }
    }

  }

  .efficient-car-modal-trigger i {
    font-size: 24px;
    margin-left: 10px;
  }

  // Indicates the pending fields
  .profile-highlighted {
    border: 5px solid orange;
    border-radius: 10px;
    padding: 10px;
  }

  .field-name-field-select-ways + .user-notification {
    font-size: 24px;
    color: $glacial-blue;
  }

  .section-heating-cooling,
  .section-utility-info,
  .fieldset-group-utilities,
  .fieldset-estimator {
    > legend {
      display: none;
    }
  }
}
