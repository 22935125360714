.sidebar-trigger {
  @include link;
  background-color: $light-blue;
  color: white;
  font-weight: bold;
  padding: 6px 15px;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 0;
  z-index: 99999;

  @include breakpoint($laptop) {
    display: none;
  };

  &:hover {
    background-color: $highlight-blue;
    text-decoration: none;
  }

  &:focus {
    color: white;
  }

  i {
    font-size: 20px;
  }
}

.remodal-wrapper .remodal.modal-sidebar-mobile {
  max-width: 100%;
  width: 100%;
  height: 100%;
  text-align: left;
  overflow: hidden;
  padding: 50px 0 0;
  position: relative;

  @media (min-width: $tablet) {
    max-width: 520px;
    height: auto;
    padding: 50px 45px 35px;
  }

  .form-item label {
    margin-bottom: -1px;
  }

  .create-post {
    @include link;
    @include blue-button;
    & {
      font-size: 12px;
      float: left;
      padding: 5px 15px;
    }
  }

  .view-filters {
    float: right;

    .views-exposed-widgets {
      margin: 0;
    }

    .views-exposed-widget {
      padding: 0;
    }

    .form-type-select {
      display: flex;
      align-items: center;

      label {
        margin-right: 7px;
      }

    }

  }

  .view-content {
    clear: both;

    @media (min-width: $tablet) {
      padding: 15px 2px 0;
    }

    .views-row {
      border-bottom: 1px solid $light-blue;
      margin-bottom: 0;

      .node {
        padding-top: 20px;
      }

    }

  }

  .header {
    padding: 0 5px;

    @media (min-width: $tablet) {
      padding: 0;
    }

    .filters {

      > div {
        @include link;
        display: inline-block;
        font-size: 16px;
        margin-right: 8px;
        margin-bottom: 5px;

        @media (min-width: $tablet) {
          font-size: 14px;
        }

        &.active {
          text-decoration: underline;
        }

        &:last-child {
          margin-right: 0;
        }

      }

    }

    .sort {
      font-size: 16px;
      line-height: 21px;
      text-align: right;
      margin-bottom: 6px;

      @media (min-width: $tablet) {
        font-size: 14px;
      }

      .select2-container .select2-selection--single .select2-selection__rendered {
        padding-right: 20px;
      }

    }
  }

}
