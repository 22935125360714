.addthis_sharing_toolbox {

  .at-share-btn-elements {

    a:last-child {
      margin-right: 0;
    }

  }

}
