#comments {
  padding-top: 25px;

  .filter-help,
  .filter-guidelines {
    display: none;
  }

  .comment {
    padding-bottom: 20px;
    margin-bottom: 20px;

    &:hover {
      .comment-actions {
        display: block;
      }
    }

    .heading {
      position: relative;

      .submitted {
        margin: 0;
      }

    }

    p {
      margin-top: 0;

      + p {
        margin-top: 1em;
      }
    }

    .user-picture {
      display: inline-block;
      padding-right: 10px;
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;

      a {
        display: inline-block;
      }

      img {
        width: 29px;
        height: auto;
        border-radius: 100%;
        vertical-align: middle;
      }

    }

    .comment-title {
      font-size: 16px;
      padding-left: 40px;
      margin: 0 !important;
      font-weight: bold;

      a {
        @include link;
        color: black;

        &:hover {
          color: $highlight-blue;
        }

      }

    }

    .content {
      @include global-box-shadow;

      margin: 10px 0;
      padding: 10px 20px;
      background-color: $white;
      border-radius: 50px;
      display: flex;
      justify-content: space-between;
      word-break: break-word;
    }

    .submitted {
      font-size: 12px;
      color: $supporting-1;
      margin: 0;
      padding: 0;
    }

    .links.inline {

      li {
        padding: 0;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

      }
    }

  }

  > form {
    padding: $modalFlank $modalFlank 0 $modalFlank;

    .form-actions {
      text-align: right;
    }

    .captcha {
      font-size: 14px;
    }

  }

  .title {
    display: none;
  }

  .form-type-textarea label {
    width: 100%;

    .form-required {
      display: none;
    }

  }

  .links {
    margin: 0 10px 0 0;
    padding: 0;
  }

  textarea {
    min-height: 100px;
    border: 1px solid $light-blue;
    outline: none;

    &:focus {
      border: 1px solid $highlight-blue;
    }

  }

  form > div {
    padding-top: 0;
  }

  .form-item {
    margin-top: 0;
  }

  .form-actions {
    text-align: right;

    input {
      @include global-box-shadow;

      font-size: 1em;
      color: $white;
      background-color: $background-green;
      padding: 10px 20px;
      line-height: 1;
      border-radius: 5px;
      border: none;


      &:first-child {
        margin-left: 0;
      }

    }
  }

}

.comment-actions {
  position: relative;
  display: none;

  button {
    cursor: pointer;
    border: none;
    background: none;
    color: #666666;
    outline: none;

    &:hover {
      color: #000;
    }
  }

  ul {
    @include global-card;

    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $white;
    margin: 0;
    padding: 0;
    flex-direction: column;
    z-index: 10;

    li {
      padding: 10px;
      cursor: pointer;
      white-space: nowrap;
      border-bottom: $supporting-2-transparent-1;

      i {
        margin-right: 10px;
      }

      &:hover {
        background-color: $supporting-2-transparent-1;
      }
    }
  }

  &.active {
    ul {
      display: flex;
    }
  }
}

#comments {
  .comment-form {
    textarea {
      @include global-box-shadow;

      min-height: auto;
      height: auto;
      padding: 10px 20px;
      font-size: 1em;
      font-family: proxima-nova, sans-serif;
      border: none;
      border-radius: 50px;
    }

    .filter-wrapper {
      display: none;
    }
  }

  .grippie {
    display: none;
  }
}

h3.comment-title {
  font-weight: bold !important;
}

.comment-bottom {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  margin-bottom: 10px;

  > div {
    display: flex;

    .links {
      margin-right: 10px;
    }

    .comment-actions {
      display: inline-block;
    }

  }
}
