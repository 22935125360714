body #comments {
  form.comment-form.ajax-comments-form-edit {
    padding: 20px 20px 10px;
    background: rgba(255, 255, 0, 0.1);
    margin-bottom: 30px;

    .form-actions {
      input[id^=cancel-ajax] {
        display: inline-block;
      }
    }
  }
}