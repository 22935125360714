#c02free-user-actions-add-form {
  text-align: center;

  #select-timeline {
    font-size: 16px;
    padding: 0 20px;
    margin-bottom: 20px;

    @media (min-width: $tablet) {
      padding: 0;
    }

  }

  .container-inline-date {
    width: 100%;

    .form-item {
      margin-bottom: 0;
    }

    .date-padding {

      @media (min-width: $tablet) {
        display: flex;
      }

    }

    .form-type-select {
      display: flex;
      align-items: center;
      font-size: 16px;
      border: 1px solid $background-gray-1;
      float: none;
      padding: 5px 5px 5px 12px;

      &:first-child {
        margin-bottom: 20px !important;

        @media (min-width: $tablet) {
          margin-bottom: 0 !important;
        }

      }

      > label {
        margin: 0 !important;
      }

    }
  }

  input[type="submit"] {
    margin-top: 20px;
  }

}
