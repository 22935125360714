.change-teamcover-bg-launcher {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.change-teamcover-bg-modal {
  &.open {
    & + .overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      background: rgba(196, 196, 196, 0.5215686274509804);
      z-index: 2;
    }
  }

}

.teams-teamcover-form {

  > .from-actions {
    margin-top: 20px;

    button.hidden {
      display: none;
    }

    > * {
      margin-right: 10px;
    }
  }

  .form-change-image {
    .real-form {
      visibility: hidden;
      position: absolute;
      left: 9999em;
      top: 9999em;
    }

  }

  > input[type="submit"] {
    visibility: hidden;
    position: absolute;
    left: 9999em;
    top: 9999em;
  }

}
