.page-communities {
  main {
    @include global-container;
  }

  .view-communities {
    .view-content {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      overflow: hidden;
    }

    table {
      width: 100%;

      thead {
        background-color: $supporting-2;
        padding: 0 5px;

        @include breakpoint($tablet) {
          padding: 0 10px;
        }

      }

      tr {
        border: none;
      }

      th,
      td {
        border: none;
        padding: 10px 5px;

        @include breakpoint($tablet) {
          padding: 10px;
        }

      }

      tbody {
        padding: 0 5px;

        @include breakpoint($tablet) {
          padding: 0 10px;
        }

        tr {
          &.odd {
            background-color: $supporting-2-transparent-3;
          }

          &.even {
            background-color: $supporting-2-transparent-1;
          }
        }
      }
    }

    img {
      height: auto;
      max-width: 100px;
    }
  }
}
