.action-groups {

  .action-tabs {
    display: block;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;

    .tabs-wrapper {
      display: flex;
      border-bottom: 2px solid rgba(50, 50, 50, .15);
      margin-bottom: 34px;

      .action-tab {
        display: inline-block;
        border-radius: 5px 5px 0 0;
        background: transparent;
        padding: 0;
        margin-right: 15px;
        margin-left: 0;

        &:last-child {
          margin-right: 0;
        }

        a {
          @include link;
          display: inline-block;
          color: $action-gray-text;
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          border-radius: 5px 5px 0 0;
          position: relative;

          &.action-list-categories {
            background: url("../images/icon-grid-gray.svg") no-repeat left 25px center $gray-tab;
            background-size: 14px;
            padding: 13px 14px 11px 43px;

            @media (min-width: $laptop) {
              padding: 18px 29px 16px 58px;
            }

          }

          &.action-list-all-actions {
            background: url("../images/icon-explore-gray.svg") no-repeat left 25px center $gray-tab;
            background-size: 25px;
            padding: 13px 14px 11px 60px;

            @media (min-width: $laptop) {
              padding: 18px 29px 16px 70px;
            }

          }

          &:hover,
          &:focus,
          &.active {
            color: white;

            &.action-list-categories {
              background: url("../images/icon-grid-white.svg") no-repeat left 25px center $highlight-blue;
              background-size: 14px;
            }

            &.action-list-all-actions {
              background: url("../images/icon-explore-white.svg") no-repeat left 25px center $highlight-blue;
              background-size: 25px;
            }

          }

          &.active {

          }

        }
      }
    }

  }

  .grey-box {
    background: $gray-tab;
    border-radius: 5px;
    padding: 20px 10px 10px;
  }

  .empty-result {
    color: $highlight-blue;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  .all-actions-messages {

    .messages-column {
      text-align: center;

      @media (min-width: $laptop) {
        text-align: left;
      }

      h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        margin: 0 0 10px;
      }

      h6 {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        margin: 0 0 54px;
      }

    }

    .action-tab-link {
      font-size: 18px;
      background: transparent;
      padding: 0;
      margin: 0 0 30px;

      a {
        @include link;
        display: inline-block;
        font-size: 18px;
        line-height: 27px;

        &:hover, &:focus {
          text-decoration: underline;
        }

      }
    }

  }

  .filters {

    .filters-wrapper {
      display: block;

      @media (min-width: $laptop) {
        display: flex;
      }

      @media (min-width: $laptop-l) {
        justify-content: space-between;
      }

    }

    .group-wrapper {
      display: block;
      flex-basis: auto;
      margin-bottom: 20px;

      @media (min-width: $laptop) {
        margin-right: 3px;
        margin-bottom: 0;
      }

      &.left-wrapper {

        @media (min-width: $laptop) {
          width: 25%;
        }

      }

      &.middle-wrapper {

        @media (min-width: $laptop) {
          width: 60%;
        }

        @media (min-width: $laptop-l) {
          width: 50%;
        }

      }

      &.right-wrapper {
        margin-right: 0;
        margin-bottom: 0;

        @media (min-width: $laptop) {
          width: 15%;
        }

        @media (min-width: $laptop-l) {
          width: 25%;
        }

      }

      .group-title {
        color: $text-color;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
        text-decoration: none;
        margin-bottom: 10px;
      }

      .filter-box {
        width: 100%;
        background: white;
        border-radius: 5px;
        padding: 19px 10px;

        @media (min-width: $laptop) {
          height: 76px;
          display: flex;
          align-items: center;
        }

        @media (min-width: $laptop-l) {
          padding: 19px 30px;
        }

        &.left {

          @media (min-width: $laptop-l) {
            padding: 19px 20px;
          }

        }

        &.right,
        &.sort-filter {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.middle {
          justify-content: space-between;

          @media (min-width: $tablet) {
            display: flex;
            align-items: center;
          }

          .select-wrapper:last-child .select-title {
            margin-top: 13px;
          }

        }

      }

      .form-radio {
        display: flex;
        align-items: center;
        padding-top: 2px;
        margin-right: 15px;
        position: relative;

        @media (min-width: $laptop) {
          margin-right: 25px;
        }

        &:last-child {
          margin-right: 0;
        }

        input[type="radio"]:not(old) {
          width: 16px;
          height: 16px;
          opacity: 0;
          margin: 0;
          position: absolute;
          top: 5px;
          left: 2px;

          &:not(old) + label::-moz-selection,
          &:not(old) + label::selection {
            color: inherit;
            background-color: transparent;
          }

          &:checked + label:before {
            box-shadow: inset 0 0 0 2px white;
            background: $green;
          }

          + label {
            font-size: 16px;
            font-weight: normal;
            line-height: 24px;
            cursor: pointer;
            transition: opacity .4s;

            &:focus,
            &:hover {
              opacity: .9;
            }

            &:before {
              content: '';
              display: inline-block;
              width: 16px;
              height: 16px;
              background: white;
              border: 1.5px solid $supporting-2;
              border-radius: 50%;
              cursor: pointer;
              vertical-align: top;
              margin-top: 3px;
              margin-right: 10px;
            }

          }
        }

      }

      .select-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        @media (min-width: $tablet) {
          margin-right: 15px;
          margin-bottom: 0;
        }

        &:last-child {
          margin-right: 0;
          margin-bottom: 0;
        }

        .select-title {
          font-size: 14px;
          font-weight: normal;
          line-height: 100%;
          margin-right: 5px;

          @media (min-width: $laptop) {
            max-width: 100px;
          }

          @media (min-width: $laptop-l) {
            margin-right: 6px;
          }

        }

        select {
          width: 100%;
          font-size: 16px;
          line-height: 24px;
          background: url("../images/icon-chevron-down.svg") no-repeat right 15px center white;
          background-size: 10px;
          border: 1px solid #DDE2E8;
          border-radius: 3px;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          padding: 8px 30px 6px 13px;

          @media (min-width: $laptop) {
            max-width: 135px;
            width: auto;
          }

          @media (min-width: $laptop-l) {
            max-width: unset;
          }

          &:focus {
            outline: none;
          }

        }

      }

      .checkbox-wrapper {
        width: 50px;
        height: 25px;
        border-radius: 20px;
        position: relative;

        input {
          width: 100%;
          height: 100%;
          background: #ababab;
          border-radius: 20px;
          cursor: pointer;
          appearance: none;
          outline: none;
          margin: 0;
          position: absolute;
          top: 0;
          left: 0;

          &:checked {
            background: $green;

            + label {
              left: calc(100% - 24px);
            }

          }

        }

        label {
          display: inline-block;
          width: 23px;
          height: 23px;
          background: white;
          border-radius: 50%;
          transition: all .4s;
          cursor: pointer;
          position: absolute;
          top: 1px;
          left: 1px;
        }

      }
    }

  }

  #allActions {

    > h4 {
      display: inline-block;
      background: white;
      cursor: pointer;
      z-index: 3;
      padding-right: 50px;
      margin: 0 0 22px;
      position: relative;

      @media (min-width: $laptop) {
        display: block;
        cursor: default;
        pointer-events: none;
        padding-right: 0;
      }

      &.is-active {

        + .filters {
          opacity: 1;
          transform: translateY(0);
          position: static;
        }

        &:before {
          transform: translateY(-50%) rotate(180deg);
        }

      }

      &:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("../images/icon-chevron-down.svg") no-repeat center;
        background-size: contain;
        transform: translateY(-50%);
        transition: all .4s;
        position: absolute;
        top: 50%;
        right: 15px;

        @media (min-width: $laptop) {
          content: none;
        }

      }

    }

    > .filters {
      width: 100%;
      opacity: 0;
      transform: translateY(-120%);
      transition: transform .4s ease-in-out, position .4s ease-out;
      position: absolute;

      @media (min-width: $laptop) {
        opacity: 1;
        transform: translateY(0);
        position: static;
      }

    }

    .load-more-wrapper {

      a {
        display: block;
        text-align: center;
        padding: 7px 20px;
      }

    }

  }

  .actions-info {
    font-size: 16px;
    margin-left: 5px;
    color: #CFD2DC;
    border-radius: 50%;
    cursor: pointer;
    transition: all .4s;
    position: relative;

    &:hover {

      span {
        opacity: 1;
      }

    }

    span {
      width: 300px;
      display: inline-block;
      color: white;
      font-size: 12px;
      line-height: 1.4;
      font-weight: 400;
      background: $background-gray-1-transparent-3;
      border-radius: 4px;
      padding: 10px 20px;
      transform: translateX(-50%);
      opacity: 0;
      pointer-events: none;
      transition: all .4s;
      position: absolute;
      left: 50%;
      bottom: calc(100% + 11px);

      &:before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid $background-gray-1-transparent-3;
        border-radius: 2px;
        transform: translateX(-50%);
        position: absolute;
        left: 50%;
        bottom: -7px;
      }

    }
  }

}
