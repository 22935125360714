$row-margin: 40px;

@mixin flex-grid() {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.landing-page__letter,
.landing-page__how-it-works,
.threecol,
.landing-page__our-progress {
  p {
    font-size: 18px !important;
  }
}

.landing-page {
  &__header {
    padding: 10px 0;

    .inner {

      @media (min-width: $mobile-l) {
        @include flex-grid();
      }

      div {
        text-align: center;
        margin-top: 15px;

        @media (min-width: $mobile-l) {
          margin-top: 0;
        }

        .landing-page__link {
          margin-left: 0;

          @media (min-width: $tablet) {
            margin-left: 10px;
          }

        }
      }
    }

    img {
      display: inline-block;
      max-width: 290px;
      height: auto;
      margin-right: 20px;

      @media (min-width: $tablet) {
        max-width: initial;
        width: auto;
        height: 60px;
        margin-right: 0;
      }
    }
  }

  &__hero {
    display: flex;
    align-items: flex-end;
    height: 40vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 80px;
    position: relative;

    @include breakpoint($laptop) {
      height: 60vh;
    }

    > .container > .row:last-child {
      width: 100%;
      transform: translateX(-50%);
      margin: 0;
      position: absolute;
      left: 50%;

      @include breakpoint($tablet) {
        bottom: -80px;
      }

      .stat2__container {
        margin-bottom: 0;
      }

    }

    .stat2__container {
      box-shadow: 0 0 10px $black-5;
    }

    .stat2__content {
      &.blue {
        h4 {
          font-size: $big-text;
        }
      }
    }

    &-text {
      text-align: center;

      @include breakpoint($laptop) {
        margin-bottom: 68px;
      }

      &.gray-background {
        @include gray-background-box;
        padding: 10px 30px;

        display: inline-block;
        margin-bottom: 20px;

        @include breakpoint($laptop) {
          margin-bottom: 58px;
        }

        h3 {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }

      h2, h3 {
        color: $white;
        font-weight: 100;
        letter-spacing: .05em;
        margin: 0;
      }

      h2 {
        font-weight: 100;
        line-height: 30px;
        margin-bottom: 11px;
        text-shadow: 0 1px 10px $black-20;
        text-transform: uppercase;

        @include breakpoint($laptop) {
          font-size: $huge-text;
          line-height: 58px;
          margin-bottom: 20px;
        }

        // Wysiwyg field
        p {
          font-size: 24px;
          margin: 0;

          @include breakpoint($laptop) {
            font-size: $huge-text;
            line-height: 58px;
          }
        }
      }

      h3 {
        line-height: 22px;
        margin-bottom: 20px;
        text-shadow: 0 1px 10px $black-20;

        @include breakpoint($laptop) {
          font-size: 32px;
          line-height: 39px;
        }

        // Wysiwyg field
        p {
          font-size: 18px;
          margin: 0;

          @include breakpoint($laptop) {
            font-size: 32px;
            line-height: 39px;
          }
        }
      }
    }
  }

  main {
    h2 {
      @include section-title;

      & {
        border: none;
      }
    }
  }

  &__letter {
    border-radius: 5px;
    margin-top: 385px;

    @include breakpoint($tablet) {
      margin-top: 110px;
    }

    @include breakpoint($laptop) {
      margin-top: 130px;
    }

    > .church-letter-container > h2 {
      text-align: center;
    }

    .church-letter-container {

      @include breakpoint($laptop) {
        max-width: $global-width;
        width: 70%;
      }

      @include breakpoint($laptop-l) {
        width: 66%;
      }

    }

    &--organization {
      @include breakpoint($laptop) {
        margin-bottom: 80px;
      }

      p {
        margin: 0;
      }

      blockquote {
        margin: 0;
        position: relative;

        @include breakpoint($laptop) {
          padding-left: $global-padding-desktop;
        }

        &:before {
          background-color: $background-gray-4;
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          left: -$global-padding-mobile;
          width: 8px;

          @include breakpoint($laptop) {
            left: 0;
          }
        }
      }
    }

    &--organization, &--local {
      padding: 15px 0;

      p {
        font-size: 18px;
        line-height: 27px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .diocese-select {
      text-align: center;
      background-color: $light-blue;
      padding: $global-padding-desktop 0;

      @include breakpoint($laptop) {
        margin-bottom: 65px;
        padding: 70px 0;
      }

      .church-letter-container {

        @include breakpoint($laptop) {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
        }

      }

      h3 {
        color: $white;
        font-size: $medium-text;
        font-weight: bold;
        line-height: 42px;
        margin: 0 0 20px 0;

        @include breakpoint($laptop) {
          display: inline-block;
          margin: 0 30px 0 0;
        }
      }

      .select2-container {
        background-color: $white;
        border-radius: 3px;
        font-size: $text-font-size;
        line-height: 24px;
        padding: 5px 15px;
        width: 100% !important;
        max-width: 400px;

        @include breakpoint($tablet) {
          display: inline-block;
          width: 330px !important;
        }
      }

      .select2-selection {
        .select2-selection__arrow {
          height: 35px;
        }
      }
    }
  }

  &__testimonials {

    > .global-container {

      @include breakpoint($laptop) {
        max-width: $global-width;
        width: 80%;
      }

    }

  }

  .testimonial-slides {
    .slick-prev,
    .slick-next {
      bottom: $global-padding-desktop;
      height: 42px;
      top: auto;
      width: 42px;
      z-index: 10;

      @include breakpoint($laptop) {
        bottom: 80px;
      }

      &:before {
        content: '';
      }
    }

    .slick-prev {
      background-image: url("../../img/arrow-prev.svg");
      left: $global-padding-desktop;

      @include breakpoint($laptop) {
        left: calc(40% + 30px);
      }
    }

    .slick-next {
      background-image: url("../../img/arrow-next.svg");
      right: $global-padding-desktop;
    }
  }

  &__testimonial {
    margin-bottom: 30px;
    outline: none;

    @include breakpoint($laptop) {
      display: flex !important;
      margin-bottom: 80px;
    }

    &--left, &--right {
      width: 100%;
    }

    &--left {
      @include breakpoint($laptop) {
        height: 386px;
        overflow: hidden;
        width: 40%;
      }

      img {
        width: 100%;
        height: auto;

        @include breakpoint($laptop) {
          height: 100%;
          width: auto;
        }
      }
    }

    &--right {
      background-color: $light-blue;
      color: $white;
      padding: $global-padding-desktop;
      padding-bottom: 90px;

      @include breakpoint($laptop) {
        padding-bottom: $global-padding-desktop;
        width: 60%;
      }
    }

    &--body {
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;

      @include breakpoint($laptop) {
        font-size: $medium-text;
        line-height: 42px;
      }

      p {
        font-size: 20px;
        line-height: 30px;
        margin-top: 0;

        @include breakpoint($laptop) {
          font-size: $lisa-medium-text;
          line-height: 32px;
        }
      }
    }

    &--author {
      font-size: $text-font-size;
      line-height: 24px;

      @include breakpoint($laptop) {
        font-size: $global-font-size;
        line-height: 30px;
      }
    }
  }

  &__how-it-works {
    background-color: $background-gray-4;
    padding: $global-padding-desktop 0;

    @include breakpoint($laptop) {
      padding: 80px 0;
    }

    .homepage-how-it-works h2 {
      margin-top: 0;

      span {
        background-color: $background-gray-4;
      }

    }

    .how-it-works_steps {
      @include breakpoint($tablet) {
        display: flex;
      }
    }

    .how-it-works_item {
      margin-bottom: 30px;
      text-align: center;

      @include breakpoint($tablet) {
        flex-basis: calc(100% / 3);
        margin-bottom: 0;
        margin-right: 60px;

        &:last-child {
          margin-right: 0;
        }
      }

      .text {
        font-size: $global-font-size;
        line-height: 27px;
      }
    }
  }

  &__our-progress {
    margin-bottom: 50px;
    padding: $global-padding-desktop 0;

    @include breakpoint($laptop) {
      padding: 80px 0;
    }

    h2 {
      max-width: 250px;
      display: flex;
      justify-content: center;
      text-align: center;
      position: relative;
      margin: 0 auto 26px;

      @media(min-width: $tablet) {
        max-width: 600px;
      }

      &:before {
        content: "";
        width: 95%;
        border-top: 3px solid $highlight-blue;
        margin: 0 auto;
        transform: translateY(-50%);
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 0;
      }

      span {
        display: inline-block;
        background: $white;
        padding: 0 10px;
        z-index: 2;

        @media(min-width: $tablet) {
          padding: 0 15px;
        }

      }

    }

    .stat2__item:not(.separator) {
      padding: 30px 0;

      @media(min-width: $tablet) {
        flex-grow: 1;
        padding: 30px 20px 30px 0;
      }

      @include breakpoint($laptop) {
        flex-basis: 20%;
        flex-grow: 0;
      }

      .stat2__icon {
        max-width: 80px;
        width: 30%;

        @include breakpoint($laptop) {
          width: 32%;
          padding-bottom: 25px;
        }

        img {
          @media(min-width: $tablet) {
            max-width: 60px;
          }
        }

      }

      .stat2__content {
        width: calc(100% - 80px);
        padding: 4px 0 0 20px;

        @media(min-width: $tablet) {
          padding: 20px 0 0 10px;
        }

        @include breakpoint($laptop) {

          &--text {
            min-height: 68px;
            font-size: $small-text;
          }

        }
      }

      h4 {
        font-size: 34px;

        @include breakpoint($laptop) {
          font-size: 24px;
        }
      }

    }

    .actions-stats-block {
      font-size: $small-text;
      padding: 0;

      @include breakpoint($laptop) {
        max-width: 80%;
      }
    }
  }

  &__leaderboard {
    .diocese-toggle {
      text-align: center;
      margin-bottom: 30px;

      @include breakpoint($laptop) {
        text-align: left;
      }

      a {
        color: $light-blue;
        display: inline-block;
        font-size: $text-font-size;
        line-height: 19px;

        &:hover {
          text-decoration: none;
        }

        & + a {
          margin-left: 20px;
        }

        &.active {
          border-bottom: 4px solid $light-blue;
          font-weight: bold;
        }
      }

      + .table-responsive {

        > div {
          display: none;

          &.active {
            display: block;
          }

        }
      }
    }

    .dioceses-table,
    .church-list {
      overflow-x: scroll;

      @include breakpoint($laptop) {
        overflow-x: auto;
      }

      .sticky-header {
        display: none !important;

        @include breakpoint($laptop) {
          display: block !important;
        }
      }

      table {
        width: 100%;
      }

      thead {
        background-color: $supporting-3;
        display: none;
        font-size: $text-font-size;

        @include breakpoint($laptop) {
          display: table-header-group;
        }
      }

      tbody {
        border: none;
        font-size: $global-font-size;
      }

      tr {
        border: none;
        display: block;

        @include breakpoint($laptop) {
          display: table-row;
        }

        &.highlight {
          font-weight: bold;
        }
      }

      th,
      td {
        padding: 5px 15px;

        @include breakpoint($laptop) {
          padding: 15px;
        }
      }

      td {
        display: block;
        text-align: left;

        @include breakpoint($laptop) {
          display: table-cell;
          text-align: center;
        }

        &:first-child {
          text-align: left;
        }

        .heading-label {
          font-weight: bold;
          margin-right: 5px;

          @include breakpoint($laptop) {
            font-weight: normal;
          }
        }

        .content {
          display: inline;
        }
      }

      .even {
        background-color: $background-gray-2;
      }
    }
  }

  #footer-slogan {
    background: url('../images/bg-footer-slogan-left.svg') no-repeat bottom -34px center $light-blue;
    background-size: 209px;
    color: $white;
    margin-top: 50px;
    padding: 30px 30px 37px;
    text-align: center;
    position: relative;

    @include breakpoint($laptop) {
      background: url('../images/bg-footer-slogan-left.svg') no-repeat bottom -34px left -10px $light-blue;
      padding: 50px 0;
      margin-top: 70px;
    }

    &:after {
      display: inline-block;
      width: 209px;
      height: 182px;
      background: url('../images/bg-footer-slogan-right.svg') no-repeat center;
      background-size: contain;
      position: absolute;
      bottom: -34px;
      right: -8px;

      @include breakpoint($laptop) {
        content: '';
      }

    }

    #footer-slogan-wrap {

      @include breakpoint($laptop) {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 650px;
      }

      @include breakpoint($laptop-l) {
        max-width: 704px;
      }

      .field-item {

        @include breakpoint($laptop) {
          text-align: left;
        }

      }

    }

    .footer-headline-text,
    .footer-headline-button {
      @include breakpoint($laptop) {
        display: inline-block;
      }
    }

    .footer-headline-button {
      @include breakpoint($laptop) {
        float: right;
      }

      a {
        background-color: $dark-blue;
        border-radius: 4px;
        color: $white;
        display: inline-block;
        font-size: $small-text;
        font-weight: bold;
        line-height: 17px;
        padding: 12px 20px;
        text-transform: uppercase;
      }
    }

    p {
      font-size: 22px;
      line-height: 32px;
      margin: 0 0 20px;

      @include breakpoint($laptop) {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 23px;

        @include breakpoint($laptop) {
          margin-bottom: 0;
        }

      }

      strong {
        font-size: 24px;
        line-height: 26px;
      }

    }
  }

  #footer {
    background-color: $background-gray-8;
    margin-top: 0 !important;
    padding-top: 25px;

    @include breakpoint($laptop) {
      padding-bottom: 50px;
      padding-top: 50px;
    }

    img {
      height: auto;
      width: 266px;
    }

    .logo-wrapper,
    .menu-wrapper {
      @include breakpoint($laptop) {
        display: inline-block;
      }
    }

    .menu-wrapper {
      @include breakpoint($laptop) {
        float: right;
      }
    }

    .menu {
      width: 100%;

      @include breakpoint($laptop) {
        padding: 0;
      }

      li {
        list-style: none;
        margin-bottom: 15px;

        @include breakpoint($laptop) {
          margin-bottom: 0;
          margin-right: 50px;
        }
      }

      a {
        color: $highlight-blue;
        font-size: $text-font-size;
        line-height: 33px;
        text-decoration: underline;
      }
    }
  }
}

.landing-page__cta {
  @include solid-button($light-blue);

  & {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-right: 10px;
  }

  &:hover, &:focus {
    text-decoration: none;
    background-color: $highlight-blue;
    color: $white;
  }
}

.row-button {
  margin: $row-margin 0;
  text-align: center;
}

.row-climate-101 {
  font-size: $global-font-size;
  line-height: 27px;
  margin-bottom: $row-margin;

  @include breakpoint($laptop) {
    margin-bottom: 80px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.domain-landingpage-org {
  #main-menu {
    display: none;
  }
}

.landing-page__link {
  margin-left: 15px;
}

.select2-container--default {
  z-index: 999;
}

.modal__landing-page {
  text-align: left;
  padding: 0 50px;

  label {
    width: 110px;
    text-align: right;
    padding-right: 5px;
  }

  &--city {
    margin-bottom: 5px;
  }

  #sign-up {
    display: block;
    margin: 10px auto 0;
  }
}

[id^="c02free-landing-page-login-form"],
[id^="c02free-landing-page-get-started-form"] {

  .form-item-wrapper {
    margin-bottom: 1em;

    @media (min-width: $tablet) {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .label-item {

      @media (min-width: $tablet) {
        width: 220px;
        height: 38px;
      }

      .panel-title {
        font-size: 14px;
        line-height: 38px;
      }

    }

    .panel-body {
      float: none;
      padding: 0;

      @media (min-width: $tablet) {
        display: flex;
        width: calc(100% - 220px);
      }

      > div.form-item {
        width: 100%;
        margin-bottom: 20px;

        @media (min-width: $tablet) {
          width: 50%;
          margin-bottom: 0;
        }

        &:nth-child(odd) {

          @media (min-width: $tablet) {
            padding-right: 10px;
          }

        }

        &:nth-child(even) {

          @media (min-width: $tablet) {
            padding-left: 10px;
          }

        }

        + div {
          width: 100%;

          @media (min-width: $tablet) {
            width: 50%;
          }

        }

      }

      .form-item {
        margin: 0;
      }

      .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: 38px;
      }

      .select2-container--default .select2-selection--single .select2-selection__arrow,
      .select2-container .select2-selection--single {
        height: 38px;
      }

      .select2-container--default .select2-selection--single {
        border: 1px solid #e7e7e7;

        &:focus {
          border: 1px solid #5897fb;
          outline: none;
        }

      }

      .select2 {
        width: 100% !important;
      }

    }
  }

}
