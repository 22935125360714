#user-pass-reset {

  > div {
    text-align: center;

    p {
      color: white;
      font-size: 20px;
      background: $highlight-blue;
      padding-bottom: 1em;
      margin: 0;

      &:first-child {
        padding-top: 50px;
      }

      &:last-of-type {
        padding-bottom: 50px;
      }

    }

    .form-actions {
      margin-top: 50px;

      input {
        color: $text-color;
        font-size: 18px;
        background: rgba(207, 210, 220, .2);
        border: 2px solid $supporting-2;
        text-align: center;
        border-radius: 5px;
        transition: all .4s;
        cursor: pointer;
        padding: 15px 30px;

        &:hover,
        &:focus {
          background: lighten($supporting-2, 10%);
        }

      }
    }
  }

}
