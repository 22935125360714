.profile-wizard-page {
  .view-id-teams {
    > .view-header {
      display: none;
    }
  }
}

.wizard {
  &-header {
    width: 100%;
    display: block;
  }

  &-header, &-section {
    border-bottom: 2px solid $background-gray-2;
  }

  &-section {
    display: none;
    justify-content: space-between;

    i {
      font-size: 4em;
    }

    h3 {
      margin-top: 0;
      line-height: 1;
    }

    &.shown {
      display: flex;
    }
  }

  &-type-box, &-type-timeline, &-type-normal {
    padding: 0;
    flex-basis: susy-span(8 of 12);
  }

  &-help {
    flex-basis: susy-span(12 of 12);
    margin-top: 15px;

    background-color: $background-gray-2;
    padding: 10px;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    justify-content: center;
    flex-direction: column;

    @media (min-width: $laptop) {
      padding: 40px;
      flex-basis: susy-span(4 of 12);
    }

    p + iframe {
      margin-top: 1em;
    }

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid $background-gray-2;
      transform: translate(-50%, 0) rotate(90deg);
      position: absolute;
      top: -14px;
      left: 50%;

      @media (min-width: $laptop) {
        transform: translate(0, -50%);
        top: 50%;
        left: -10px;
      }

    }

  }

  &-type-normal {
    input {
      appearance: none;
      border: 2px solid $supporting-3;
      border-radius: 5px;
      padding: 10px;
      display: inline;
      margin-right: 10px;
      font-size: 1em;
    }
  }
}

.box-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: susy-span(12 of 12);

  @media (min-width: $tablet) {
    justify-content: flex-start;
  }

  &.box-small {
    width: susy-span(12 of 12);
    margin-bottom: 20px;

    @media (min-width: $laptop) {
      display: flex;
    }

    .box {
      flex-basis: 150px;

      @media (min-width: $laptop) {
        margin-right: 20px;
      }

    }

    i {
      font-size: 30px;
    }
  }
}

.box {
  @include center-flex;
  flex-direction: column;
  text-align: center;
  background-color: $background-gray-2;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid $supporting-2;
  height: 128px;
  width: 128px;
  margin-right: 15px;
  margin-bottom: 15px;

  @media (min-width: $tablet) {
    margin-right: 30px;
  }

  i {
    margin-bottom: 5px;
  }

  &.checked {
    i {
      color: $white;
    }
  }
}

.timeline {
  &-wrapper {

    @media (min-width: $tablet) {
      display: flex;
    }

    h4 {
      margin-top: 35px;
      margin-bottom: 0;

      @media (min-width: $tablet) {
        margin-top: 60px;
      }

    }

  }

  &-item {
    padding-left: 55px;
    padding-right: 0;
    position: relative;

    @media (min-width: $tablet) {
      flex-basis: 25%;
      padding-left: 0;
      padding-right: 20px;
    }

    &:last-child {
      .item-line {
        display: none;
      }
    }
  }
}

.item {
  &-circle {
    height: 40px;
    width: 40px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: $white;
    border: 2px solid $supporting-3;
    border-radius: 100%;
    cursor: pointer;
  }

  &-line {
    width: 2px;
    height: 100%;
    background-color: $supporting-3;
    z-index: 0;
    position: absolute;
    top: 40px;
    left: 20px;

    @media (min-width: $tablet) {
      width: 100%;
      height: 2px;
      top: 20px;
    }

  }

  &-content {
    padding-right: 20px;
  }
}

.item-circle.checked, .box.checked, .timeline-item.checked .item-circle {
  background-color: $faint-green;
  color: $white;
}

.form-item-profile-household-baseline-data-field-have-stove-range-und,
.form-item-profile-household-baseline-data-field-stove-hours-week-und,
.form-item-profile-household-baseline-data-field-how-often-stovetop-und,
.form-item-profile-household-baseline-data-field-gas-electric-oven-und,
.form-item-profile-household-baseline-data-field-oven-hours-week-und,
.form-item-profile-household-baseline-data-field-often-oven-und,
.form-item-profile-household-baseline-data-field-gas-electric-stovetop-und,
.form-item-profile-household-baseline-data-field-dryer-type-und,
.form-item-profile-household-baseline-data-field-water-heater-type-und,
.form-item-profile-household-baseline-data-field-central-heating-type-und,
.form-item-profile-household-baseline-data-field-portable-electric-heater-und,
.form-item-profile-household-baseline-data-field-air-conditioning-und,
.form-item-profile-household-baseline-data-field-has-pool-und,
.form-item-profile-household-baseline-data-field-heat-home-winter-und,
.form-item-profile-household-baseline-data-field-hot-tub-heat-type-und,
.form-item-profile-household-baseline-data-field-hot-tub-always-on-und,
.form-item-profile-household-baseline-data-field-pool-pump-efficient-und,
.form-item-profile-household-baseline-data-field-pool-has-cover-und,
.form-item-profile-household-baseline-data-field-pool-heat-type-und,
.form-item-profile-household-baseline-data-field-heat-pool-months-und,
.form-item-profile-household-baseline-data-field-do-you-heat-your-pool-und,
.form-item-profile-household-baseline-data-field-select-ways-und,
.form-item-profile-household-baseline-data-field-do-you-have-a-vehicle-und,
.form-item-profile-household-baseline-data-field-dryer-location-und,
.form-item-profile-household-baseline-data-field-garbage-service-type-und,
.form-item-profile-household-baseline-data-field-garbage-can-size-und,
.form-item-profile-household-baseline-data-field-have-solar-panels-und,
.form-item-profile-household-baseline-data-field-has-utility-12-und,
.form-item-profile-household-baseline-data-field-has-solar-panels-und,
.form-item-profile-household-baseline-data-field-has-green-electric-und,
.form-item-profile-household-baseline-data-field-how-you-pay-und,
.form-item-profile-household-baseline-data-field-does-your-home--und,
.form-item-profile-household-baseline-data-field-do-you-rent-your-home-und,
.form-item-profile-household-baseline-data-field-which-home-energy-use-est-und,
.field-name-field-have-solar-panels,
[class*="field-how-often-charge-home-und"],
[class*="field-vehicle-charge-at-home-und"],
[class*="field-vehicle-type-und"] {
  label, select, .select2, input[type="checkbox"] {
    display: none;
  }
}

#slider-1 {
  margin-top: 20px;
}

span.ui-slider-tick-mark {
  display: inline-block;
  height: 10px;
  position: absolute;
  top: 20px;
  transform: translateX(-50%);

  span {
    font-size: 14px;
  }
}

.profile-collapsible {
  margin: 30px 0;
  font-size: 16px;

  .billing-url-prefix {
    display: none;
  }

  #utilityapi-redirect-placeholder {
    margin: 10px 0;
  }
}

label[for="edit-profile-household-baseline-data-field-solar-panels-percentage-und"],
label[for="edit-profile-household-baseline-data-field-has-green-electric-und"] {
  font-size: 20px !important;
  font-weight: bold !important;
}

.timeline-item.checked {
  background-color: $white;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.energy-profile__content form > div {
  .tab-controls, .form-actions {
    // display: none;
  }
}

tr.even, tr.odd {
  background-color: $white;
  padding: 20px;
}

.field-name-field-vehicles {
  h2 {
    margin-top: 30px;
  }
}

.energy-profile {
  #edit-save {
    display: none;
  }
}

#edit-profile-household-baseline-data-field-have-solar-panels {
  display: none;
}

.energy-profile .field-name-field-vehicle-electric-acquired {
  padding: 40px 0;
  border-top: 2px solid $background-gray-2;

  legend {
    display: block;
    padding: 0;
    margin: 0 0 20px;
    font-weight: 500;
  }

  .select2-container .select2-selection--single {
    margin-bottom: 0;
  }

  label {
    font-weight: 300;
  }
}

.field-name-field-car-share-miles {
  .wizard-help {
    ul {
      margin: 0;
      display: flex;
      flex-direction: column;
    }

    & {
      align-items: flex-start;
    }

    li {
      margin-top: 5px;
    }
  }
}

.form-item-profile-household-baseline-data-field-heat-pool-months-und {
  .box-calendar {
    i {
      display: none;
    }
  }
}

.natural-gas-use, .electricity-use, .fuel-oil-use, .propane-use {
  background-color: $background-gray-2;
  padding: 30px;
  border-radius: 5px;
  margin: 30px 0;

  > .fieldset-wrapper {
    .field-type-datetime {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
}

.group-estimator {
  display: none;

  &.active {
    display: block;
  }
}

.natural-gas-use, .electricity-use, .fuel-oil-use, .propane-use, .group-hand-entry {
  > legend {
    display: initial !important;
    border-radius: 5px;

    > span {
      padding: 10px 10px 10px 20px;
      margin: 0 !important;
      background: $white !important;
    }
  }
}

[id^="profile2-edit-household-baseline-data-form"] {
  margin: 0 0 0 100px;

  @include desktop() {
    margin: 0;
  }

  span.fieldset-legend {
    font-size: 18px;
    margin: 15px 0;
    display: block;
    font-weight: bold;
    background-color: transparent;
  }

  > div {
    > h3 {
      display: none;
    }
  }
}

#edit-profile-household-baseline-data-field-select-ways-und {
  margin: 15px 0;

  > div {
    margin-bottom: 10px;
  }
}

table[id^="field-vehicles-values"] {
  > tbody {
    > tr {
      > td {
        background-color: $background-gray-2;
        padding: 22px;
        border-bottom: 1px solid $supporting-2;
      }
    }
  }
}

.slider-wrapper {
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: 30px;
}

.wizard-type-slider .slider-wrapper {
  margin-left: 0;

  @media (min-width: $laptop) {
    margin-left: 15px;
  }

}

.field-name-field-vehicle-electric-acquired {
  legend {
    display: block !important;
    margin-bottom: 0 !important;
  }
}

.energy-profile-module {
  background: #F3F4F8;
  padding: 20px;
  border: 2px solid #CFD2DC;
  border-radius: 5px;

  h3, h2 {
    font-weight: 700;

    span {
      font-size: 22.4px;
    }
  }

  h2 {
    margin-top: 0;
  }
}

.trigger-estimator {
  color: $highlight-blue;

  &:hover {
    cursor: pointer;
  }
}

.hand-entry {
  p {
    margin-bottom: 15px !important;
  }

  p, strong {
    font-size: 16px !important;
  }

  button {
    margin-bottom: 10px;
  }

  > fieldset {
    > legend {
      display: none !important;
    }
  }

  .energy-profile__heading {
    margin: 0;
    display: none;
  }

  fieldset {
    height: auto !important;
    margin: 0;
    padding: 0;

    legend {
      position: relative;
      left: 0;

      span {
        padding-left: 0 !important;
      }
    }
  }

  .fieldset-legend {
    background: #f3f4f8 !important;

    a {
      color: $text-color !important;
    }

    &::after {
      content: '+';
    }
  }
}

.group-hand-entry-fields {
  display: none;

  &.active {
    display: block;
  }
}

#edit-profile-household-baseline-data-field-how-big-house-und-0-value {
  width: auto;
}

.fieldset-group-utilities > .fieldset-wrapper {
  .form-type-select {
    label {
      font-weight: bold;
    }

    .select2-selection--single {
      border: 3px solid $highlight-blue;
    }
  }

  #edit-profile-household-baseline-data-field-utility-gas {
    margin-top: 40px;
  }

  #edit-profile-household-baseline-data-field-propane-use {
    margin: 20px 0 0;

    label {
      font-weight: 700;
    }
  }
}
