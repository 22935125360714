.modal-picture {

  .close-wrapper {

    @media (min-width: $tablet) {
      width: 28px;
      height: 28px;
    }

  }

}
