.page-node-add-city-administrator-contact {

  .block-system {
    // background-color: rgba(207, 210, 220, .2);

    > .content {
      @include new-container;
    }

  }

}
