.page-my-community {

  main.container {
    padding-left: 0;
    padding-right: 0;
  }

  .remodal div[class*="modal-event-node-"] {
    padding: 30px 20px 0;

    p {
      margin-top: 0;

      &:last-child {
        margin-bottom: 0;
      }

    }
  }

}

.modal-community-rank {
  .item-list {
    > ul {
      margin: 20px;
    }
  }
}
