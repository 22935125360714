.triggerToTop {
  display: none;
  width: 40px;
  height: 40px;
  z-index: 50;
  position: fixed;
  bottom: 10px;
  right: 10px;

  span {
    display: block;
    width: 100%;
    height: 100%;
    background: url("../images/icon-arrow-top.svg") no-repeat center white;
    background-size: contain;
    border-radius: 50%;
  }

}

// Show only in certain pages.
body.node-type-team,
body.page-my-community-group,
body.page-my-community,
body.page-user.not-front {

  .triggerToTop {
    display: inline-block;
  }

}
