.node-type-resource {

  .page-title-wrapper > .container {
    @include new-container;

    h1 {
      margin: 1em 0;
    }

  }

}
