.select2-container {
  width: auto !important;
  outline: none;
  z-index: 999;

  * {
    outline: none;
  }

  .select2-selection {
    background: transparent;

    &.select2-selection--single {
      border: none;
    }

    .select2-selection__rendered {
      min-width: 100px;
      padding-left: 0;
      padding-right: 28px;
    }

    .select2-selection__arrow {
      height: 25px;
    }

  }

  .select2-dropdown {
    border: none;
    border-radius: 0;
    background: white;
    box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.7);
  }

  .select2-results {
    width: 100%;
    font-size: 14px;
    line-height: 1.5;
  }

  .select2-results__option {
    padding: 6px 10px;

    &[aria-selected="true"] {
      color: white;
      background: #6DA3D9;
    }

    &:hover {
      background: $light-blue;
    }

  }

}

select[readonly].select2-hidden-accessible + .select2-container {
  pointer-events: none;
  touch-action: none;

  .select2-selection {
    background: #eee;
    box-shadow: none;
  }

  .select2-selection__arrow,
  .select2-selection__clear {
    display: none;
  }
}

// Select dropdown must show on modals.
body > .select2-container {
  z-index: 99999;
}
