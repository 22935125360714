.change-background {
  color: #FFF;
  font-size: 1.5em;
  background: $supporting-2-not-transparent-4;
  cursor: pointer;
  float: left;
  border-radius: 4px;
  padding: 5px;
  margin: 10px;

  @media (min-width: $laptop) {
    float: right;
  }

  &:hover {
    opacity: .7;
  }

}
