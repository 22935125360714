.node-type-page {
  .panels-sections-3col {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;

    .main-content {

      > .hero-pane .pane-page-title {
        background: $background-blue;
        margin-bottom: 30px;

        .pane-content {
          @include global-container;
          & {
            padding-top: 50px;
            padding-bottom: 50px;
          }

          @include breakpoint($tablet) {
            padding: 50px ($global-padding-desktop * 2.5);
          }

          .container {
            color: white;
            padding: 0;
            margin: 0;
          }

        }

      }

      > .container-fluid {
        @include global-container;

        @include breakpoint($tablet) {
          padding: 0 ($global-padding-desktop * 2.5);
        }
      }

    }

    ul {
      font-size: $global-font-size;
    }
  }
}
