.view-community-projects {

  .view-content > div {
    @include box;
    padding: 20px;
    margin: 0 0 20px;
  }

  h2 {
    font-size: 16px;
    line-height: 1.3;
    margin: 0 0 20px;

    a {
      @include link;
      color: black;

      &:hover {
        color: $light-blue;
      }

    }

  }

  .views-field-nothing .field-content {
    display: flex;

    .image {
      max-width: 240px;

      img {
        display: inline-block;
        width: 100%;
        height: auto;
        padding-right: 20px;
      }

    }

    .body {
      width: 100%;
      flex-grow: 2;

      p {
        margin-top: 0;
      }

    }
  }

}
