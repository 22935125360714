.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-right-responsive {
  float: none;
  margin-bottom: 1em;

  @media (min-width: $mobile-l + 1) {
    float: right;
    margin-left: 1em;
    margin-bottom: 1em;
  }
}

.float-left-responsive {
  float: none;
  margin-bottom: 1em;

  @media (min-width: $mobile-l + 1) {
    float: left;
    margin-right: 1em;
    margin-bottom: 1em;
  }
}

.align {
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
  &-left {
    text-align: left;
  }
}
