.panels-sections-3col {

  > .has-sidebar {

    @include breakpoint($laptop) {
      display: flex;
      width: 100%;
    }

    > .container-fluid {
      position: relative;

      @include breakpoint($laptop) {
        flex-basis: 70%;
        flex-shrink: 0;
      }

      .pane-user-dashboard-header,
      .pane-user-pub-dashboard-header {
        @include center-flex;

        height: 250px;
        margin: 0;

        @include breakpoint($laptop) {
          height: 200px;
        }
      }

      > div {
        padding: 0;
      }

      .container {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }

    }

    .layout-sidebar {
      background-color: $background-gray-4;
      display: none;
      padding: 20px;

      @include breakpoint($laptop) {
        display: block;
        flex-basis: 30%;
        max-width: 30%;
        min-width: 30%;
        padding: 0;

        > div {
          margin: 20px;
        }
      }
    }
  }

}

.my-savings {
  margin-bottom: 15px !important;
  margin-top: 60px !important;
  width: 100% !important;
}
