// Sidebar configuration.
$sidebar-width-m: 241px;
$sidebar-width-d: 60px;

.sidebar {
  display: none;
  z-index: 2;
  background-color: $light-blue;
  flex-direction: column;
  position: fixed;
  width: $sidebar-width-d;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: auto;
  justify-content: flex-start;

  @include breakpoint($laptop) {
    justify-content: space-between;
  }

  .fa-bars, .fa-arrow-left {
    cursor: pointer;
    transition: opacity .3s ease-in;
  }

  .fa-bars {
    transform: scaleX(1.3);
  }

  &, a, i {
    color: $white;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .share-progress {
    margin-top: 15px;

    a {
      background: linear-gradient(105.52deg, #1AA317 4.95%, #26EE9A 130.78%);

      &.active {
        background: none;
      }
    }

    i {
      color: #FFF;
      font-size: 20px;
    }
  }

  .image {
    @include center-background;
    border-radius: 100%;
    margin: 0 auto;
    position: relative;

    &-add {
      @include center-flex;

      position: absolute;
      background-color: $white;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      display: none;
      box-shadow: 0px 4px 8px 0px $background-gray-1-transparent-1;

      i {
        color: $text-color;
      }
    }

    &:hover {
      .image-add {
        display: flex;
        cursor: pointer;
      }
    }
  }

  &__meta {
    @include center-flex;

    width: 100%;
    padding: 3vh 0 0;
    flex-direction: column;
  }

  h3 {
    font-size: 20px;
    margin: 20px 0 0;
    font-weight: 100;
  }

  header {
    border-bottom: 2px solid $white;
  }

  li {
    padding: 0;
    margin: 0;
  }

  a {
    @include center-flex;

    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 5px;
    justify-content: flex-start;

    &:hover, &:focus {
      text-decoration: none;
      background-color: darken($light-blue, 5%);
    }
  }

  &-shown.logged-in {
    .sidebar {
      display: none;
      width: $sidebar-width-d;

      @include breakpoint($laptop) {
        display: flex;
        overflow: hidden;
      }

      a {
        @include center-flex;
        font-size: 16px;
        margin: .5vh 10px;

        i {
          font-size: 1.125em;
          margin: 0;
          width: auto;
        }

        span {
          display: none;
          font-size: 16px;
        }
      }

      .share-progress a {
        margin: 0;
      }

      h3 {
        display: none;
      }

      .image {
        height: 40px;
        width: 40px;
      }

      .fa-arrow-left {
        display: none;
        opacity: 0;
        visibility: hidden;
        margin: 0;
      }

      .fa-bars {
        @include toggle-spacing;

        opacity: 1;
        visibility: visible;
      }

      &__site-links, &__user-links {
        margin-top: 10px;
      }
    }

    @include breakpoint($laptop) {
      .page > .content {
        margin-left: $sidebar-width-d;
      }
    }

    &.sidebar-expanded {
      .sidebar {
        display: flex;
        width: $sidebar-width-m;

        .fa-bars {
          opacity: 0;
          visibility: hidden;
          margin: 0;
        }

        .fa-arrow-left {
          @include toggle-spacing;

          opacity: 1;
          visibility: visible;
        }

        .sidebar-toggle {
          display: none;

          @include breakpoint($laptop) {
            display: block;
          };
        }

        .image {
          height: 80px;
          width: 80px;
        }

        a {
          font-size: 14px;
          justify-content: left;
          margin: 0 10px;
          padding: 10px 20px;

          i {
            margin-right: 10px;
            width: 22px;
          }

          span {
            display: inline;
            font-size: 16px;
          }
        }

        h3 {
          display: block;
        }

        &__site-links, &__user-links {
          margin-top: 10px;
        }

        &__user-links {
          margin-bottom: 10px;
        }
      }

      .page > .content {
        margin-left: $sidebar-width-m;
      }

      .tippy-tooltip {
        &.white-theme {
          display: none !important;
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;

    @media (min-width: $tablet) {
      margin-bottom: 35px;
    }

    &--name {
      text-align: center;
    }
  }

  &-bottom {
    border-top: 2px solid $white;
  }

  &__user-links--submenu {
    .fa-chevron-circle-down, .fa-chevron-circle-up {
      margin: 0 0 0 10px;
      font-size: 1em;
    }

    .fa-chevron-circle-up {
      display: none;
    }

    ul {
      background-color: darken($background-blue, 10%);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      margin: 0 10px;

      li {
        display: none;
      }
    }

    &.shown {
      > a {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .fa-chevron-circle-up {
        display: inline;
      }

      .fa-chevron-circle-down {
        display: none;
      }

      ul {
        li {
          display: flex;
        }
      }
    }

    li {
      &, & a {
        margin: 0;
        width: 100%;

        &:hover {
          background-color: darken($light-blue, 1%);
        }

        &:last-child {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
}

.admin-menu {
  .sidebar {
    @media (min-width: $laptop) {
      top: 29px;
      height: calc(100vh - 29px);
    }
  }
}

.tippy-tooltip {
  &.white-theme, &.white-alt-theme {
    background-color: $background-gray-1-transparent-3;
    display: flex;
    align-items: center;

    .tippy-content {
      color: $white;
      font-size: 1em;
    }

    .tippy-roundarrow {
      fill: $background-gray-1-transparent-3;
    }
  }

  &.white-theme {
    height: 36px;
    padding: 0 10px;

    .tippy-roundarrow {
      // Add !important to override style set by JS.
      top: 50% !important;
      transform: translateY(-50%);
    }

    .tippy-content {
      color: $white;
      line-height: 1;
      font-size: 1em;
    }
  }

  &.white-alt-theme {
    padding: 10px 20px;

    .tippy-content {
      line-height: 1.4em;
    }
  }
}
