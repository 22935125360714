.messages {
  word-break: break-word;

  li {
    text-align: left;
  }

}

main > .messages {
  margin: 20px auto 0;

  @media (min-width: $laptop) {
    max-width: 1440px;
  }

}
