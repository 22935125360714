.view-team-members-admin {

  .view-header {
    margin-bottom: 30px;
  }

  .view-content {
    overflow-x: scroll;

    @media (min-width: $tablet) {
      overflow-x: auto;
    }

    table {
      @include tableStyle;
    }

  }

}
