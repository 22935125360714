.disabled-button {
  cursor: default;
  text-decoration: none;
  opacity: .2;

  &:hover {
    text-decoration: none;
  }

}
