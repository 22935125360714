.contextual-item-actions {
  position: relative;

  > button {
    cursor: pointer;
    border: none;
    background: none;
    color: #666666;
    outline: none;

    &:hover {
      color: #000;
    }
  }

  > ul {
    display: none;
    position: absolute;
    margin: 0;
    padding: 0;
    top: 100%;
    right: 0;

    > li {
      $border-color: #e8e8e8;

      &:first-child {
        > * {
          border-top: 2px $border-color solid;
          border-left: 2px $border-color solid;
          border-right: 2px $border-color solid;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }

      &:last-child {
        > * {
          border-left: 2px $border-color solid;
          border-right: 2px $border-color solid;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      > * {
        display: block;
        padding: 10px;
        cursor: pointer;
        white-space: nowrap;
        border-bottom: 2px $border-color solid;
        background: white;
        text-decoration: none;
        color: black;

        a {
          text-decoration: none;
          color: black;
        }

        &:hover {
          background-color: $border-color;
          text-decoration: none;
        }
      }
    }
  }

  &.open {
    > button {
      color: #000;
    }

    > ul {
      display: block;
    }
  }

}