.pane-action-stats-panel-pane-1 {
  @include global-container;
  & {
    position: relative;
  }

  > h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      width: 100%;
      z-index: 1;

      @media (min-width: $laptop) {
        width: auto;
        pointer-events: none;
      }

    }

    a {
      display: none;

      @media (min-width: $laptop) {
        display: inline-block;
      }
    }
  }

  .heading {
    margin: 0;
    visibility: hidden;
    height: 0;
    padding: 0;
    display: none;

    > div {
      display: inline-block;
    }

    .pane-title {
      float: left;
      margin: 0;
    }

  }

  > .pane-content {
    flex-wrap: wrap !important;

    @media (min-width: $laptop) {
      flex-wrap: unset !important;
    }

  }

  .pane-content {
    width: 100%;

    .invite-friend {
      width: 100%;
    }

  }

  .fa-user-plus {
    @include link;
    color: $light-blue;
    font-size: 0;
    text-decoration: none;
    padding: 8px;

    &:hover {
      opacity: .8;
    }

    &:before {
      font-size: 22px;
    }
  }

  .user-progress {

    @media (min-width: $tablet) {
      width: 100%;
    }

    @media (min-width: $laptop) {
      flex-wrap: nowrap;
    }

    > .action-stats-overlay {
      width: 100%;
      flex-basis: auto;

      @media (min-width: $tablet) {
        flex-basis: 70%;
        margin-bottom: 20px;
      }

      @media (min-width: $laptop) {
        width: 100%;
        margin-bottom: 0;
      }

    }

    .action-stats-overlay {

      + .user-progress_item {
        margin: 0;
      }

    }
  }

}
