.view-teams {

  .item-list > ul {
    margin: 0;
    padding: 0;

    li {
      align-items: center;
      border-bottom: 2px solid $background-gray-3;
      display: flex;
      list-style: none;
      margin-bottom: 10px;
      padding: 10px 0 10px 25px;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .views-field-id {
    width: 40px;
    font-weight: bold;
    padding-right: 10px;

    + div {
      width: 50px;
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        @include global-box-shadow;

        width: 100%;
        height: 100%;
        border-radius: 50%;
        vertical-align: middle;
        object-fit: cover;
        border: 1px solid $supporting-2-transparent-2;
      }
    }
  }

  .views-field-title {
    font-size: $global-font-size;
    font-weight: bold;
    padding-left: 20px;
    text-align: left;
    width: 70%;
  }

  .views-field-field-earned-points {
    width: 30%;
    font-weight: normal;
    text-align: right;
    padding-left: 20px;
  }

  #team-standing-total {
    @include see-all-links;

    & {
      padding-right: 20px;
    }
  }

  ul.team-actions {
    li {
      padding: 0;
      display: block;
      border-bottom: none;
      margin: 0 0 5px;
    }
  }
}
