.page-about-join {

  .links-wrapper {
    @include new-container;

    & {
      text-align: center;
      margin-top: 35px;
      margin-bottom: 3em;
    }

    a {
      display: inline-block;
      @include cta($highlight-blue);

      & {
        font-size: 18px;
      }

      &:last-child {
        margin-right: 0;
      }

      &.sign-up {
        text-transform: uppercase;
      }

    }
  }

  .hero-wrapper {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 2em;
    min-height: 25em;
    padding: 3em;
    text-align: center;

    @media screen and (min-width: $tablet) {
      min-height: 35em;
    }

    h1 {
      color: #FFF;
      font-size: 28px;
      font-weight: 400;
      margin-bottom: 11px;
      margin-top: 22px;

      @media screen and (min-width: $tablet) {
        font-size: 42px;
      }
    }

    .gray-shade {
      padding-top: 20px;

      h1 {
        background-color: rgba(85, 94, 103, .3);
        border-radius: 5px;
        display: inline;
        padding: 10px 20px;
      }
    }
  }

}
