.members-block {
  margin-bottom: 50px;

  .content-wrapper {
    flex-wrap: wrap;
    padding: 0;

    @media (min-width: $laptop) {
      width: susy-span(11 of 12);
    }

  }

  .leads-group {
    flex-basis: 100%;
    margin-bottom: 20px;

    @media (min-width: $laptop) {
      margin: 15px 0 30px;
      background-color: $supporting-2-transparent-1;
    }

    @media (min-width: $laptop-l) {
      flex-basis: 50%;
      flex-shrink: 0;
    }

    .wrapper {
      @include box;
      padding: 20px;

      .left {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @media (min-width: $tablet) {
          margin-bottom: 0;
        }

        .user-picture {
          width: 90%;
        }

        .image {
          width: 60px;
          height: 60px;

          a {
            @include link;

            display: inline-block;
            border-radius: 50%;

            &.ctools-use-modal {
              height: 100%;
              width: 100%;
            }

            &:hover {
              opacity: .7;
            }

          }

          img {
            width: 100%;
            height: 100%;
            vertical-align: middle;
            object-fit: contain;
          }

        }

        .text {
          max-width: 300px;
          width: calc(100% - 60px);
          padding-right: 10px;
          margin-left: 20px;
        }

        .position {
          font-size: 1em;
          font-weight: bold;
        }

        .name {
          display: flex;
          flex-wrap: wrap;
          font-size: $h5;

          span {
            flex-basis: 100%;
            text-align: left;
          }
        }

      }

      .right {
        color: $light-blue;
        font-size: 14px;
        padding-left: 80px;

        .phone {
          background: url("../images/icon-phone.svg") no-repeat left center;
          background-size: 14px;
          padding-left: 25px;
          margin-bottom: 5px;
        }

        .mail {
          background: url("../images/icon-mail.svg") no-repeat left center;
          background-size: 16px;
          padding-left: 25px;
        }

      }

    }

    img {
      border-radius: 50%;
    }

  }

  .members-group {
    @include box;
    flex-basis: 100%;
    padding: 20px 20px 0;
    position: relative;

    > ul {
      margin: 0;

      @media (min-width: $tablet) {
        display: flex;
        flex-wrap: wrap;
      }

      li {
        list-style: none;
        padding: 0 0 20px;

        @media (min-width: $tablet) {
          width: 50%;
        }

        @media (min-width: $laptop) {
          width: calc(100% / 4);
        }

      }

    }

    .member {
      display: flex;
      align-items: center;

      .image {
        width: 50px;
        height: 50px;
        margin-right: 10px;

        a {
          @include link;
          display: inline-block;
          border-radius: 50%;

          &.ctools-use-modal {
            height: 100%;
            width: 100%;
          }

          &:hover {
            opacity: .7;
          }

        }

        img {
          @include global-box-shadow;

          width: 100%;
          height: 100%;
          vertical-align: middle;
          object-fit: cover;
          border-radius: 50%;
          border: 1px solid $supporting-2-transparent-2;
        }

      }

      .name {
        display: flex;
        flex-wrap: wrap;
        font-size: 1em;
        max-width: calc(100% - 56px);

        span {
          flex-basis: 100%;
          text-align: left;
        }
      }

    }

    .modal-trigger {
      width: 100%;
      border-top: 1px solid $light-grey;
      text-align: right;
      padding: 20px 0 20px 0;

      a {
        color: $text-color;
        font-size: 14px;
        font-weight: bold;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

}
