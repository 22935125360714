.btn {
  text-align: center;
}

.btn-main {
  @include cta($highlight-blue);
}

.btn-primary {
  @include blue-button;

  &:not(.btn-small) {
    @include wider-button;
  }

  &.btn-small {
    @include small-button;
  }
}

.btn-cancel {
  @include cancel-blue-button;

  &:not(.btn-small) {
    @include wider-button;
  }

  &.btn-small {
    @include small-button;
  }
}

.btn-orange {
  @include cta($background-orange);
}

.btn-green {
  @include solid-button($background-green);
}

.gradient-button {
  &-blue {
    @include h-gradient-button($glacial-blue 5.31%, $sky-blue 84.26%);
  }

  &-green {
    @include h-gradient-button($forest-green 4.95%, $emerald 130.78%);
  }
}
