// Logged.
.logged-in {

}

// Not logged.
.not-logged-in {

}

// Sidebar menu
.sidebar-shown-small {

  &.logged-in {

    .page > .content {
      margin-left: 0;

      @media (min-width: $laptop) {
        margin-left: 60px;
      }

    }

  }

  &.not-logged-in {

    .page > .content {
      margin-left: 0;
    }

  }

}
