.gauge {
  height: 140px;
  width: 140px;
  margin: 0;
  padding: 0;
  background-color: $white;
  position: relative;
  text-align: center;

  .rail {
    stroke: $supporting-2;
  }

  .progress {
    stroke: $background-blue;
  }

  &__inner {
    position: relative;
    display: inline-block;
    transform: rotate(-90deg);
    max-width: 150px;

    circle {
      stroke-width: 12;
    }

    .rail {
      fill: transparent;
    }

    .progress {
      fill: transparent;
      stroke-linecap: round;
    }

    @media (min-width: $laptop) {
      width: 100%;
      height: 100%;
    }
  }

  &__stat {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 14px;

    h5, p {
      margin: 0;
      font-size: 12px;
      text-transform: uppercase;
      font-family: sans-serif;
    }

    p {
      font-weight: 600;
      color: #8C909C;
    }
  }
}
