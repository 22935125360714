.page__header {
  background-color: $white;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000000;

  @include breakpoint($laptop) {
    min-height: 60px;
    justify-content: space-between;
    @include center-flex;
  }

  h2 {
    @include h4;
    & {
      font-weight: 100;
      margin: 0;
      width: 100%;
    }
    @include breakpoint($laptop) {
      padding-left: 40px;
    }
  }

  i {
    cursor: pointer;
    font-size: 1em;
    padding: 15px 13px;

    @include breakpoint($tablet) {
      padding-bottom: 18px;
      padding-top: 18px;
    }

    @include breakpoint($laptop) {
      padding: 20px;
    }
  }

  .fa-bars {
    background-color: $light-blue;
    color: $white;
    margin-right: 10px;

    &:before {
      font-size: 24px;
    }

    @include breakpoint($laptop) {
      display: none;
    }
  }

  .logo {
    width: 100%;

    a {
      padding: 0;

      + img {
        max-height: 60px;
        height: 100%;

        @include breakpoint($tablet) {
          height: 40px;
        }

      }

    }

    img {
      width: auto;
      height: 40px;
      vertical-align: top;
    }

  }

  .sidebar-expanded & {
    nav {
      @include breakpoint($laptop, true) {
        display: none;
      }
    }
  }

  nav {
    display: flex;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: 15px;

    .team {
      display: none;

      @media (min-width: $laptop) {
        display: flex;
        right: 20px;
      }

      a {
        display: inline-block;
        color: $background-gray-1;
        white-space: nowrap;
        padding: 0 10px;

        &:hover, &:focus {
          text-decoration: none;
        }

      }
    }

    .search-block {
      display: none;
      background: white;

      @include breakpoint($tablet) {
        display: block;
      }

    }

  }

  ul {
    margin: 0;
    display: flex;
  }

  li {
    @include center-flex;
  }

  a {
    color: $background-gray-1;
    padding: 0 10px;

    &:hover, &:focus {
      text-decoration: none;
    }

    &.no-padding {
      padding: 0;
    }
  }

  .top_search {
    display: none;

    .container-inline {
      display: flex;
      align-items: center;
    }

    .element-invisible {
      display: none;
    }

    .form-item {
      margin: 0;
    }

    input {
      font-size: 14px;
      padding: 6px;
      margin: 0;

      &:focus {
        outline-color: $highlight-blue;
      }

    }

    &.visible {
      display: block;
    }

    .form-actions input[type=submit] {
      border-radius: 0;
      padding: 8px 15px;
      outline: none;
      margin: 0;
    }

  }
}

// Admin menu should not be hidden by the header
body.admin-menu .page__header {
  z-index: 900;
}
