.view-my-community-posts {

  .view-content {

    ul {
      margin: 0;

      li {
        @include box;
        padding: 20px;
        margin: 0 0 20px;
      }

    }

  }

  table {
    width: 100%;

    tbody tr {

      &:nth-child(odd) {
        background: $background-gray-2;
      }

    }

    th {
      font-size: 18px;
      padding: 0 10px;
    }

    td {
      font-size: 16px;
      padding: 10px;
    }

  }

}
