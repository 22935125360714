.comments-feed-loader {
  text-align: center;

  img {
    max-width: 150px;
  }
}

.comments-feed-container {

  .comments-title {
    line-height: 1;
    margin-top: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .filters {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-right: 20px;
      flex-wrap: wrap;

      > div {
        border-radius: 5px;
        line-height: 1;
        padding: 5px;
        margin: 0;
        white-space: nowrap;
        cursor: pointer;

        &.active {
          text-decoration: underline;
        }

        &:last-child {
          margin-right: 0;
        }

      }

    }

    .sort {
      font-size: 14px;
      line-height: 21px;

      .select2 {
        margin-bottom: -2px;
      }

      .select2-container .select2-selection--single .select2-selection__rendered {
        padding-right: 20px;
      }

    }

  }

  .view-filters {
    display: none;
  }

  .view-header {
    display: none;
  }

}
