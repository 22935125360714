.page-user-password {

  .block-system {
    background: $highlight-blue;

    > .content {
      @include new-container;
    }

  }

}
