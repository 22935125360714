.tipped-dot-relative-wrapper {

  &.tip_basic_household_activities {
    display: block;
    width: 100%;

    > span {
      top: -14px;
      left: 75%;

      @include breakpoint($laptop) {
        top: -10px;
        left: 82%;
      }
    }
  }

  &.tip_complete_your_profile {
    > span {
      top: -10px;

      @include breakpoint($tablet) {
        top: -29px;
        left: 32px;
      }

      @include breakpoint($laptop) {
        top: -29px;
        left: 180px;
      }
    }
  }
}
