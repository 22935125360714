.search-form {
  padding-top: 50px;
  padding-bottom: 30px;

  .container-inline.form-wrapper {
    display: flex;
    align-items: flex-end;
  }

  input[type="submit"] {
    @include cta($highlight-blue);
    & {
      border: none;
    }
  }

  .form-type-checkbox {
    @include checkbox;
  }

}
