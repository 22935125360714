.field-name-field-action-question-answers {

  .question {
    padding: 0 !important;
    margin-top: 10px;

    .field-prefix,
    .field-suffix {
      font-size: 14px !important;
      display: inline-block;
    }

    input {
      max-width: 65px;
      color: $white;
      font-size: 15px !important;
      font-family: 'Poppins', sans-serif;
      text-align: center;
      background: $highlight-blue;
      border: none;
      padding: 3px 0;
      margin: 0 2px !important;
    }

  }

}
