.page-node-manage-people {

  .section-one {
    background: $highlight-blue;

    .col-sm-8,
    .col-sm-4 {
      float: none;
      padding: 0;
    }

    .col-sm-8 {
      width: 100%;
    }

    .col-sm-4 {
      width: auto;
    }

    .row {
      margin-left: 0;
      margin-right: 0;
    }

    .pane-team-header {
      @include new-container;

      @media (min-width: $laptop) {
        padding-top: 40px;
      }
      & {
        color: white;
        padding-top: 40px;
      }
    }

    .team-rank {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin-top: 10px;

      h2,
      a {
        display: inline-block;
      }

      h2 {
        font-size: 22px;
        margin: 0;
      }

      a {
        width: 20px;
        height: 20px;
        background: $supporting-2;
        margin-left: 8px;

        span {
          font-size: 18px;
        }

      }

    }

    .team-info {

      .field-name-field-team-picture,
      .team-name {
        text-align: center;
      }

      .field-name-field-team-picture {

      }

      .team-name {
        margin: 0 0 15px;
      }

      .edit-settings {

        a {
          color: white;
        }

      }

    }

    .action-buttons {
      display: inline-block;

      a {
        color: white;
      }

    }

  }

  .section-two {

    .container {
      @include new-container;
    }

  }

}
