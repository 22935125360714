#user-pass {
  padding: 50px 0;

  .form-item {
    margin-bottom: 30px;

    label {
      color: white;
      font-size: 18px;
      margin-bottom: 8px;
    }

    input {
      box-shadow: 0 10px 10px 0 rgba(207, 210, 220, .2);
      transition: all .4s;
      border: none;
      outline: none;
      padding: 10px;
    }

  }

  .form-actions {

    input {
      color: $text-color;
      font-size: 18px;
      background: rgba(207, 210, 220, .2);
      border: 2px solid $supporting-2;
      text-align: center;
      border-radius: 5px;
      transition: all .4s;
      cursor: pointer;
      padding: 15px 30px;

      &:hover,
      &:focus {
        background: lighten($supporting-2, 10%);
      }

    }
  }

}
