.modal-search {

  form {
    padding-right: 10px;
  }

  .form-item input {
    width: 100%;
    color: $text-color;
    font-size: 1em;
    border: 1px solid $background-gray-1;
    outline: none;
    transition: all .4s;
    padding: 10px;

    &:focus {
      border: 1px solid $text-color;
    }


  }

}
