.cke_editable {
  font-size: $p;
  line-height: 1em;
  padding: 10px;

  ul {
    @include unordered-list;
  }

  li {
    + li {
      margin-top: 0.25rem;
    }
  }
}
