// All "energy-profile/" wizard page styling.

// Variable settings.
$heading-size: 1.4em;
$icon-size: 50px;
$navigation-box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.15);
$navigation-size: 60px;
$scroll-bar-size: 15px;
$wizard-box-spacing: 30px;

.energy-profile {
  h1 {
    color: $white;
    margin-bottom: 15px;

    @media (min-width: $laptop) {
      color: $text-color;
    }
  }

  h2 {
    margin-bottom: 10px;
  }

  h1, h2 {
    font-size: $heading-size;
  }

  p {
    margin: 0;
  }

  &-progress {
    margin-top: $global-padding-desktop;
    padding: 0 $global-padding-desktop;

    @media (min-width: $laptop) {
      padding: 0;
    }

    ul {
      display: flex;
      justify-content: space-between;
      position: relative;

      &:before {
        border: 2px solid $background-gray-1-transparent-4;
        content: '';
        height: 2px;
        left: 50%;
        margin-top: 9px;
        position: absolute;
        transform: translateX(-50%);
        width: calc(100% - 50px);
        z-index: -1;
      }

      @media (min-width: $tablet) {
        margin-bottom: 2 * $global-padding-desktop;

        &:before {
          height: 4px;
          margin-top: 18px;
          width: calc(100% - 110px);
        }
      }

      li {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 0;
        position: relative;

        @media (min-width: $tablet) {
          font-size: $global-font-size;
        }

        &:not(.current) {
          cursor: pointer;
        }

        &:before {
          background-color: $white;
          border: 2px solid $background-gray-1-transparent-4;
          border-radius: 50%;
          content: '';
          display: block;
          height: 20px;
          margin-bottom: 10px;
          width: 20px;
        }

        &.step-highlighted {
          &:before {
            background-color: $highlight-yellow-2;
          }
        }

        @media (min-width: $tablet) {
          font-size: $global-font-size;
          &:before {
            height: 35px;
            width: 35px;
          }
        }

        &.current {
          &:before {
            background-color: $faint-green;
          }

          &.step-highlighted:before {
            background-color: $highlight-orange-1;
          }

        }

      }
    }
  }

  &__heading {
    background-color: $background-blue;
    color: $white;
    max-width: 1000px;
    padding: 30px 0;

    @media (min-width: $laptop) {
      background-color: $white;
      color: $text-color;
    }
  }

  &__inner-content {
    width: susy-span(10 of 12);
    margin: 0 auto;
  }

  &__intro {
    padding-bottom: $navigation-size;

    @media (min-width: $laptop) {
      display: flex;
      justify-content: space-between;
    }

    &__card {
      padding: 40px 0 35px;
      border-bottom: 1px solid $supporting-3;

      @media (min-width: $laptop) {
        flex-basis: 20%;
        border-bottom: none;
      }

      .energy-profile__inner-content {
        @media (min-width: $laptop) {
          width: 100%;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__content {
    font-size: $global-font-size;
    @include mobile-first() {
    }

    @media (min-width: $laptop) {
      width: susy-span(10 of 12);
      margin: 0 auto;
      padding-bottom: 200px;
      overflow: auto;
    }
  }

  i {
    font-size: 36px;
    color: $text-color;
  }

  &__graph {
    // Due a business decision, the header right now must be hidden.
    display: none !important;
    @include center-flex;

    height: 200px;
    background-color: $background-gray-2;
    border-bottom: 2px solid $supporting-2;

    .pane-c02free-user-user-impact-co2 {
      flex-basis: susy-span(10 of 12);
      margin: 0;
      border: none;

      > .pane-content {
        h2 {
          display: none;
        }

        .stat-icons {
          .electric {
            background-color: $highlight-yellow-2;
          }

          .airline {
            background-color: $highlight-blue;
          }

          .food {
            background-color: $highlight-green;
          }

          .vehicles {
            background-color: $highlight-yellow-1;
          }

          .gas {
            background-color: $background-gray-1-transparent-1;
          }
        }

        .graph-baseline {
          margin: 0;
          padding: 0;
          box-shadow: none;
          border: none;
        }
      }
    }

    ul {
      display: none;
    }
  }

  &__navigation {
    @include full-width;

    @include mobile-first {
      width: 100vw;
      left: 50%;
    }

    & {
      width: calc(100vw - 60px);
      left: calc(50% + 60px);
      position: fixed;
      bottom: 0;
      background: $white;
      padding: 15px 0;
      border-top: 2px solid $supporting-2;
      background-color: $background-gray-2;
      z-index: 100000;
    }
    @media (min-width: $laptop) {
      padding: 30px 0;
    }

    ul {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      width: susy-span(10 of 12);
    }

    .fa-arrow-left {
      margin-right: 5px;
    }

    .fa-arrow-right {
      margin-left: 5px;
    }

    li {
      span {
        display: none;
      }

      &.submit {
        font-size: 0;

        span {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          margin-right: 5px;
        }
      }

      .fa-check {
        margin-left: 5px;
      }
    }
  }

  button, input[type="submit"] {
    @include mobile-first() {
      padding: 10px;
    }

    & {
      appearance: none;
      border: none;
      background-color: $faint-green;
      color: $white;
      cursor: pointer;
      padding: 10px 20px;
      border-radius: 5px;
    }
    i, & {
      color: $white;
      font-size: 16px;

      @include desktop() {
        font-size: 1em;
      }
    }

    &:hover {
      background: $faint-green;
    }
  }

  input[type="submit"] {
    background-color: $background-blue;
  }

  #edit-submit {
    display: none;
  }

  input[type="text"],
  input[type="number"] {
    margin: 0;
    padding: 8px 15px;
    appearance: none;
    font-size: 1em;
    border-radius: $welcome-border-radius;
    border: 2px solid $background-gray-2;

    @media (min-width: $laptop) {
      margin: 10px 0;
    }

    &.form-error {
      border-color: $error-border;
      outline: none;
    }
  }

  .error-description {
    color: $error-border;
  }

  select.form-error,
  select.form-error.select2-hidden-accessible + .select2-container {
    border-radius: $welcome-border-radius;
    border: 2px solid $error-border;
  }

  .group-hand-entry {
    input[type="text"] {
      width: auto;
    }
  }

  label, h3, p {
    font-weight: 300;
    font-size: $global-font-size;
  }

  label {
    font-weight: 500;
  }

  h1, h2, h3 {
    font-weight: 500;
  }

  .wizard-element.form-error {
    padding: 10px;
    border: 2px solid $error-border;
    border-radius: $welcome-border-radius;
  }

  .wizard-type-box, .wizard-type-timeline {
    .box-wrapper, .timeline-wrapper {
      margin-top: 20px;

      p {
        font-size: $small-text;
      }
    }

    > h3 {
      font-weight: 500;
      margin-bottom: 0;
      max-width: 1000px;

      & + .wizard-description {
        margin-bottom: 20px;

        & + .box-wrapper, & + .timeline-wrapper {
          margin-top: 0;
        }
      }
    }
  }

  .wizard-type-box {
    flex-basis: 100%;
  }

  .wizard-type-normal {
    flex-basis: 100%;

    h2 {
      margin-top: 0;
    }

    input + span {
      display: block;
      margin-top: 4px;

      @media (min-width: $laptop) {
        display: inline-block;
        padding-left: 10px;
        margin-top: 0;
      }

    }

    > h3 {
      max-width: 1000px;
    }
  }

  .wizard-description {
    white-space: pre-wrap;
    margin-bottom: 15px;
    max-width: 1000px;

    @media (min-width: $laptop) {
      margin-bottom: 0;
    }

  }

  .field-name-field-what-kind-car {
    padding-bottom: 30px;

    input[type=text] {

      @include mobile-first() {
        width: 100% !important;
      }

    }

  }

  .field-name-field-vehicles {
    padding-bottom: 20px;
  }

  .select2-container .select2-selection--single {
    padding: 15px 30px 15px 15px;
    border-radius: $welcome-border-radius;
    appearance: none;
    font-size: 1em;
    border: 2px solid $supporting-2;
    margin: 10px 0;
    margin-right: 10px;
    line-height: 1;
    position: relative;
    height: auto;
    background-color: $white;

    > span {
      padding: 0;
      margin: 0;
      line-height: 1;
    }
  }

  .container-inline-date .date-padding {
    padding: 0;
    float: none;
  }

  .select2-container .select2-selection--single .select2-selection__arrow {
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
  }

  th.field-label {
    font-size: 1.4em;
  }

  .field-type-field-collection {
    .energy-profile__heading {
      display: none;
    }

    tbody, tr, td, th {
      border: none;
    }

    tbody tr:not(:last-child) {
      border-bottom: 10px solid $white;
    }

    .draggable {
      > td {
        background-color: $background-gray-2;
        border-radius: 0;
        border: none;
        padding: 0;
        margin: 0;
      }
    }

    .field-multiple-drag {
      display: none;
    }

    tr {
      display: none;

      &.active {
        display: table-row;
        padding: 0;
      }
    }
  }

  th {
    label {
      margin: 0 0 10px;
    }
  }

  .tabledrag-toggle-weight-wrapper, .field-add-more-submit {
    display: none;
  }

  .field-name-field-how-often-charge-home {
    .timeline-item {
      flex-basis: auto;
      width: 25%;
      white-space: normal;
    }
  }

  .wizard-type-wrapper {
    border-top: 2px solid $background-gray-2;
    padding: 20px 0;

    h3 {
      margin-top: 0;
      margin-bottom: 7px;
      font-size: 20px;
      font-weight: bold;
      white-space: normal;

      @media (min-width: $laptop) {
        margin-bottom: 0;
      }

    }
  }

  .profile-section {
    > .form-wrapper {
      > .fieldset-wrapper {
        > .form-wrapper:first-child {
          .wizard-type-wrapper {
            border-top: none;
            padding-top: 10px;
          }
        }
      }
    }
  }

  // Energy profile - Empty/Errored fields
  .profile-highlighted {
    border: 2px solid $highlight-orange-1 !important;
    padding: 10px;
  }

  .profile-highlighted:not(.car-item):not(.box) {
    margin: 10px 0;
  }

  .profile-highlighted.slider {
    padding: 0;
  }

  .profile-text-highlighted {
    label {
      color: $highlight-orange-1;
      font-weight: 600;
    }
  }

  .profile-field-highlighted {
    input[type=text], select {
      border: 2px solid $highlight-orange-1 !important
    }
  }

  .profile-highlighted-label {
    label {
      color: $highlight-orange-1 !important;
      font-weight: 600;
    }
  }

}

#graph {
  width: 500px;
  height: 400px;
}

canvas {
  height: 100%;
  margin-left: -5px;
}

.modal-team, .modal-teams {
  @media (min-width: $laptop) {
    width: 800px;
    height: 600px;
    overflow: hidden auto;
  }
}

.page-profile-wizard, .page-my-energy-profile {
  main {
    width: susy-span(12 of 12);
    padding: 0;
  }

  .select2-container--default .select2-search--dropdown .select2-search__field {
    padding: 15px 30px 15px 15px;
    appearance: none;
    font-size: 1em;
    line-height: 1;
    height: auto;
    margin: 0;
  }

  .select2-search--dropdown {
    padding: 0;
    box-shadow: 0 1px 3px 0 rgba(50, 50, 50, .7);
  }

  .form-item {
    margin: 0;
  }

  .team-list-container {
    margin: 0;
  }

  .view-teams {
    h3 {
      display: none;
    }
  }

  .cta-default {
    // Default CTAs.
    @include cta($highlight-blue);

    & {
      margin-top: 30px;
      display: inline-block;
    }
  }
}

.page-my-energy-profile {
  .energy-profile {
    &__navigation {
      background-color: transparent;
      border: none;
      bottom: -70px;
      left: auto;
      margin: 0;
      padding: 0;
      position: absolute;
      right: auto;
      width: 100%;

      @include desktop() {
        bottom: -170px;
      }

      ul {
        display: flex;
        justify-content: space-between;
        width: auto;
      }
    }
  }
}

.profile-section-inner {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  > div, > input {
    label {
      font-weight: bold;
      font-size: $global-font-size;
    }

    .description {
      margin: 5px 0 15px;
    }
  }
}

.profile-section {
  position: relative;

  @include mobile-first() {
    width: susy-span(10 of 12);
    margin: 0 auto;
  }

  > fieldset {
    min-width: 0;
  }

  .energy-profile__heading {
    @include mobile-first() {
      background: $white;
      color: $text-color;
    }

    & {
      padding: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    p {
      font-size: $global-font-size;
    }

    h2 {
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 10px;
    }

    & + .form-wrapper {
      > legend {
        display: none;
      }
    }
  }

  > div {
    > label, > div > label {
      font-weight: bold;
      font-size: $global-font-size;
    }

    > div > label {
      margin-top: 30px;
    }

    .description {
      margin: 5px 0 15px;
    }
  }

  .energy-profile__navigation--button {
    padding: 2px;
  }

  &[data-order="1"] {
    .energy-profile__navigation--button {
      &[data-navigation="previous"] {
        visibility: hidden;
      }
    }
  }

  &[data-order="6"] {
    .energy-profile__navigation--button {
      &[data-navigation="next"] {
        visibility: hidden;
      }
    }
  }
}

.pge-data-form {
  display: none;
}

.profile-wizard-page {
  margin-top: 60px;
}

.page-profile-wizard {
  .select2-search {
    position: static !important;
    left: initial !important;
  }

  .energy-profile__heading {
    padding-bottom: 30px;
    border-bottom: 2px solid #F3F4F8;
    margin-bottom: 0;
  }
}

#edit-neighborhood-intro, #edit-community-intro, #edit-team-intro {
  + div {
    margin-top: 30px;
  }
}

tr.draggable.active {
  > td {
    padding: 30px !important;
    border-radius: 5px !important;

    @include mobile-first() {
      padding: 50px 15px 15px !important;
    }

  }

  input[type="text"],
  input[type="number"] {
    width: auto;
  }
}

#has-group {
  margin-top: 30px;
  font-size: $global-font-size;
}

.field-name-field-community-logo {
  #team-name {
    display: none;
  }
}

#edit-profile-household-baseline-data-field-how-big-house-und-0-value {
  display: none;
}

#how-big-house {
  width: auto;
  margin-top: -15px;
  margin-bottom: 25px;
}

[id*="residency-validation-form"] {
  padding: 30px 0 !important;

  label {
    font-size: 16px;
  }

  input[type="text"] {
    margin: 10px 0;
    padding: 15px !important;
    border-radius: $welcome-border-radius !important;
    width: 100% !important;
    appearance: none !important;
    font-size: 1em !important;
    border: 2px solid $background-gray-2 !important;
    outline: 0 !important;
  }
}

#edit-verification-intro {
  + p {
    margin-top: 15px;
    font-size: 16px;
  }
}

.group-utilities-wrapper.only-gas {

  .energy-profile__heading {
    margin-bottom: 0;

    h2 {
      margin-bottom: 0;
    }

  }

  #edit-profile-household-baseline-data-field-utility-gas {
    margin-top: 0;
  }

}
