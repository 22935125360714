.members-community-block {
  @include global-container;

  > .content-wrapper {
    &.open {
      margin-bottom: 0;
    }
  }

  > h2 {
    margin-bottom: 15px;
  }

  .leads-group {

    h3 {
      margin: 0;
    }

    .content-wrapper {
      margin-bottom: 20px;

      > ul {

        @media (min-width: $tablet) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        li {
          margin-bottom: 20px;

          @media (min-width: $tablet) {
            width: 49%;
          }

          .lead {
            @include box;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 30px;
          }

        }

      }

      .left {
        display: flex;
        align-items: center;

        .user-picture {
          width: 90%;
        }

        .image {
          width: 80px;
          padding-right: 10px;

          a {
            @include link;
            display: inline-block;
            border-radius: 50%;

            &:hover {
              opacity: .7;
            }

          }

          img {
            width: 100%;
            height: auto;
            vertical-align: middle;
          }

        }

        .position {
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .name {
          font-size: 18px;
          font-weight: bold;
        }

      }

      .right {
        color: $light-blue;
        font-size: 14px;

        .phone {
          background: url("../images/icon-phone.svg") no-repeat left center;
          background-size: 14px;
          padding-left: 25px;
          margin-bottom: 5px;
        }

        .mail {
          background: url("../images/icon-mail.svg") no-repeat left center;
          background-size: 16px;
          word-break: break-word;
          padding-left: 25px;
        }

      }


      img {
        border-radius: 50%;
      }

    }

    + h3 {
      margin: 0 0 .5em;
    }

  }

  .members-group {

    .team-members {
      @include members-list;
    }

    .modal-trigger {
      @include see-all-links;
    }

  }

  .actions-group {
    text-align: right;
    margin-bottom: 30px;

    @media (min-width: $laptop) {
      margin-bottom: 0;
    }

    a {
      @include cta($highlight-blue);
      & {
        font-size: 14px;
        padding: 10px 20px 10px 45px;
        margin: 0;
        position: relative;
      }

      &:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("../images/icon-leave.svg") no-repeat center;
        background-size: contain;
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        left: 20px;
      }

    }
  }

}
