body.page-actions {

  .modal-default .ctools-modal-content {
    text-align: center;
  }

  main {
    max-width: 100%;
    width: 100%;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  .action-groups {

    > div {
      padding: 0 15px;

      @media (min-width: $laptop) {
        padding: 0;
      }


      &:not(:first-child):not(:last-child) {

        @media (min-width: $laptop) {
          width: susy-span(11 of 12);
          margin-left: auto;
          margin-right: auto;
        }

        @media (min-width: $laptop-l) {
          max-width: 1440px;
          width: susy-span(10 of 12);
        }

      }

      &:first-child {
        background: $background-blue;
        padding: 0;
        margin-bottom: 30px;

        @media (min-width: $tablet) {
          margin-bottom: 0;
        }

        @media (min-width: $laptop) {
          width: calc(100% + 9.3%);
          margin-left: -4.65%;
        }

        @media (min-width: $laptop-l) {
          width: calc(100% + 24%);
          margin-left: -12%;
        }

        h2 {
          color: $white;
          padding: 50px 15px;
          margin: 0 auto;
          position: relative;

          @media (min-width: $tablet) {
            margin: 0 auto 30px;
          }

          @media (min-width: $laptop) {
            width: susy-span(11 of 12);
            padding: 50px 4%;
            margin: 0 auto 42px;
          }

          @media (min-width: $laptop-l) {
            width: susy-span(10 of 12);
            padding: 50px 8%;
          }

          @media (min-width: 1800px) {
            max-width: 1440px;
            padding: 50px 0;
          }

        }

      }

      .btn {
        @include blue-button;
      }

    }

    .actions-list-categories {

      .actions-list-filters-wrapper {
        align-items: center;
        display: flex;
        margin-top: 10px;

        .action-category-filter {
          margin-left: 25px;

          a.btn {
            @include blue-button;
          }
        }
      }

      .actions-list-categories-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -5px;

        @media (min-width: $laptop) {
          width: calc(100% + 10px);
        }
      }

      &-title {
        @include h4;

        & {
          flex-basis: 100%;
          font-weight: bold;
          padding: 0 5px;
          margin-bottom: 25px;
        }
      }

      .action-category {
        flex-basis: 50%;
        overflow: hidden;
        padding: 0 5px 10px;

        @media (min-width: $tablet) {
          flex-basis: 25%;
        }

        @media (min-width: $laptop) {
          flex-basis: 25%;
        }

        &.selected {
          .actions-category-wrapper a {

            &:before {
              border: 10px solid $background-blue;
            }

            span {
              background: $generic-semi-transparent-hover;
              padding: 10px 15px 20px;
            }

          }
        }

        .actions-category-wrapper {
          min-height: 165px;
          height: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          position: relative;

          img {
            max-width: 100%;
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
          }

          a {
            display: inline-block;
            width: 100%;
            text-decoration: none;
            cursor: pointer;
            outline: none;
            margin: 0;

            &:hover span {
              background: $generic-semi-transparent-hover;
            }

            &:before {
              content: "";
              display: inline-block;
              width: 100%;
              height: 100%;
              background: transparent;
              border: 10px solid transparent;
              box-sizing: border-box;
              z-index: 2;
              transition: all .4s;
              position: absolute;
              top: 0;
              left: 0;
            }

            span {
              display: block;
              color: white;
              font-size: 21px;
              font-weight: 700;
              line-height: 24px;
              text-align: center;
              background: $generic-semi-transparent;
              border-bottom: 1px solid rgba(86, 151, 234, 0.5);
              border-top: 1px solid rgba(86, 151, 234, 0.5);
              z-index: 1;
              transition: all .4s;
              padding: 10px 15px;
              position: relative;
            }

          }
        }
      }
    }

  }

  .page {
    overflow: hidden;

    > .content .action-groups > div {
      padding-bottom: 40px;

      @media (min-width: $tablet) {
        padding-bottom: 0;
      }

      &.actions-list-categories {
        @media (min-width: $tablet) {
          padding-bottom: 10px;
        }
      }

      &:first-child {
        padding-bottom: 0;
      }

      &:last-child {
        max-width: 100%;
        width: 100%;
        margin: 0;

        &.all-actions {
          padding-bottom: 20px;
        }

        &:not(.all-actions) {
          padding: 50px 0 40px;

          @media (min-width: $tablet) {
            padding: 30px 0 20px;
          }
        }

        > .wrapper {
          margin: 0 auto;

          @media (min-width: $laptop) {
            width: susy-span(11 of 12);
            margin-left: auto;
            margin-right: auto;
          }

          @media (min-width: $laptop-l) {
            max-width: 1440px;
            width: susy-span(10 of 12);
          }

          > h3 {
            padding: 0 15px;
            margin-top: 0;

            @media (min-width: $laptop) {
              padding: 0;
            }

            span {
              background: white;
              border-radius: 12px;
              padding: 4px 16px;
              margin-left: 13px;
            }

          }

          ul {
            padding: 0 15px;

            @media (min-width: $laptop) {
              padding: 0;
            }

            &.actions-list {

              @media (min-width: $laptop) {
                margin-top: 49px;
              }

            }
          }

        }

        > .load-more-wrapper,
        > .actions-list-category,
        > h3,
        > p,
        > ul {
          padding: 0 15px;

          @media (min-width: $laptop) {
            width: susy-span(11 of 12);
            padding: 0;
            margin-left: auto;
            margin-right: auto;
          }

          @media (min-width: $laptop-l) {
            max-width: 1440px;
            width: susy-span(10 of 12);
          }
        }

        > .actions-list-category {

          > h3 {
            display: inline;

            + div {
              display: inline;
            }
          }

          .video-wrapper {

            @media (min-width: $tablet) {
              display: flex;
            }

            > div {
              margin-top: 18px;
              margin-bottom: 36px;

              @media (min-width: $tablet) {
                width: 360px;
                flex-shrink: 0;
                margin-right: 18px;
              }
            }

            > p {
              @media (min-width: $tablet) {
                width: calc(100% - 360px);
                flex-grow: 1;
              }
            }
          }
        }

        > h3 {
          margin-top: 0;

          span {
            color: white;
            background: #ccc;
            border-radius: 12px;
            padding: 4px 16px;
            margin-left: 13px;
          }

        }

        > ul {
          padding: 0 15px;

          @media (min-width: $laptop) {
            padding: 0;
          }

          li {

            &:nth-child(4n+1) {

              @media (min-width: $laptop) {
                padding-left: 0;
              }

            }

            &:nth-child(4n+4) {

              @media (min-width: $laptop) {
                padding-right: 0;
              }

            }
          }

        }

        > .load-more-wrapper a {
          display: block;
          text-align: center;
          padding: 7px 20px;
        }

      }

      &.only-mobile {
        padding-bottom: 20px;
      }

      &:not(:last-child) {

        > h3 {
          font-size: 16px;
          font-weight: 400;
          margin: 0 0 5px;

          span {
            background: #fff;
            border-radius: 12px;
            padding: 4px 16px;
            margin-left: 13px;
          }

        }

      }

      > p {
        font-size: 18px;
        margin-bottom: 1.1em;
      }

      > a:not(.btn):not(.back-button) {
        @include link;
        display: none;
        color: $text-color;
        font-weight: 700;
        float: right;
        margin-bottom: 15px;
        position: relative;
        bottom: -3px;

        @media (min-width: $laptop) {
          display: inline-block;
        }

        &:hover {
          color: $light-blue;
          text-decoration: none;
        }

      }

      > ul {
        margin-bottom: 0;

        li {
          list-style: none;
        }

      }
    }
  }

}

.node-action #action-accordeon .action__step-content .wrapper {
  font-size: 18px;
}
