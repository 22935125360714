.node-community_post-form {
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: $laptop) {
    width: susy-span(11 of 12);
    padding: 30px 0;
  }

  @media (min-width: $laptop-l) {
    width: susy-span(10 of 12);
  }

}
