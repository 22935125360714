// Styles for global layout properties.
.links {
  > ul {
    display: flex;

    li {
      padding-left: 10px;
      display: flex;
      align-items: center;

      @media (min-width: $tablet) {
        padding-left: 20px;
      }

      &.language-switcher {

        li {
          padding-left: 0;
        }

        @media (min-width: $tablet) {
          display: flex;
        }
      }
    }
  }

  &.national {
    .links__login {
      background: $background-blue;
      color: $white;
      cursor: pointer;
      padding: 10px 20px;
      border-radius: $welcome-border-radius;

      @media (min-width: $tablet) {
        border: none;
      }

      &:active,
      &:hover {
        background: $highlight-blue;
      }
    }
  }
  &__sign-up {
    background: $background-green;
    color: $white;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: $welcome-border-radius;

    @media (min-width: $tablet) {
      border: none;
    }

    &:active,
    &:hover {
      background: $highlight-green;
    }
  }
}

.hero {
  position: relative;

  &__banner {
    @include center-flex;
    height: 350px;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;

    @media (min-width: $laptop) {
      height: 560px;
      margin-bottom: calc(25% / 2);
    }

    section.hero.national & {
      height: 550px;

      > .field-type-image img {
        height: 550px;
      }

    }

    > .field-type-image {
      max-height: 100%;
      width: 100%;
      overflow: hidden;
      position: absolute;
      z-index: -1;

      img {
        width: 100vw;
        height: 350px;
        object-fit: cover;
        object-position: left bottom;

        @media (min-width: $laptop) {
          height: 560px;
        }

      }
    }

    h2 {
      margin: 0;
      color: $white;
      display: block;
      text-align: center;
      font-weight: 100;
      font-size: 24px;
      padding-top: 40px;
      width: susy-span(11 of 12);

      @media (min-width: $laptop) {
        font-size: 42px;
        padding-top: 80px;
        width: susy-span(7 of 12);
      }

      &.gray-background {
        @include second-gray-background-box;
        margin-top: 30px;

        @media (min-width: $laptop) {
          margin-top: 70px;
        }
      }
    }

  }

  &__form {
    @media (min-width: $tablet) {
      width: susy-span(10 of 12);
    }

    &__inner {
      padding: 30px 0;
      background: $background-blue;

      @media (min-width: $tablet) {
        background: $white;
        border-radius: $welcome-border-radius;
        width: susy-span(6 of 12);
      }
    }

    #autocomplete {
      font-size: 1em;
      border: none;
      border-radius: $welcome-border-radius;
      margin-top: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom: 1px solid lighten($background-gray-1, 50%);
      border-left: 1px solid lighten($background-gray-1, 50%);
      border-right: 1px solid lighten($background-gray-1, 50%);

      ul {
        margin: 0;
        padding: 0;
      }

      li {
        background: lighten($background-gray-1, 60%);
        padding: 15px;
        white-space: normal;
        margin: 0;
        border-bottom: 1px solid lighten($background-gray-1, 50%);

        &:last-child {
          border-bottom: none;
        }

        &.selected {
          background: $highlight-blue;
        }
      }

      p {
        margin: 0;
      }
    }

    form {
      width: susy-span(10 of 12);
      margin: 0 auto;

      .form-item {
        margin: 0;
      }

      input {
        margin: 10px 0;
        border: none;
        padding: 15px;
        border-radius: $welcome-border-radius;
        width: 100%;
        appearance: none;
        font-size: 1em;

        @media (min-width: $tablet) {
          border: 2px solid $background-gray-2;
        }
      }
    }

    h3 {
      display: block;
      text-align: center;
      margin: 0 auto 15px;
      color: $white;
      width: susy-span(10 of 12);
      font-weight: 100;

      @media (min-width: $tablet) {
        color: $text-color;
      }
    }

    @media (min-width: $tablet) {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__cta {
    text-transform: uppercase;
    transition: .2s ease-in;
    font-weight: bold;
    margin-top: 40px;

    &--white {
      @include cta($white);
      & {
        color: $highlight-blue;
        font-size: 18px;
      }
      &:hover {
        background: $highlight-blue;
        color: $white;
      }
    }

    &--blue {
      @include cta($highlight-blue);
      & {
        color: $white;
        font-size: 14px;
        margin-top: 10px;
      }
      &:hover {
        background: $white;
        color: $highlight-blue;
      }
    }

    @media (min-width: $tablet) {
      padding: 10px 25px;
    }
  }

  &__absolute_title {
    position: absolute;
    top: 20px;
    left: 40px;
    max-width: calc(100% - 80px);

    h2 {
      color: #fff;
      background: rgba(85, 94, 103, .5);
      border-radius: 5px;
      padding: 10px 20px;
      font-weight: 100;
      margin: 0;
    }
  }

  .stat2__container {
    @media(min-width: $tablet) {
      position: absolute;
      bottom: -20%;
      background: #fff;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  i {
    margin-left: 5px;
  }
}

.redirect-to-domain-form {
  margin-top: 40px;
}

body.page-welcome {

  main {
    width: susy-span(10 of 12);
    padding: 0 0 60px;

    .field-name-field-city-we-solutions-content .field-item {

      p:first-child {
        text-align: center;

        ~ p {
          text-align: left;
        }

      }
    }
  }

  .main {
    &__content {
      :last-child:after {
        content: "";
        clear: both;
        display: table;
      }
    }
  }

}

.main {
  &__content {
    padding: 30px 0;
    text-align: center;

    @media (min-width: $tablet) and (max-width: $laptop) {
      margin-top: 100px;
    }

    @media (min-width: $laptop) {
      width: susy-span(12 of 12);
      margin: 0 auto;
    }

    &.left-aligned {
      text-align: left;
    }
  }
}

.strikethrough-background {
  text-align: left;
  margin: 0 auto;

  & + div, & + section {
    padding-top: 0;
  }
}

.strikethrough-title {
  position: relative;
  margin: 0 0 40px 0;
  text-align: center;

  span {
    position: relative;
    z-index: 2;
    background-color: $white;
    padding: 0 15px;
    text-align: center;
    max-width: 85%;
    display: inline-block;
  }

  &::before {
    content: '';
    height: 4px;
    background-color: $background-green;
    display: block;
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 5px;

    @media (min-width: $tablet) {
      width: susy-span(8 of 12);
    }

    @media (min-width: $laptop) {
      width: susy-span(7 of 12);
    }
  }

  & + div, & + section {
    padding-top: 60px;
  }

  & ~ p {
    margin-left: 5%;
    width: 90%;
  }

  & ~ .media-element-container {
    &.media-float-left {
      margin-left: 5%;
    }

    &.media-float-right {
      margin-right: 5%;
    }
  }
}

.threecol {
  &__container {
    @media (min-width: $laptop) {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }

  &__item {
    &:nth-child(2) {
      padding: 20px 0;

      @media (min-width: $laptop) {
        padding: 0 20px;
      }
    }

    @media (min-width: $laptop) {
      padding: 0 20px;
    }

    @media (min-width: $laptop) {
      flex-basis: calc(100% / 3);
    }

    h3 {
      @media (min-width: $laptop) {
        font-size: 20px;
        margin-bottom: 0;
      }

      @media (min-width: $laptop-l) {
        font-size: 20px;
      }
    }

    p {
      color: rgb(103, 106, 115);
    }
  }
}

.category__list {
  @media (min-width: $tablet) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }

  @media (min-width: $laptop) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
  }

  &--item {
    @include global-card;

    margin-bottom: 30px;
    border-radius: 5px;

    > div {
      @include center-background;

      padding-bottom: 70%;
      position: relative;
      border-radius: 5px;
    }
  }

  a {
    @include h5;
    & {
      font-weight: 700;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $generic-semi-transparent;
      width: 100%;
      padding: 20px;
      color: $white;
      text-align: left;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
