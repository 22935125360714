.view-recent-actions {
  .view-content {
    .views-row {
      margin-bottom: 20px;

      &.views-row-9, &.views-row-10 {
        display: none;
      }
    }
  }
}
