// Styles for singular HTML selectors with no classes or IDs, i.e. "select".
body {
  color: $text-color;
  font-size: 16px;
  overflow-x: hidden;
  margin-top: 0;
  margin-bottom: 0;

  main {

    @media (min-width: $tablet) {
      padding: 0;
    }

  }

}

* {
  box-sizing: border-box;
}

a {
  color: $light-blue;
  text-decoration: none;

  &:active {
    color: $highlight-blue;
  }

  &:hover {
    text-decoration: underline;
  }
}

header {
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 0;
  width: susy-span(11 of 12);
}

ul {
  list-style-type: none;
}

ul, li {
  padding: 0;
}

form {
  input {
    margin: 0;
  }
}

.only-mobile {

  @media (min-width: $laptop) {
    display: none !important;
  }

}

.only-desktop-block {
  display: none !important;

  @media (min-width: $laptop) {
    display: block !important;
  }

}

.view-empty {
  padding-left: 10px;
  padding-right: 10px;
}

.element-hidden {
  display: none !important;
}