.node-team-form {
  .form-text {
    font-size: $text-font-size;

    &:focus {
      outline: $black solid 1px;
    }
  }

  .form-textarea {
    font-family: $font-family-sans-serif;
    font-size: 14px;
  }

  .field-name-field-team-about {
    & + .description {
      margin-top: 2px;
    }
  }

  .form-file {
    width: 100%;
    border: 1px solid $light-grey;
    font-size: $text-font-size;
    padding-right: 5px;

    @media (min-width: $tablet) {
      width: auto;
    }

  }

  .form-submit {
    background-color: $background-blue;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
    margin-top: 10px;
    outline: none;
    padding: 10px 20px;

    &:hover, &:focus {
      background-color: $highlight-blue;
    }
  }
}

.modal-default .ctools-modal-content #modal-content > form.node-team-form {
  padding-left: 20px;
  padding-right: 20px;
}
