.user-progress {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include breakpoint($tablet) {
    flex-direction: row;
  }

  &_item {
    border-bottom: 1px solid $background-gray-3;
    padding: 20px 0;

    @include breakpoint($tablet) {
      border-bottom: none;
      flex-basis: 50%;
      margin-bottom: $global-padding-mobile;
      padding: 0;
    }

    @include breakpoint($laptop) {
      flex-basis: 30%;
      border-right: 1px solid $background-gray-3;
    }

    &:last-child {
      border-left-width: 0;
      border-bottom-width: 0;

      @include breakpoint($tablet) {
        border-left-width: 1px;
      }

      @include breakpoint($laptop) {
        border-left-width: 0;
        border-right: none;
        flex-basis: 40%;
      }
    }
  }

  .action-stats-overlay {
    flex-basis: 100%;
    min-height: 150px;

    @include breakpoint($laptop) {
      flex-basis: calc(100% * 2 / 3);
    }
  }

  .action-stats-overlay-message {
    margin: 0;
    position: relative;
    min-height: 150px;
  }

  .pane-content {
    margin-bottom: 0 !important;
  }

  .user-badge, .co2-stats {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 26px;

    @include breakpoint($tablet) {
      flex-direction: column;
      height: 100%;
      padding-left: 0;
    }

    .dashboard-modal-badge {
      color: $supporting-2;
    }

    div {
      margin-bottom: 5px;

      + div {
        color: $supporting-1;
        font-size: $text-font-size;
        margin: 0 0 0 16px;
        text-transform: uppercase;

        @include breakpoint($tablet) {
          margin: 0 $global-padding-mobile;
          text-align: center;
        }
      }

      img {
        width: 80px;

        @include breakpoint($tablet) {
          width: 108px;
        }

      }
    }

  }

  .stat {
    a {
      margin: 0 auto;
    }

    .stat-label {
      color: $supporting-1;
      font-size: $text-font-size;
      font-weight: normal;
      padding-left: 15px;
      text-transform: uppercase;

      span {
        font-size: $global-font-size;
        font-weight: 600;
        text-transform: none;
      }

      a {
        font-size: 18px;
      }
    }
  }
}
