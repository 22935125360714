.onboard-actions {
  background-image: linear-gradient($white, $white 70%, $background-gray-2);
  padding: 45px $global-padding-mobile 35px $global-padding-mobile;
  position: relative;

  p {
    font-size: $text-font-size;
    line-height: 24px;
    margin: 0 0 25px 0;
    text-align: center;

    @include breakpoint($tablet) {
      font-size: $global-font-size;
    };
  }

  .buttons-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 $global-padding-mobile;

    @media (min-width: $tablet) {
      flex-direction: row;
    }

    a {
      margin-bottom: 25px;
      display: flex;
      align-items: center;

      @include breakpoint($tablet) {
        margin: 0 8px;
      };

      &#energy_profile{
        position: relative;
        &:before {
          background-image: url('../../img/thunder-icon.svg');
          content: '';
          display: block;
          height: 51px;
          left: -35px;
          position: absolute;
          top: -5px;
          width: 51px;
        }
      }
    }

    &.dismissed {
      display: none;
    }

    .button {
      &.completed {
        height: 0;
        visibility: hidden;
      }
    }

    .dismiss-block {
      color: $white;
      font-size: 12px;
      margin: 0;
    }
  }
}
