body.page-user {

  .section-three {
    position: relative;
  }

  #confeti {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .congratulations-text {
    color: $background-green;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    margin-bottom: 30px;
    position: relative;
  }

  .congratulations-text-bottom {
    text-align: center;
    padding-top: 15px;

    a {
      @include cta($highlight-blue);
      &{
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        padding: 13px 25px 11px;
        margin: 0;
      }
    }

  }

  .pane-action-stats-panel-pane-1 {

    > h2 {
      margin-top: 25px;

      @include breakpoint($tablet) {
        margin: 40px auto 15px;
      }
    }

    .pane-content {

      @include breakpoint($laptop) {
        margin-bottom: 30px;
      }

    }

    .user-progress {

      @include breakpoint($tablet) {
        align-items: flex-start;
        justify-content: center;
      }

      .user-badge,
      .co2-stats {

        @include breakpoint($tablet) {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
        }

        @include breakpoint($laptop) {
          min-height: 210px;
        }

        @include breakpoint($laptop-l) {
          min-height: auto;
        }

        > div {

          @include breakpoint($tablet) {
            width: 100%;
          }

          &:first-child {

            @include breakpoint($tablet) {
              align-self: flex-start;
            }

          }

          &:last-child {

            @include breakpoint($tablet) {
              align-self: flex-start;
              line-height: 1.2;
            }

          }

        }

        .tons-reduced {

          @include breakpoint($tablet) {
            margin-bottom: 0;
          }

          @include breakpoint($laptop) {
            white-space: nowrap;
          }
        }
      }

      .user-badge img {

        @include breakpoint($tablet) {
          display: block;
          width: 123px;
          height: 123px;
          margin: 8px auto 14px;
        }

      }

      .co2-stats .gauge {

        @include breakpoint($tablet) {
          margin-left: auto;
          margin-right: auto;
        }

      }
    }

  }

  .pane-user-dashboard-my-actions {

    .gauge {
      width: 91px;
      height: 91px;
    }

  }

  .section-3col {

    .container > .row {
      margin-bottom: 30px;

      @include breakpoint($tablet) {
        display: flex;
        margin-bottom: 50px;
      }

      > div {
        margin-bottom: 20px;

        @include breakpoint($tablet) {
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

      }
    }

  }

  .pane-c02free-user-user-teams-block {
    height: 100%;

    > .pane-content {
      display: flex;
      height: 100%;
      margin-bottom: 30px;

      @include breakpoint($tablet) {
        flex-direction: row;
        flex-wrap: nowrap;
      }

      .stat {
        border-bottom: 1px solid $background-gray-3;
        padding: 10px 20px;

        @include breakpoint($tablet) {
          min-height: auto;
          display: block;
          border-right: 1px solid $background-gray-3;
          border-bottom: none;
          padding: 0 20px;
        }

        &:last-child {
          border-bottom: none;
          border-right: none;
        }

        > .rank-label {
          display: none;
        }
      }

      .view-empty,
      .view-content {
        height: 100%;

        > div {
          height: 100%;
        }
      }
    }
  }

  // @todo: retirar esto una vez que back haya podido ajustar pagina.
  .pane-action-stats-panel-pane-5 {
    background: #e2e2e2;
    margin-bottom: 50px;

    .stat-row {
      margin-bottom: 30px;

      > div {
        border: 1px solid;
      }

    }
  }

  .section-one {
    margin-bottom: 30px;
  }

  .panels-sections-3col {
    > .has-sidebar {
      > .container-fluid {
        flex-basis: 100%;

        @include breakpoint($laptop) {
          flex-basis: 70%;
        }

        h2 {
          &:not(#dashboard-name) {
            @include accordion-title;
          }

          &:not(.with-invite) {

            @include breakpoint($laptop) {
              pointer-events: none;
            }

          }
        }
      }
    }
  }

  .layout-sidebar {
    h2 {
      text-align: left;
    }
  }
}

.level-ribbon {
  background-color: $background-blue;
  color: $white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
  text-align: center;
}

form[id^="user-profile-form-"] {
  width: susy-span(10 of 12);
  margin: 30px auto;

  fieldset.collapsible {
    min-inline-size: auto;
  }

  label {
    margin-bottom: .5em;
    font-size: 16px;
  }

  input[type="text"], input[type="mail"], input[type="password"], input[type="tel"] {
    max-width: 100%;
    appearance: none;
    border: 2px solid $supporting-3;
    border-radius: 5px;
    padding: 10px;
    display: inline;
    margin-right: 10px;
    font-size: 1em;
  }

  .form-item {
    margin: 15px 0;
  }

  .description {
    font-size: 14px;
    margin: .5em 0;
    color: $supporting-1;
  }

  .password-strength {
    float: none;
    margin-bottom: 15px;
  }

  input[type="submit"] {
    @include cta($background-blue);
  }
}
