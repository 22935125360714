.pane-user-dashboard-shortcuts {
  .links-wrapper {
    width: 100%;
    text-align: center;

    @media (min-width: $laptop) {
      text-align: right;
    }

    a {
      @include cta($highlight-blue);
      & {
        display: inline-block;
        margin-right: 0;
        margin-left: 20px;
      }

      &:first-child {
        margin-left: 0;
      }

    }
  }
}
