.page-messages {

  .privatemsg-message-participants {
    @include new-container;

    & {
      padding: 10px 0;
      margin-bottom: 25px;
      margin-top: $global-padding-desktop;
    }
  }

  .privatemsg-message {
    @include new-container;
  }

  .privatemsg-author-avatar {
    width: 100px;
    margin-right: 20px;
  }

  .privatemsg-message-column {
    width: calc(100% - 120px);
  }

  .privatemsg-message-information {

    span, a {
      font-size: 16px;
    }

  }

  form {
    @include new-container;

    & {
      margin-top: $global-padding-desktop;
    }
  }

  .form-actions {
    margin: 2em 0;
  }

}
