.node-type-city-administrator-contact {

  main {
    @include new-container;

    .page-title-wrapper > .container {
      padding: 0;
    }

  }

}
