.policies-top {
  button {
    @include cta($highlight-blue);

    &.active {
      background-color: darken($highlight-blue, 15%);
    }
  }

  & {
    margin: 60px 0;
    text-align: center;
  }
}

.policies-content {
  margin: 0 60px 60px;
}
