.view-affiliation-points {

  .item-list > ul {
    padding: 0;

    li {
      align-items: center;
      border-bottom: 2px solid $background-gray-3;
      display: flex;
      list-style: none;
      margin: 0 0 10px;
      padding: 10px 0 10px 25px;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .views-field-id {
    width: 40px;
    font-weight: bold;
    padding-right: 10px;

    + div {
      display: none;
    }
  }

  .views-field-name {
    font-size: $global-font-size;
    font-weight: bold;
    padding-left: 20px;
    text-align: left;
    width: 70%;
  }

  .views-field-field-earned-points {
    font-weight: normal;
    padding-left: 20px;
    text-align: right;
    width: 30%;
  }

  #community-total {
    @include see-all-links;
  }

  .views-field.views-field-id {
    .field-content {
      font-size: 35.2px;
      color: $highlight-green;
      font-weight: bold;
    }
  }

  .my-community-rank {
    .views-field-title, .views-field-field-earned-points {
      > span, > div {
        color: lighten($supporting-1, 20%);
      }
    }
  }
}
