.pane-c02free-user-user-teams-block {
  margin-top: 40px;

  .stat-label {
    text-transform: uppercase;

    > div {
      align-content: center;
      align-items: center;
      display: flex;

      .separator {
        display: none;
        font-size: 16px;
        margin-right: 8px;
      }
    }
  }

  a {
    font-size: 18px;
  }

  .pane-content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    @include breakpoint($tablet) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
    }

    @include breakpoint($laptop) {
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 0 !important;
    }

    .stat {
      width: 100%;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      margin: 0;
      padding: 10px 40px;

      @include breakpoint($tablet) {
        min-height: 70px;
        width: auto;
        border-right: none !important;
        padding: 10px;
      }

      @include breakpoint($laptop) {
        border-right: none;
      }

      &:nth-child(3) {
        border-bottom: none;
      }

      span.separator {
        color: $supporting-2-transparent-2;
      }
    }

    .rank-label {
      color: $supporting-1;
      font-size: $text-font-size;
      margin-bottom: 5px;
      margin-left: 18px;
      text-transform: uppercase;
    }

    .stat-inline {
      @include center-flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    .stat-name {
      font-weight: bold;
    }

    .stat-num {
      font-size: 35px;
      font-weight: bold;
      color: $highlight-green;
      text-align: right;
      min-width: 55px;
    }

    a {
      display: inline-block;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      &.remodal-trigger {
        margin: 0 auto;
      }
    }
  }

  &.has-rank {
    text-align: right;

    .stat-inline {

      > a {
        font-size: 18px;
        font-weight: 600;
        margin: 0;

        @include breakpoint($laptop) {
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }
  }
}

.space-community-join {
  display: none;
}
