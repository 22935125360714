.view-control-content-panes {

  .pane-title {
    margin: 0 0 1em;
  }

  .view-header {

    a {
      font-size: 16px;
      font-weight: bold;
    }

  }

  ul {
    margin: 0;

    li {
      margin: 0 0 10px;
    }

  }

}
